import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PhysicalActivityLineChart = ({
    onBeamData,
    offBeamData
}) => {
  const combinedArray = [...onBeamData?.y_axis, ...offBeamData?.y_axis].filter(value => value !== null);
  const maxNumber = combinedArray?.length > 0 ? Math.max(...combinedArray) : 0;

  const data = {
    labels: ['' ,...onBeamData?.x_axis],
    datasets: [
      {
        label: 'On Kidney Beam',
        data: [0, ...onBeamData?.y_axis], 
        borderColor: 'rgba(68,109,175,1)',
        backgroundColor: 'rgba(68,109,175,1)',
        pointRadius:0,
        spanGaps: true,
        borderWidth: 2,
      },
      {
        label: 'Outside Kidney Beam',
        data: [0, ...offBeamData?.y_axis],
        borderColor: 'rgba(228,109,48,1)',
        backgroundColor: 'rgba(228,109,48,1)',
        pointRadius:0,
        spanGaps: true,
        borderWidth: 2,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false, 
      },
    },
    scales: {
      x: {
          min: 0,
          suggestedMin: 0,
          suggestedMax: 10,
          width: 0,
          grid: {
            display: false,
          },
        },
        y: {
          min: 0,
          suggestedMin: 0,
          suggestedMax: maxNumber,
          border: {
            display: false,
          }
        },
    },
  };

  return (
    <div className='chart-1'>
      <Line data={data} options={options} />
    </div>
  )
  
};

export default PhysicalActivityLineChart;
