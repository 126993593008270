import { authHeader } from "../_helpers";
import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";
import Cookies from "universal-cookie";
import {
  LOGIN_PROCESS,
  LOGIN_SUCCESS,
  CHECK_ISAUTH,
  SET_CONDITION,
  SET_MENU_CONDITION,
  SET_CHALLENGE_TAG,
  SET_INITIAL_CONDITION,
} from "../utilities";

export function login(email, password, passwordAttempts = false) {
  return (dispatch, getState) => {
    const requestOptions = postData({ email, password, passwordAttempts });
    dispatch({
      type: LOGIN_PROCESS,
      payload: true,
    });
    return fetch(constand.BACKEND_URL + "/api/login", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((user) => {
        if (!user.isPactsterUser) {
          dispatch({
            type: LOGIN_PROCESS,
            payload: false,
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          dispatch({
            type: SET_CONDITION,
            payload: constand.CONDITION,
          });
          user.authdata = window.btoa(
            unescape(encodeURIComponent(email + ":" + password))
          );
          const now = new Date();
          const expiryDate = new Date(now.setFullYear(now.getFullYear() + 10));
          localStorage.setItem("user", JSON.stringify(user));
          const cookies = new Cookies();
          cookies.set("refreshToken", user.refreshToken, { path: "/" ,expires:expiryDate});
          localStorage.setItem("refreshToken", user.refreshToken);
        }
        return user;
      });
  };
}

export function forgotPassword(email) {
  return (dispatch, getState) => {
    const requestOptions = postData({ email });
    return fetch(constand.BACKEND_URL + "/api/forgot", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        return response;
      });
  };
}

export function resetPassword(dataObj) {
  return (dispatch, getState) => {
    const requestOptions = postData(dataObj);
    return fetch(constand.BACKEND_URL + "/api/reset", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        return response;
      });
  };
}

export function checkResetToken(token) {
  return (dispatch, getState) => {
    const requestOptions = getData();
    return fetch(constand.BACKEND_URL + "/api/reset/" + token, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        return response;
      });
  };
}

export function isAuth() {
  var is_auth =
    localStorage.getItem("user") || localStorage.getItem("userAuthToken")
      ? true
      : false;
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_ISAUTH,
      payload: is_auth,
    });
  };
}
export function setRefreshToken(refreshToken) {
  localStorage.setItem("refreshToken", refreshToken);
}
export function logout() {
  // remove user from local storage to log user out
  return (dispatch, getState) => {
    var showWelcomePopup = localStorage.getItem("firstTimeUser");
    let userPasswordAttempts = localStorage.getItem("userPasswordAttempts");
    const refreshToken = localStorage.getItem("refreshToken");
    console.log(refreshToken);
    // remove all
    localStorage.clear()
    setRefreshToken(refreshToken);
    localStorage.setItem("firstTimeUser", showWelcomePopup);
    localStorage.setItem("userPasswordAttempts", userPasswordAttempts);
    localStorage.setItem("refreshToken", refreshToken);

    isAuth();
    dispatch({
      type: LOGIN_PROCESS,
      payload: false,
    });
  };
}

export function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return (dispatch, getState) => {
    return fetch(`{constand.BACKEND_URL}/users`, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
}

export function setCondition(condition) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CONDITION,
      payload: condition,
    });
  };
}

export function setMenuCondition(condition) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_MENU_CONDITION,
      payload: condition,
    });
  };
}

export function facebookLogin(socialUser) {
  return async (dispatch, getState) => {
    const requestOptions = postData({ facebookId: socialUser.id });
    dispatch({
      type: LOGIN_PROCESS,
      payload: true,
    });
    return fetch(constand.BACKEND_URL + "/api/login", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((user) => {
        if (!user.isPactsterUser) {
          dispatch({
            type: LOGIN_PROCESS,
            payload: false,
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          dispatch({
            type: SET_CONDITION,
            payload: constand.CONDITION,
          });
          user.authdata = window.btoa(user.id);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("refreshToken", user.refreshToken);
        }
        return user;
      });
  };
}

export function googleLogin(socialUser) {
  return async (dispatch, getState) => {
    const requestOptions = postData({ googleId: socialUser.sub });
    dispatch({
      type: LOGIN_PROCESS,
      payload: true,
    });
    return fetch(constand.BACKEND_URL + "/api/login", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((user) => {
        if (!user.isPactsterUser) {
          dispatch({
            type: LOGIN_PROCESS,
            payload: false,
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          dispatch({
            type: SET_CONDITION,
            payload: constand.CONDITION,
          });
          user.authdata = window.btoa(user.id);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("refreshToken", user.refreshToken);
        }
        return user;
      });
  };
}

export function updateUserChallengeTag() {
  console.log("updateUserChallengeTag");
  return (dispatch, getState) => {
    const requestOptions = getData();
    return fetch(constand.BACKEND_URL + "/api/updateUserTag", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: SET_CHALLENGE_TAG,
          payload:
            response.foundTags && response.foundTags.TagId ? true : false,
        });
        return response;
      });
  };
}

export function setInitialCondition(condition) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_INITIAL_CONDITION,
      payload: condition,
    });
  };
}

export function impersonateAccount(params) {
  return (dispatch, getState) => {
    let data = { email: params };
    const requestOptions = postData(data);
    dispatch({
      type: LOGIN_PROCESS,
      payload: true,
    });
    return fetch(
      constand.BACKEND_URL + "/api/impersonateAccount",
      requestOptions
    )
      .then((response) => handleResponse(response, dispatch))
      .then((user) => {
        if (!user.isPactsterUser) {
          dispatch({
            type: LOGIN_PROCESS,
            payload: false,
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          dispatch({
            type: SET_CONDITION,
            payload: constand.CONDITION,
          });
          user.authdata = window.btoa(data.email);
          localStorage.setItem("user", JSON.stringify(user));
        }
        return user;
      });
  };
}
