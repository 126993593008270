import React from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { cancelBanner, cancelBannerKD, cancelBannerPC } from "../../actions";
import * as constand from "../../constant";
import { commonService } from "../../_services";

class CffBanner extends React.Component {
    constructor() {
        super();
    }
    componentDidMount() {

        console.log('getConditionBanner', this.props.conditionBanner)
    }
    setCloseBannerCookies = (closeBanner) => {
        if (typeof Cookies.get(closeBanner) == 'undefined') {
            if (Cookies.get('CookieControl') && Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted') {
                Cookies.set(closeBanner, false);
            } else if (typeof Cookies.get('CookieControl') == 'undefined') {
                Cookies.set(closeBanner, false);
            }
        }
    }
    sweetThanks() {
        let condition = commonService.replaceSpaceToChar(this.props.condition.toLowerCase(), '');
        let closeBannerCookie = 'closeBanner' + condition;
        this.setCloseBannerCookies(closeBannerCookie);
        console.log('closeBannerCookie', closeBannerCookie)
        this.props.cancelBanner(closeBannerCookie);

        if (Cookies.get('CookieControl') && Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted') {
            Cookies.set(closeBannerCookie, true);
        }

    }
    renderBanner(type, currentPath) {
        let banner_HTML_text = this.props.conditionBanner.banner_HTML_text || "";
        let mobile_banner_HTML_text = this.props.conditionBanner.mobile_banner_HTML_text || "";
        return (
            <div class="condition-banner">
                {type == 'web' &&
                    <section id="section6" className={currentPath == '/home' ? "bannerSection section6-bg navbar-collapse" : "bannerSection section6-bg navbar-collapse"} style={{ backgroundColor: this.props.conditionBanner.banner_colour }}>
                        <div className="col-md-12">
                            <div className="row align-item-center">
                                {this.props.conditionBanner.banner_img &&
                                    <div className="float-left cff_banner_img">
                                        <div className="cff_image">
                                            <img src={this.props.conditionBanner.banner_img} className="w-100" />
                                        </div>
                                    </div>
                                }
                                <div className="w-55 banner_content float-left ml-4">
                                    <p className="text-white font-18 font-qmedium mb-0 " dangerouslySetInnerHTML={{
                                        __html: banner_HTML_text
                                    }}></p>
                                </div>
                                <div className="banner_buttons">
                                    {this.props.conditionBanner.button2==1 &&
                                        this.renderButton2()
                                    }
                                    {this.props.conditionBanner.button1==1 &&
                                        this.renderButton1()
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {type == "mobile" &&
                    <section id="section6" className="section6-bg mobile-condition-dd" style={{ backgroundColor: this.props.conditionBanner.banner_colour }}>
                        <div className="col-md-12">
                            <div className="row align-item-center">
                                {this.props.conditionBanner.banner_img &&
                                    <div className="float-left cff_banner_img">
                                        <div className="cff_image"><img src={this.props.conditionBanner.banner_img} className="" /></div>
                                    </div>
                                }
                                <div className="cff_adults">
                                    <p className="text-white font-14 font-qmedium mb-0" dangerouslySetInnerHTML={{
                                        __html: mobile_banner_HTML_text
                                    }}></p>
                                    <div className="cff_buttons">
                                        {this.props.conditionBanner.button2==1 &&
                                            this.renderButton2()
                                        }
                                        {this.props.conditionBanner.button1==1 &&
                                            this.renderButton1()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        )
    }
    renderButton1() {
        return (
            this.props.conditionBanner.button1_url &&
            <a href={this.props.conditionBanner.button1_url} className="btn btn-banner float-right" style={{ backgroundColor: this.props.conditionBanner.button1_bkg_colour, color: this.props.conditionBanner.button1_font_colour, borderColor: this.props.conditionBanner.button1_border_colour }} >{this.props.conditionBanner.button1_text}</a>
            ||
            <button className="btn btn-banner float-right mr-3 ml-3" style={{ backgroundColor: this.props.conditionBanner.button1_bkg_colour, color: this.props.conditionBanner.button1_font_colour, borderColor: this.props.conditionBanner.button1_border_colour }} onClick={() => {
                this.sweetThanks()
            }}>{this.props.conditionBanner.button1_text}</button>
        )
    }
    renderButton2() {
        return (
            this.props.conditionBanner.button2_url &&
            <a href={this.props.conditionBanner.button2_url} className="btn btn-banner float-right" style={{ backgroundColor: this.props.conditionBanner.button2_bkg_colour, color: this.props.conditionBanner.button2_font_colour, borderColor: this.props.conditionBanner.button2_border_colour }} >{this.props.conditionBanner.button2_text}</a>
            ||
            <button className="btn btn-banner float-right mr-3 ml-3" style={{ backgroundColor: this.props.conditionBanner.button2_bkg_colour, color: this.props.conditionBanner.button2_font_colour, borderColor: this.props.conditionBanner.button2_border_colour }} onClick={() => {
                this.sweetThanks()
            }}>{this.props.conditionBanner.button2_text}</button>
        )
    }
    render() {
        var currentPath = window.location.href;
        currentPath = currentPath.indexOf('/home') != -1 ? '/home' : '';
        currentPath = currentPath.indexOf('/reset') != -1 ? '/home' : currentPath;
        var { type, condition } = this.props;
        console.log('cffbanner-this.props', this.props)
        console.log('cffbanner', condition)
        console.log('IP_Details', this.props.IP_Details)
        console.log('cffbanner-cnd', condition.includes(constand.CONDITION.split(" ")[0]))
        let countryCode = this.props.IP_Details && this.props.IP_Details.countryCode ?
        this.props.IP_Details.countryCode:this.props.IP_Details.country?this.props.IP_Details.country.code:'';
        console.log("countryCode",countryCode)
        return (
            <React.Fragment>
                {this.props.conditionBanner && this.props.conditionBanner.show==1 && (this.props.conditionBanner.country.length==0 || (this.props.conditionBanner.country.length > 0 && this.props.conditionBanner.country.includes(countryCode))) &&
                    this.renderBanner(type, currentPath)
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        close_banner: state.header.close_banner,
        close_banner_kd: state.header.close_banner_kd,
        close_banner_pc: state.header.close_banner_pc,
        conditionBanner: state.header.conditionBanner,
        IP_Details: state.accountinfo.ip_data,

    };
};

const mapDispatchToProps = {
    cancelBanner,
    cancelBannerKD,
    cancelBannerPC
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CffBanner);
