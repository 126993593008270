import React from "react";
import ContactForm from "../ContactPage/ContactFormComponent";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";

class HealthComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className="healthpage-content m-t-70">
        <Helmet>
          <title>{constand.ABOUT_HEALTH_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.ABOUT_HEALTH_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.ABOUT_HEALTH_DESC} />
          <meta property="og:image" content={constand.ABOUT_HEALTH_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.ABOUT_HEALTH_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="section-1">
          <div className="">
            <div className="col-md-12 ml-auto mr-auto">
              <div className="section-header text-center p-t-30 p-b-30">
                <h3 className="orangefont font-37 position-relative font-medium m-t-30">
                  Health service & partnerships
                </h3>
                <p className="w-36 font-medium mx-auto p-t-20 sub-health-cont font-21 black-txt">
                  From patients to clinicians, to healthcare commissioners,
                  insurers, charities and employers - we work with anybody who
                  believes that exercise can improve health outcomes
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="section3" className="section3-bg p-t-40 p-b-40">
          <div className="container-fluid mx-auto w-55">
            <div className="row">
              <div className="col-12 d-flex align-items-center col-md-7 col-lg-7 text-left">
                <div className="card cardnew border-0">
                  <div className="card-block">
                    <h3 className="clearfix float-left font-semibold font-24 w-100">
                      Research
                    </h3>
                  </div>
                  <p className="paragraph-style m-t-30 font-14 black-txt font-qregular">
                  Since our beginnings, we've taken every opportunity to get involved in research as we believe this is great way to learn more about you, our users. We take these learnings and make the platform better. Sometimes they are big changes, sometimes they are small, but they all add up. Another bonus of research is being able to gather evidence that Kidney Beam improves your physical and emotional wellbeing. Evidence like this is useful for care-providers, so that they know how to best support you in feeling good.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-5 text-center">
                <figure>
                  <img
                    src={constand.WEB_IMAGES+"health_blog1.png"}
                    className="img-responsive r-image w-100"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionnew-3 p-t-80  p-b-40">
          <div className="container-fluid mx-auto w-80">
            <h5 className="redtext col-md-6 col-lg-5 col-sm-6 m-t-10 font-semibold font-24 m-b-60 ml-auto mr-auto text-center">
              Some important research projects we've been involved in are:
            </h5>
            <div className="row">
              <div className="col-lg-4 col-sm-12 mb-4">
                <div className="card h-100 border-0">
                  {/* <a href="javascript:void(0)">
                    <img
                      className="card-img-top"
                      src="/images/health_blog2.png"
                      alt=""
                    />
                  </a> */}
                  <div className="card-body image-content pl-0">
                    <h4 className="card-title font-semibold font-15 black-txt">
                      ‘Is Pactster* an acceptable tool promote Exercise
                      Participation in the Adult Cystic Fibrosis Community with
                      and without Physiotherapy Support?”
                    </h4>
                    <div className="grey-text m-t-30 font-13 font-qmedium">
                      Independent research by Adult Cystic Fibrosis Centre,
                      Newcastle upon Tyne NHS Trust, UK
                    </div>
                    <div id="summary">
                      <p
                        id="collapseSummary"
                        className="card-text m-t-20 font-14 black-txt font-qregular collapse"
                      >
                        This study monitored 25 participants using Pactster over
                        a 6 week period. The participants were separated into
                        two groups, one group belonging to a community with
                        physio support and the other group working
                        independently. The study found that all participants
                        perceived Pactster to be an acceptable tool to promote
                        exercise participation. The group with physio support
                        showed greater improvements in lung function and
                        exercise capacity. This study resulted in the further
                        development of Pactster groups and the addition of live
                        classes to give greater opportunity for physio support.
                        <p className="card-text m-t-20 font-14 black-txt font-qregular">
                          *Pactster is the former name for Kidney Beam
                        </p>
                      </p>
                      <a
                        className="readmore redtext font-14 font-qregular collapsed"
                        data-toggle="collapse"
                        href="#collapseSummary"
                        aria-expanded="false"
                        aria-controls="collapseSummary"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mb-4">
                <div className="card h-100 border-0">
                  {/* <a href="javascript:void(0)">
                    <img
                      className="card-img-top"
                      src="/images/health_blog2.png"
                      alt=""
                    />
                  </a> */}
                  <div className="card-body image-content pl-0">
                    <h4 className="card-title font-semibold font-15 black-txt">
                      ‘Improving adherence to exercise in people with cystic
                      fibrosis by developing innovative, behaviour change
                      approaches for Pactster (a community-driven online
                      exercise platform)’
                    </h4>
                    <div className="grey-text font-qmedium font-13 m-t-30">
                      Funded by Innovate UK, undertaken by Pactster
                    </div>
                    <div id="summary">
                      <p
                        id="collapseSummary1"
                        className="card-text font-14 black-txt font-qregular m-t-20 collapse"
                      >
                        This study monitored 25 participants using Pactster over
                        a 6 week period. The participants were separated into
                        two groups, one group belonging to a community with
                        physio support and the other group working
                        independently. The study found that all participants
                        perceived Pactster to be an acceptable tool to promote
                        exercise participation. The group with physio support
                        showed greater improvements in lung function and
                        exercise capacity. This study resulted in the further
                        development of Pactster groups and the addition of live
                        classes to give greater opportunity for physio support.
                      </p>
                      <a
                        className="readmore redtext font-14 font-qregular collapsed"
                        data-toggle="collapse"
                        href="#collapseSummary1"
                        aria-expanded="false"
                        aria-controls="collapseSummary"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mb-4">
                <div className="card h-100 border-0">
                  {/* <a href="javascript:void(0)">
                    <img
                      className="card-img-top"
                      src="/images/health_blog2.png"
                      alt=""
                    />
                  </a> */}
                  <div className="card-body image-content pl-0">
                    <h4 className="card-title font-semibold font-15 black-txt">
                      'Improving physical activity levels in adolescents with
                      Cystic Fibrosis (CF) through the development of
                      age-specific behaviour change interventions to support
                      adherence to specialist exercise programmes online
                    </h4>
                    <div className="grey-text font-qmedium font-13 m-t-30">
                      Undertaken by University of Exeter and funded by Vertex
                      Circle of Care
                    </div>
                    <div id="summary">
                      <p
                        id="collapseSummary2"
                        className="card-text collapse font-14 black-txt font-qregular m-t-20"
                      >
                        This project kicked-off in 2019 and is designed to
                        better understand the opinions and needs of 12-18year
                        olds with CF with regard to online exercise and
                        behavioural support. As well as assessing general
                        opinions of Kidney Beam, the researchers at Exeter will be
                        undertaking a needs assessment to identify the kind of
                        behavioural support that would be most useful for
                        adolescents with CF who want to be physically active,
                        and to use evidence collected to co-create
                        technology-based behavioural change components which we
                        will implement.
                      </p>
                      <a
                        className="readmore redtext font-14 font-qregular collapsed"
                        data-toggle="collapse"
                        href="#collapseSummary2"
                        aria-expanded="false"
                        aria-controls="collapseSummary"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="sectionnew-4 lightblue-bg">
          <div className="container">
            <div className="section-header text-center p-t-30 p-b-30">
              <h5 className="font-semibold font-24 darkblue-text m-t-10">
                Partners
              </h5>
              <div className="col-md-12 p-t-20 p-b-20 ">
                <div className="row ">
                  <div className="col-12 col-md-6 col-sm-12 col-lg-3 m-b-10">
                    <figure>
                      <img
                        src="/images/partner_img_01.png"
                        className="img-responsive"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-md-6 col-sm-12 col-lg-3 m-b-10">
                    <figure>
                      <img
                        src="/images/partner_img_01.png"
                        className="img-responsive"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-md-6 col-sm-12 col-lg-3 m-b-10">
                    <figure>
                      <img
                        src="/images/partner_img_01.png"
                        className="img-responsive"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="col-12 col-md-6 col-sm-12 col-lg-3 m-b-10">
                    <figure>
                      <img
                        src="/images/partner_img_01.png"
                        className="img-responsive"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
                      </div> */}
        {/* <div className="sectionnew-5 m-t-40 m-b-40 float-left w-100">
            <h5 className="redtext col-md-6 col-lg-6 col-sm-6 m-t-10 m-b-60 font-24 font-semibold ml-auto mr-auto text-center">Case Studies</h5>
                <div className="container">
                    <div className="col-md-12 col-lg-12 col-sm-10 mr-auto ml-auto">
                    <div className="col-lg-4 col-md-4 col-sm-12 float-left">
                        <div className="card h-100">
                            <a href="#"><img className="card-img-top" src="/images/health_blog3.png" alt="" /></a>
                            <div className="card-body">
                            <h5 className="card-title font-semibold font-15 black-txt">
                                Research
                            </h5>
                            <p className="card-text font-qmedium font-13 grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet numquam aspernatur eum quasi sapiente nesciunt</p>
                            <div className="form-group">

                            <a href="#" className="btn-blue-inverse font-medium font-14 float-left w-100 text-center">Read more</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 float-left">
                        <div className="card h-100">
                            <a href="#"><img className="card-img-top" src="/images/health_blog3.png" alt="" /></a>
                            <div className="card-body">
                            <h5 className="card-title font-semibold font-15 black-txt">
                                Research
                            </h5>
                            <p className="card-text font-qmedium font-13 grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet numquam aspernatur eum quasi sapiente nesciunt</p>
                            <div className="form-group">

                            <a href="#" className="btn-blue-inverse font-medium font-14 float-left w-100 text-center">Read more</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 float-left">
                        <div className="card h-100">
                            <a href="#"><img className="card-img-top" src="/images/health_blog3.png" alt="" /></a>
                            <div className="card-body">
                            <h5 className="card-title font-semibold font-15">
                                Research
                            </h5>
                            <p className="card-text font-qmedium font-13 grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet numquam aspernatur eum quasi sapiente nesciunt</p>
                            <div className="form-group">

                            <a href="#" className="btn-blue-inverse font-medium font-14 float-left w-100 text-center">Read more</a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="section-4 health-last float-left w-100 m-t-40 p-b-40">
          <div className="col-md-6 ml-auto mr-auto">
            <h5 className="text-center m-b-20 font-semibold font-24">
              Contact Us
            </h5>
            <p className="text-center font-qregular black-txt font-14">
              Thinking you might like to work with us? Let's have a chat.
            </p>
            <p className="text-center font-qregular black-txt font-14 m-b-80 col-md-9 col-lg-9 col-sm-10 mr-auto ml-auto">
              Rest assured, you'll be in good company. We partner with
              organisaations worldwide - health charities, insurance companies,
              medical device and other private companies, NHS Trusts - commited
              to using technology to improve healthcare.
            </p>

            <ContactForm location={this.props.location} />
          </div>
        </div>
      </section>
    );
  }
}

export { HealthComponent };
