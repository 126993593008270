import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGoals,
  goalsModalOpen,
  completeGoal,
} from "../../../actions/GoalActions";
import { convertToQueryString } from "../../dynamicDashboard/utlity";
import GoalRow from "./GoalRow";
import { CREATE_UPDATE_GOALS_LIST } from "../../../utilities";
import { GOALS_LIST_CNT } from "../../../constant";
import { toast } from "react-toastify";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";

const GoalsList = ({ ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [programId] = useState(props.programId);
  const create_update_goals = useSelector((state) => state.goals.isReset);
  // const program_goal_list = useSelector(
  //   (state) => state.goals.program_goal_list
  // );

  const [goalsPaginationData, setGoalsPaginationData] = useState({
    page: 1,
    limit: GOALS_LIST_CNT,
  });

  const [goalsData, setGoalsData] = useState([]);
  const [newGoalsData, setNewGoalsData] = useState([]);
  const [goalsDataCount, setGoalsDataCount] = useState(null);
  const [createUpdateReset, setCreateUpdateReset] = useState(false);

  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  function loader() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  useEffect(() => {
    loader();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setGoalsPaginationData((prevData) => ({
          ...prevData,
          page: prevData.page + 1,
        }));
      }
    };

    const throttledHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  async function handleAddEditFetch() {
    const program_goal_list = await dispatch(
      fetchGoals({
        id: programId,
        queryString: convertToQueryString(goalsPaginationData),
      })
    );
    setGoalsData(program_goal_list.goals);
    setNewGoalsData(program_goal_list.goals);
    setCreateUpdateReset(false);
    dispatch({
      type: CREATE_UPDATE_GOALS_LIST,
      payload: false,
    });
  }

  // useEffect(() => {
  //   if (program_goal_list && Object.keys(program_goal_list).length) {
  //     if (program_goal_list.goals) {
  //       if (createUpdateReset) {
  //         handleAddEditFetch();
  //       } else {
  //         if (
  //           program_goal_list.goals.length > 0 &&
  //           !goalsData.some(
  //             (a) =>
  //               program_goal_list.goals[0].id == a.id ||
  //               program_goal_list.goals[0].programId == programId
  //           )
  //         ) {
  //           setGoalsData((prevGoals) => [
  //             ...prevGoals,
  //             ...program_goal_list.goals,
  //           ]);
  //         }
  //       }
  //     }
  //     if (program_goal_list.goalsCount) {
  //       setGoalsDataCount(program_goal_list.goalsCount);
  //     }
  //   }
  // }, [program_goal_list]);
  async function handlePagination() {
    const program_goal_list = await dispatch(
      fetchGoals({
        id: programId,
        queryString: convertToQueryString(goalsPaginationData),
      })
    );
    if (program_goal_list && Object.keys(program_goal_list).length) {
      if (program_goal_list.goals.length > 0) {
        if (createUpdateReset) {
          handleAddEditFetch();
        } else {
          const goalsDataTemp = [...goalsData, ...program_goal_list.goals];
          setGoalsData(goalsDataTemp);
          // setGoalsData((prevGoals) => [
          //   ...prevGoals,
          //   ...program_goal_list.goals,
          // ]);
          setNewGoalsData(goalsDataTemp);
        }
      }
      if (program_goal_list.goalsCount) {
        setGoalsDataCount(program_goal_list.goalsCount);
      }
    }
  }
  useEffect(() => {
    if (goalsPaginationData) {
      if (goalsData.length !== goalsDataCount) {
        // dispatch(
        //   fetchGoals({
        //     id: programId,
        //     queryString: convertToQueryString(goalsPaginationData),
        //   })
        // );
        handlePagination();
      }
    }
  }, [goalsPaginationData]);
  useEffect(() => {
    if (create_update_goals) {
      setGoalsData([]);
      setGoalsDataCount(null);
      setCreateUpdateReset(true);
      setGoalsPaginationData({ limit: GOALS_LIST_CNT, page: 1 });
    }
  }, [create_update_goals]);

  const handleGoalComplete = async (goal, status) => {
    try {
      const response = await dispatch(completeGoal(goal.id, status));
      if (!response.success) throw new Error(response.message);
      toast.success(response.message);
      setGoalsData((prevGoals) =>
        prevGoals.map((g) =>
          g.id === goal.id ? { ...g, isCompleted: status } : g
        )
      );
      setNewGoalsData((prevGoals) =>
        prevGoals.map((g) =>
          g.id === goal.id ? { ...g, isCompleted: status } : g
        )
      );
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const onEdit = (goal) => {
    dispatch(goalsModalOpen(true, goal));
  };
  if (loading || createUpdateReset) {
    return (
      <div className="text-center w-100">
        <AnimateLoaderComponent />
      </div>
    );
  }
  return (
    <div className="goals-datas mt-3 border-0 p-0">
      <div className="row prggoals header-row">
        <div className="col col-wide font-qmedium ash-txt font-14 fw500">
          Goal
        </div>
        <div className="col font-qmedium ash-txt font-14 fw500 text-right">
          Date set
        </div>

        <div className="col font-qmedium ash-txt font-14 fw500 text-right">
          Date reviewed
        </div>

        <div className="col font-qmedium ash-txt font-14 fw500 text-right">
          Status
        </div>
        <div className="col font-qmedium ash-txt font-14 fw500 text-right">
          Edit
        </div>
      </div>
      {!newGoalsData.length && (
        <div className="row prggoals header-data-row align-items-center">
          <div className="col col-wide black-txt font-16 fw700 font-qbold text-center">
            No goals found
          </div>
        </div>
      )}
      {newGoalsData.map((goal, index) => (
        <GoalRow
          key={index}
          goal={goal}
          title={goal.goal}
          dateSet={goal.createdAt}
          reviewDate={goal.reviewDate}
          isCompleted={goal.isCompleted}
          onClick={handleGoalComplete}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
};

export default GoalsList;
