import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { showBrowseCategories } from "../../actions";
import { Link } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { commonService } from "../../_services";
class OndemandBrowseCategories extends React.Component {
    constructor(props) {
        super(props);
    }
    closeBrowseList = () => {
        document.body.classList.remove('removebbg-popup');
        this.props.showBrowseCategories(false)
    }

    onCloseModal = () => { }

    savePageUrl = (url) => {
        if (url) {
            var pathnameTemp = url;
            Cookies.set("current-page-url", pathnameTemp);
        }
    }

    jumpToBrowseSection = (condition, value) => {
        this.props.showBrowseCategories(false);
        this.props.scrollToCategory(value);
        /* this.savePageUrl("/on-demand/" + condition + "/#" + value);
        const element = document.getElementById(value);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth', block: 'center',
                inline: 'center'
            });
        } */
    }

    renderMobileView = () => {
        let condition = this.props.condition;
        return (
            <Modal
                className="removebbg-popup"
                open={this.props.is_filter_open}
                onClose={this.onCloseModal}
                center
            >
                <div className={"w-100"}
                    id="accordion"
                >
                    <div className="card-header border-0">
                        <h5 className="mb-0">
                            <a
                                role="button"
                                data-toggle="collapse"
                                className="collapsed font-medium font-18"
                            >
                                Browse all categories
                                <i onClick={() => this.closeBrowseList()} className="fa  fa-2x pull-right small-arrow fa fa-times"></i>
                            </a>
                        </h5>
                    </div>
                    {this.props.available_filter && Object.keys(this.props.available_filter).length &&
                        Object.keys(this.props.available_filter).map((value, key) => {
                            return (
                                <div className="card">
                                    <div className="card-header" id={key}>
                                        <h5 className="mb-0">
                                            <a
                                                role="button"
                                                data-toggle="collapse"
                                                href={"#collapse" + key}
                                                aria-expanded="false"
                                                aria-controls={"collapse" + key}
                                                className="collapsed font-book font-18"
                                            >
                                                {value}
                                                <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                                            </a>
                                        </h5>
                                    </div>
                                    <div
                                        id={"collapse" + key}
                                        className="collapse"
                                        data-parent="#accordion"
                                        aria-labelledby={"collapse" + key}
                                    >
                                        <div className="card-body">
                                            <div id="accordion-1">
                                                {this.props.available_filter[value].length && this.props.available_filter[value].map(
                                                    (item, key) => {
                                                        let idValue = item.value ? commonService.removeSpaceAndTrim(item.value) : key;
                                                        return (
                                                            <p key={"length" + key}
                                                                className="pointer clearfix capitalize_text font-14 browse-paratext mb-0 font-16"
                                                            >
                                                                <Link class="mb-0"
                                                                    //to={"/on-demand/" + condition + "/#" + idValue} 
                                                                    to='#'
                                                                    onClick={() => this.jumpToBrowseSection(condition, 'mob-' + idValue)}
                                                                >
                                                                    <p className="font-16 font-qregular"> {item.value} </p>
                                                                </Link>
                                                            </p>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    {/* <button className="btn btn-blue">
                            Show all
                        </button> */}
                </div>
            </Modal>
        )
    }

    renderWebView = () => {
        let condition = this.props.condition
        return (
            <div className="col-md-12 filter_grid m-t-33 ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row browse_grid pl-3">
                            {this.props.available_filter && Object.keys(this.props.available_filter).length &&
                                Object.keys(this.props.available_filter).map((value, key) => {
                                    return (
                                        <div className="col-md-2 p-l-0 mb-0 pr-5">
                                            <div className="filter_head  black-txt small-txt font-16 font-medium  m-b-15">
                                                {value}
                                            </div>
                                            {this.props.available_filter[value].length && this.props.available_filter[value].map(
                                                (item, key) => {
                                                    let idValue = item.value ? commonService.removeSpaceAndTrim(item.value) : key;
                                                    return (
                                                        <span
                                                            key={"length" + key}
                                                            className="pointer clearfix capitalize_text m-b-15 font-14 browse-paratext mb-0"
                                                        >
                                                            <Link className="nav-link link-scroll font-book navalign pointer link-scroll mb-0 p-0"
                                                                //to={"/on-demand/" + condition + "/#" + idValue}
                                                                to='#'
                                                                onClick={() => this.jumpToBrowseSection(condition, idValue)}
                                                            >
                                                                <p>  {item.value} </p>
                                                            </Link>
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {/* mobile view content */}
                <div className="d-block d-sm-block d-md-none d-lg-none">
                    {this.props.is_filter_open && !this.props.is_web_view && (this.renderMobileView())}
                </div>

                {/* web view content */}
                <div className="d-none d-lg-block d-md-block w-100">
                    {this.props.is_filter_open && this.props.is_web_view && (this.renderWebView())}
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,

    };
};
const mapDispatchToProps = {
    showBrowseCategories
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OndemandBrowseCategories);
