import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { completeSession, saveSession } from "../../actions";
import { useParams } from "react-router-dom";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { ImageTag, TextWrap } from "../../tags";

import LockIcon from "../../assets/images/messages/lock.svg";

const SessionList = ({
  list,
  sessionStartCb,
  refreshSession,
  membershipCheck,
  hasMembership,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("start");
  const [session, setSession] = useState([]);
  const { programId } = useParams();

  async function startSession() {
    if (hasMembership) {
      sessionStartCb(type, session);
      console.log("sessionStart");
    }
  }
  useEffect(() => {
    startSession();
  }, [hasMembership]);

  const sessionStart = (type, session) => () => {
    membershipCheck();
    setType(type);
    setSession(session);
  };

  const handleSaveSession =
    (sessionId, savedSessionId, isSaved) => async () => {
      await dispatch(
        saveSession({
          sessionId,
          savedSessionId: savedSessionId || null,
          isSaved,
        })
      );

      toast.success("Successfully updated");
      refreshSession();
    };

  const getStatusClass = (status, progress) => {
    if (status === "completed" || progress === 100) {
      return "incomplete-status1";
    }
    return "incomplete-status";
  };

  const getEnabledClass = (isEnabled) => {
    return isEnabled ? "enabled" : "disabled";
  };

  const dynamicWidth = (params) =>
    params.status === "completed" ? "100" : params.progress;

  return (
    <React.Fragment>
      {list && list.length ? (
        list.map((session, index) => {
          let buttonTitle = "Start session";
          if (session.status === "started") {
            buttonTitle = "Continue session";
          } else if (session.status === "completed") {
            buttonTitle = "Redo session";
          }
          return (
            <React.Fragment>
              <div
                className={`list-datas mt-3  `}
                style={{ position: "relative" }}
              >
                <div
                  style={{ display: session?.isEnabled ? "none" : "block" }}
                  className="lockic"
                >
                  <img src={LockIcon} alt="search" />
                  <p className="mb-0">Locked until week {index + 1}</p>
                </div>
                <div
                  className={`card bg-light-subtle ${getStatusClass(
                    session.status,
                    session.progress
                  )} ${getEnabledClass(session?.isEnabled)}`}
                  style={{
                    "--dynamic-width": `${dynamicWidth(session)}%`,
                  }}
                >
                  <ImageTag
                    src={constand.S3_URL + session?.imageUrl}
                    className="img-fluid thumb-goal-image"
                    alt="Goal Image"
                  />
                  <div className="card-body align-items-center">
                    <div className="text-section">
                      <h5 className="card-title mb-0 font-16 fw600 black-txt font-qbold">
                        Session {session.sessionOrder}:{" "}
                        <TextWrap text={session.title} limit={20} />
                      </h5>
                      <ul className="list-group list-group-horizontal capabilities">
                        <li className="list-group-item bg-transparent border-0 p-0 d-flex">
                          <figure>
                            <img
                              src="/images/dashboard/calendar.svg"
                              className="img-fluid"
                              alt="calendar"
                            />
                          </figure>
                          <span className="font-16 fw500 ash-txt font-qmedium">
                            {session?.isEnabled
                              ? session.formattedDate
                              : `Available ${session.formattedDate}`}
                          </span>
                        </li>
                        <li className="list-group-item bg-transparent border-0 p-0 d-flex">
                          {session?.isVideoSession && (
                            <>
                              <figure>
                                <img
                                  src="/images/dashboard/clock.svg"
                                  className="img-fluid"
                                  alt="video"
                                />
                              </figure>
                              <span className="font-16 fw500 ash-txt font-qmedium">
                                {session.videoLength} min
                              </span>
                            </>
                          )}
                        </li>
                        <li className="list-group-item bg-transparent border-0 p-0 d-flex">
                          {session?.isVideoSession && (
                            <>
                              <figure>
                                <img
                                  src="/images/dashboard/video.svg"
                                  className="img-fluid"
                                  alt="video"
                                />
                              </figure>
                              <span className="font-16 fw500 ash-txt font-qmedium">
                                Video
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                      {session?.isEnabled && (
                        <div className="session-button-section d-flex align-items-center mt-2">
                          <a
                            href="javascript:void(0)"
                            className="session-btn btn btn-outline-dark font-qbold"
                            onClick={sessionStart("start", session)}
                          >
                            {buttonTitle}
                          </a>
                          <figure
                            style={{
                              // background: session.isSaved ? "#456EB0" : "",
                              cursor: "pointer",
                              borderRadius: "50px",
                              border: "1.5px solid #D7D5D8",
                              padding: "6px 8px 6px 8px",
                              marginBottom: "0",
                            }}
                          >
                            <img
                              src={
                                session.isSaved
                                  ? "/images/dashboard/liked.svg"
                                  : "/images/dashboard/like.svg"
                              }
                              className={`img-fluid`}
                              //  ${
                              //   session.isSaved && "img-fluid-highlight"
                              // }`}
                              onClick={handleSaveSession(
                                session?.id,
                                session?.SavedSessions[0]?.id,
                                session?.isSaved
                              )}
                              alt="Like"
                            />
                          </figure>
                        </div>
                      )}
                    </div>
                    {session?.isEnabled && (
                      <div className="cta-section d-flex align-items-center">
                        <a
                          href="javascript:void(0)"
                          className="session-btn btn btn-outline-dark font-qbold"
                          style={{ padding: "12px 24px 12px 24px" }}
                          onClick={sessionStart("start", session)}
                        >
                          {buttonTitle}
                        </a>
                        <figure
                          style={{
                            // background: session.isSaved ? "#456EB0" : "",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={
                              session.isSaved
                                ? "/images/dashboard/liked.svg"
                                : "/images/dashboard/like.svg"
                            }
                            className={`img-fluid`}
                            //  ${
                            //   session.isSaved && "img-fluid-highlight"
                            // }`}
                            onClick={handleSaveSession(
                              session?.id,
                              session?.SavedSessions[0]?.id,
                              session?.isSaved
                            )}
                            alt="Like"
                          />
                        </figure>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment>
          <div className="list-datas mt-3">
            <div className="card bg-light-subtle">
              <div className="card-body align-items-center">
                <div className="text-section">
                  <h5 className="card-title mb-0 font-16 fw600 black-txt font-qbold">
                    No session found
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default SessionList;
