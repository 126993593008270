import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import { sendGroupInvitation } from "../../actions";
import { commonService } from "../../_services";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";

class InviteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: '',
            submitted: false,
            loading: false,
            isSuccessModel: false,
            isValidEmail: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
    }

    componentDidMount() {
        this.setState({ emails: '' })
    }
    componentWillReceiveProps() {
        this.setState({ emails: '' })
    }

    onCloseModal() { }

    handleChange(e) {
        const { name, value } = e.target;
        let { workout } = this.state;
        this.setState({
            [name]: value
        });
        console.log('handleChange')
    }
    sendInvitation() {
        console.log('sendInvitation')
        this.setState({ submitted: true });
        var { emails } = this.state;
        if (!emails)
            return;

        var isValidEmail = false;
        emails = emails.replace(/ /g, '').replace(/\r?\n|\r/g, ''); //remove whitespace/newlines
        if (emails) {
            console.log('emails==>', emails)

            var emailsSplit = emails.includes(',') ? emails.split(',') : emails;
            if (Array.isArray(emailsSplit)) {
                emailsSplit.map((item, key) => {
                    isValidEmail = !commonService.mailPatternCheck(item);
                    console.log('Emailcheck--', isValidEmail);
                })
            } else {
                isValidEmail = !commonService.mailPatternCheck(emails);
                console.log('elseEmailcheck--', isValidEmail);
            }
            if (isValidEmail) {
                this.setState({ isValidEmail: isValidEmail })
                return;
            }
        }

        this.setState({ loading: true });
        var params = { emails: emails, groupName: this.props.group, condition: commonService.replaceChar(this.props.groupState.condition, true) }
        this.props.sendGroupInvitation(params).then(response => {
            this.setState({ submitted: false, loading: false });
            if (response.status) {
                this.setState({ isSuccessModel: true });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }, error => {
            this.setState({ submitted: false, loading: false });
        })

    }
    renderSuccessModal() {
        return (
            <Modal
                className="removebbg-popup"
                open={this.state.isSuccessModel}
                onClose={this.onCloseModal}
                center
            >
                <div
                    className="modal-dialog common_design modal-width--custom plain-popup m-t-50"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header header-styling  border-0">

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={() => this.props.modelClose()}
                                >
                                    &times;
                </span>
                            </button>
                        </div>
                        <div className="modal-body body-padding--value">
                            <div>
                                <div class="col-lg-12 col-md-12 col-sm-12 m-b-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="/images/signupimg1.png" />
                                        </div>

                                        <div className="col-md-6 align-self-center text-center">
                                            <p className="font-20 twitter-color">Success!</p>
                                            <p className="font-14 twitter-color">Your email has been sent.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons-read col-md-4 m-t-20 pointer pull-right">
                                    <a disabled={this.state.loading} className="btn btn-blue-inverse font-14 read-later pointer w-100" onClick={() => { this.props.modelClose(); this.setState({ isSuccessModel: false }) }}>
                                        Close</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
    render() {
        return (
            <div>
                <Modal
                    className="removebbg-popup"
                    open={this.props.is_model_open && !this.state.isSuccessModel}
                    onClose={this.onCloseModal}
                    center
                >
                    <div
                        className="modal-dialog common_design modal-width--custom plain-popup m-t-50"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0">
                                <h5
                                    className="modal-title1 black-txt col-md-12 p-0 font-book font-24"
                                    id="exampleModalLabel "
                                >Send invite to participants</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() => this.props.modelClose()}
                                    >
                                        &times;
                </span>
                                </button>
                            </div>
                            <div className="modal-body body-padding--value">
                                <div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 m-b-10">
                                        <p className="black-txt">Send to: <span className="gray-txt">(copy and paste email addresses -  separate using a comma ,)</span></p>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            name="emails"
                                            onChange={this.handleChange}
                                            value={this.state.emails}></textarea>
                                        {this.state.submitted && !this.state.emails && (
                                            <div className="text-danger">This is required.</div>
                                        )}
                                        {this.state.submitted && this.state.isValidEmail && (
                                            <div className="text-danger">Please check that only valid email addresses are provided, separated by a comma.</div>
                                        )}
                                    </div>

                                    <div className="buttons-read col-md-4 m-t-20 pointer pull-right">
                                        <button disabled={this.state.loading} className="btn btn-login font-14 read-later pointer w-100" onClick={() => { this.sendInvitation() }}>
                                            SEND</button>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center w-100">
                                {(this.state.loading) && (<AnimateLoaderComponent />)}
                            </div>
                        </div>
                    </div>

                </Modal>
                {this.state.isSuccessModel && this.renderSuccessModal()}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        is_group_mode: state.header.is_group_mode,
    };
};

const mapDispatchToProps = {
    sendGroupInvitation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteModal);