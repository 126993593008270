import {
  CREATE_UPDATE_GOALS_LIST,
  IS_GOAL_MODAL_OPEN,
  SET_DASHBOARD_PROGRAM_GOALS_LIST,
  SET_PROGRAM_GOALS_LIST,
} from "../utilities";

const INITIAL_STATE = {
  program_goal_list: [],
  dashboard_program_goal_list: [],
  isReset: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROGRAM_GOALS_LIST:
      return {
        ...state,
        program_goal_list: action.payload,
      };
    case SET_DASHBOARD_PROGRAM_GOALS_LIST:
      return {
        ...state,
        dashboard_program_goal_list: action.payload,
      };
    case CREATE_UPDATE_GOALS_LIST:
      return {
        ...state,
        isReset: action.payload,
      };
    case IS_GOAL_MODAL_OPEN:
      return {
        ...state,
        is_goal_modal_open: action.payload,
        goal: action.goal,
      };
    default:
      return state;
  }
};
