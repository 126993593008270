import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie-consent"; import {
    switchAccount, fetchUserDetails, login, impersonateAccount, setExploreConditions, healthCondition, fetchUserOtherDetails
} from "../../actions";
import { commonService } from "../../_services";
import * as constand from "../../constant";


class ConfirmationPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRead: false,
            masterUserDetails: {}
        };
    }
    componentDidMount() {
        if (this.props.isSwitchAccount) {
            this.switchMasterAccount(this.props.logged_userData, null)
        }
    }

    //child user details
    switchMasterAccount = (item, isImpersonate) => {
        let masterUserDetails = this.props.masterUserDetails != null ? this.props.masterUserDetails : JSON.parse(localStorage.getItem('masterUserDetails'));
        this.setState({ masterUserDetails: masterUserDetails })
        if (Object.keys(item).length) {
            if (isImpersonate) {
                this.props.impersonateAccount(item.email).then(() => {
                    this.props.switchAccount(false);
                    this.props.fetchUserDetails().then(() => {
                        this.props.fetchUserOtherDetails();
                        let loggedUser = JSON.parse(localStorage.getItem('userDetails'))
                        this.props.healthCondition().then((res) => {
                            console.log('this.props.logged_userData-storage', JSON.parse(localStorage.getItem('userDetails')))
                            this.props.setExploreConditions(loggedUser.UserConditions, res.condition)
                        });
                        //change initial condition url 
                        if (isImpersonate) {
                            const { from } = { from: { pathname: '/on-demand/' + loggedUser.UserConditions[0].Tag.tag } };
                            this.props.history.push(from);
                        }
                    });
                })
            }
            //active profile
            let master_item = commonService.setActiveProfile(item, masterUserDetails);
            this.setState({ masterUserDetails: master_item })
        }
    }

    renderButtons = () => {
        return (
            <>
                {this.props.isConfirmation &&
                    <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                        <a className="btn btn-blue-inverse font-14 read-now m-r-20" onClick={() => this.props.closeConfirmationPopup('Yes')}>
                            {this.props.yesButton ? this.props.yesButton : "Yes"}
                        </a>
                        <a className="btn btn-blue-inverse font-14 read-later m-l-20 pointer" onClick={() => this.props.closeConfirmationPopup('No')}>
                            {this.props.noButton ? this.props.noButton : "No"}
                        </a>
                    </div>
                }
                {!this.props.isConfirmation && this.props.hasButton &&
                    <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                        <a className="btn btn-blue-inverse font-14 read-later m-l-20 pointer" onClick={() => this.props.closeConfirmationPopup(this.props.terms)}>
                            Continue
                        </a>
                    </div>
                }
            </>
        )
    }
    renderBodyContent = () => {
        return (
            <>
                {this.props.type == 'policy' &&
                    <div>
                        <div className="col-md-12 text-center m-b-30 mr-auto ml-auto">
                            <p className="font-18 font-qregular black-txt">We’ve been reviewing our <a className="black-txt font-semibold" href="/terms" target="_blank"> <u>terms and conditions</u></a> and <a className="black-txt font-semibold" href="/terms" target="_blank"> <u>privacy policy</u></a> and have made a few changes that we need you to agree to before continuing to use Kidney Beam. Please read these and confirm that you are happy to agree to them below:</p>
                        </div>
                        <div className="toggle-btns float-left w-100">
                            <span className="font-18 font-qregular black-txt">
                                I agree to Kidney Beam’s T&Cs and privacy policy…
                                {/* <a className="black-txt" href="/terms" target="_blank"> <u>T&Cs</u> </a>and <a className="black-txt" href="/privacy" target="_blank"><u>Privacy policy…</u></a> */}</span>
                            <label className="switch m-l-10 pull-right" htmlFor="terms">
                                <input type="checkbox" name="terms" id="terms" defaultChecked={this.props.terms} onChange={this.props.handleCheckboxChange} value={this.props.terms} />
                                <div className="slider round"></div>
                            </label>
                            {this.props.submitted && (!this.props.terms) && <p className="text-danger m-t-10">* We require this consent to allow you to use Kidney Beam.</p>}
                        </div>
                    </div>
                }
            </>
        )
    }
    onCloseModal() { }
    render() {
        return (
            <Modal
                className="removebbg-popup"
                open={this.props.is_model_open}
                onClose={() => this.onCloseModal()}
                center
            >
                <div
                    className="modal-dialog common_design modal-width--custom live-classes--popup"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header header-styling  border-0">
                            <h5
                                className="modal-title1 text-center white-txt col-md-12 p-0 font-book font-24"
                                id="exampleModalLabel "
                            >
                                {this.props.title || 'Heads Up!'}
                            </h5>
                            {this.props.isCloseRequired && <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={() => this.props.type == 'policy' ? this.props.closeConfirmationPopup('Yes') : this.props.type == 'accountLocked' ? this.props.closePopup() : this.props.type == "membershipPaymentError" ? this.props.closePaymentFailedPopup() : this.props.switchAccount(false)}
                                >&times;</span>
                            </button>
                            }
                        </div>
                        <div className="modal-body body-padding--value pb-0">
                            <div>
                                {this.renderBodyContent()}
                                {this.props.type != 'policy' && this.props.type != 'SwitchAccountPopup' && this.props.type != 'accountLocked' && this.props.type != "membershipPaymentError" &&
                                    <div className="col-md-12 text-center m-b-30 mr-auto ml-auto">
                                        <p className="font-15 font-semibold black-txt">{this.props.desc ? this.props.desc : "Are you sure you want to close and lose your changes?"}</p>
                                    </div>
                                }
                                {this.renderButtons()}

                            </div>
                        </div>
                        {/* switch account popup */}
                        {this.props.is_switch_account_open && this.state.masterUserDetails &&
                            <div className="modal-body body-padding--value p-0">
                                <div className="account_tabs m-0 profile_account_tabs new_prof_popup">
                                    <div className="container-fluid mx-auto0">
                                        <div className="row">
                                            <div className="col-12 mb-0 m-t-20 d-sm-none d-md-block d-lg-block">
                                                <ul
                                                    className="nav nav-pills flex-row"
                                                    id="myTab"
                                                    role="tablist"
                                                >
                                                    {this.state.masterUserDetails && <li className="nav-item">
                                                        <button
                                                            id={this.state.masterUserDetails.id}
                                                            disabled={this.state.masterUserDetails.id == this.props.logged_userData.id}
                                                            className={
                                                                (this.state.masterUserDetails.activeClass
                                                                    ? "active"
                                                                    : "") + " nav-link font-medium font-21 m-b-10 btn_bg"
                                                            }
                                                            onClick={() => this.switchMasterAccount(this.state.masterUserDetails, this.state.masterUserDetails.id)}
                                                        >
                                                            <span className="profile_img"><img src={
                                                                this.state.masterUserDetails.profilePic
                                                                    ? constand.S3_API_IMG + '/profile/' + this.state.masterUserDetails.profilePic
                                                                    : constand.WEB_IMAGES + "no-profile-pic.png"
                                                            } alt="" /></span>
                                                            <span className="profile_name">You</span>
                                                        </button>
                                                    </li>
                                                    }
                                                    {this.state.masterUserDetails.childUser && this.state.masterUserDetails.childUser.map((child_userData, key) => {
                                                        return (
                                                            <li className="nav-item">
                                                                <button
                                                                    id={child_userData.id}
                                                                    disabled={child_userData.id == this.props.logged_userData.id}
                                                                    className={
                                                                        (child_userData.activeClass
                                                                            ? "active"
                                                                            : "") + " nav-link font-medium font-21 m-b-10 btn_bg"
                                                                    }
                                                                    onClick={() => this.switchMasterAccount(child_userData, this.state.masterUserDetails.id)}
                                                                >
                                                                    <span className="profile_img">
                                                                        <img

                                                                            src={
                                                                                child_userData.profilePic
                                                                                    ? constand.S3_API_IMG + '/profile/' + child_userData.profilePic
                                                                                    : constand.WEB_IMAGES + "no-profile-pic.png"
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <span className="profile_name">{child_userData.name} {child_userData.lastName}</span>
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* account lock popup */}
                        {this.props.is_account_locked &&
                            <div className="modal-body body-padding--value p-0">
                                <div className="container-fluid">
                                    <p className="font-15 font-semibold black-txt text-center mt-4 px-3">
                                        Sorry, your account has been locked as
                                        you have entered your password incorrectly five times in a row.
                                        Please send an email to <a href="mailto:tech@beamfeelgood.com">tech@beamfeelgood.com</a> to have this unlocked so you can join us again soon.
                                    </p>

                                    <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                                        <a className="btn btn-blue-inverse font-14 read-later m-l-20 pointer" onClick={this.props.closePopup}>
                                            Close
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* membership payment error popup */}
                        {this.props.type == 'membershipPaymentError' &&
                            <div className="modal-body body-padding--value p-0">
                                <div className="container-fluid">
                                    <p className="font-15 font-semibold black-txt text-center mt-4 px-3">{this.props.errorMessage} There has been an error with your payment in Stripe. <a href="https://dashboard.stripe.com/test/dashboard" target="_blank">Please retry in Stripe</a> or contact us at <a href="mailto:tech@beamfeelgood.com">tech@beamfeelgood.com</a> </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        is_switch_account_open: state.header.is_switch_account_open,
        master_user_details: state.header.master_user_details,
        healthcondition_list: state.register.healthcondition_list,
        is_account_locked: state.accountinfo.is_account_locked
    };
};
const mapDispatchToProps = {
    switchAccount, fetchUserDetails, login, impersonateAccount, setExploreConditions, healthCondition, fetchUserOtherDetails
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmationPopup);
