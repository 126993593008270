import React from "react";
import { Link, URLSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import {
	pushHistory,
	ondemandWorkoutList,
	workoutSearchSuggestionList,
	ondemandCMSModelOpen,
	clearOndemandDetail,
	ondemandDetail,
	getOnDemandBrowseCategories,
	showBrowseCategories,
	getBodyWorkoutList,
	refreshWorkoutList,
	getCompletedWorkoutCount,
	openClinicianPostSessionPopup,
    cliniciansGroupSessionData
} from "../../actions";
import { commonService } from "../../_services";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { ImageTag, TextWrap } from "../../tags";
import { Helmet } from "react-helmet";
import AdminBanner from "../Common/AdminBanner";
import ReactGA from 'react-ga';
import OndemandSearchComponent from "../Common/OndemandSearchComponent";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { Carousel, Thumbs } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WorkoutCMSComponent from "./WorkoutCMSComponent";
import OndemandBrowseCategories from "./OndemandBrowseCategories";
const _ = require('lodash');
class OndemandListComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			typeFilter: this.props.params.condition,
			search_data: "",
			selected_filter: {},
			loader: false,
			ondemandWorkoutList: {},
			seeMoreWorkoutsList: false,
			isShowSearchWorkout: false,
			searchKeywordsList: [],
			is_cms_model: false,
			draftSection: {},
			isWebView: true,
			onDemandBody: [],
			bodyWorkoutListing: [],
			category_loader: false,
			percentage: 0
		};
		this.lastState = this.props.params.condition;
		this.searchFunction = this.searchFunction.bind(this);
		this.swiperRef = React.createRef();
		this.loader = false;
		this.searchRef = React.createRef()  
	}

	componentDidMount() {
		this.showHistoryBack();
		this.checkCliniciansPopup();
	}

	checkCliniciansPopup = () => { 
		if(this.props.params.roomId && this.props.params.workoutId){
			this.props.openClinicianPostSessionPopup(true);
			let sessionData = {
				roomId: this.props.params.roomId,
				workoutId: this.props.params.workoutId,
				condition: this.props.params.condition,
				expectedCount: this.props.params.expectedCount
			};
			this.props.cliniciansGroupSessionData(sessionData); 
		} 
	}

	componentWillMount() {
        let typeFilter = commonService.replaceChar(this.state.typeFilter, true);
        this.setState({ typeFilter: typeFilter });
        if (this.props.is_auth) {
            this.props.getCompletedWorkoutCount(typeFilter);
        }
       
        let existingSearch = this.props.history.location.search;
        if (existingSearch) {
            existingSearch = existingSearch.split("?");
            let searchData = existingSearch[1].split("=");
            let searchValue = searchData[1];
            this.getOnDemandList(searchValue.replace(/%20/g, ' '));
        } else {
            this.getOnDemandList();
        }
        this.getOnDemandSearchSuggestion();
        this.props.ondemandCMSModelOpen({ open: false });
        this.props.getOnDemandBrowseCategories(typeFilter);
    }

	componentWillUnmount() {
		this.setState({
			search_data: "",
		});
	}
	// componentDidUpdate() {
	// 	var bodyList = this.props.body_workout_list.length && this.props.body_workout_list.map(a => { return { ...a } })
	// 	if (bodyList.length != this.state.onDemandBody.length) {
	// 		setTimeout(() => {
	// 			const filteredUsers = _.filter(bodyList, { type: "drafts" });
	// 			if (bodyList.length && filteredUsers) {
	// 				this.setState({ draftSection: bodyList[0] })
	// 				if (!this.props.is_create_mode) {
	// 					bodyList.shift()
	// 				}
	// 			}
	// 			this.setState({ bodyWorkoutListing: bodyList })
	// 		}, 0.1);
	// 	}
	// }

	showHistoryBack = () => {
		let existingSearch = this.props.history.location.search;
		console.log('showHistoryBack=', this.props.body_workout_list.length)
		if (existingSearch) {
			existingSearch = existingSearch.split("?");
			let searchData = existingSearch[1].split("=");
			let searchName = searchData[0];
			let searchValue = searchData[1];

			switch (searchName) {
				case 'category':
					this.getOnDemandList(searchValue);
					break;
				case 'keyword':
					this.showHistoryBackSearch(searchValue.replace(/%20/g, ' ') );
					break;
				default:
					if (this.props.is_auth) {
						this.props.getCompletedWorkoutCount(this.state.typeFilter);
					}
					this.getOnDemandList();
					break;
			}
		} else if (!this.props.body_workout_list.length) {
			// this.getOnDemandList();
		}
	}

	showHistoryBackSearch = (searchValue) => {
		//this.onSearchResult('yoga');
		this.setState({ search_data: searchValue, isShowSearchWorkout: true }, function () {
			this.searchFunction();
		})
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value, });
		if (value == '') {
			this.searchFunction();
			this.setState({ isShowSearchWorkout: false, seeMoreWorkoutsList: false })
		}
	}

	componentWillReceiveProps(props) {
		if (this.lastState != props.params.condition) {
			this.lastState = props.params.condition;
			this.loader = true;
			this.setState(
				{
					typeFilter: props.params.condition,
					search_data: "", isShowSearchWorkout: false, seeMoreWorkoutsList:false
				},
				function () {
					this.componentWillMount();
					if (this.props.is_auth) {
						this.props.getCompletedWorkoutCount(this.state.typeFilter);
					}
					this.getOnDemandList();
				}
			);
		}
		if (!this.props.is_create_mode && props.is_create_mode) {
			if (this.state.ondemandWorkoutList && Object.keys(this.state.ondemandWorkoutList).length
				> 0) {
				let ondemandWorkoutList = this.state.ondemandWorkoutList;
				ondemandWorkoutList.BodySection.unshift(this.state.draftSection);
				this.setState({ ondemandWorkoutList: ondemandWorkoutList })
			}
		} else if (this.props.is_create_mode && !props.is_create_mode) {
			if (this.state.ondemandWorkoutList && Object.keys(this.state.ondemandWorkoutList).length
				> 0) {
				let ondemandWorkoutList = this.state.ondemandWorkoutList;
				ondemandWorkoutList.BodySection.shift();
				this.setState({ ondemandWorkoutList: ondemandWorkoutList })
			}
		}
		this.setState({ ondemandWorkoutList: this.props.ondemand_list });

		let loggedInFromOnDemand = JSON.parse(localStorage.getItem("loggedInFromOnDemand"))
		if (loggedInFromOnDemand) {
			localStorage.removeItem("loggedInFromOnDemand");
			if (this.props.is_auth) {
				this.props.getCompletedWorkoutCount(this.state.typeFilter);
			}
			this.getOnDemandList();
		}
	}
	/* Workout search. */
	searchFunction() {
		if (this.state.search_data) {
			this.setState({ isShowSearchWorkout: true }, function () {
				ReactGA.event({
					category: "On Demand Video List",
					action: "Searched ",
					label: this.state.search_data,
				})
				if (this.props.is_auth) {
					this.props.getCompletedWorkoutCount(this.state.typeFilter);
				}
				this.getOnDemandList();
				let me = this;
				// setTimeout(() => me.searchRef.current.scrollIntoView({ behavior: 'smooth' }), 1000);
				setTimeout(() =>
					window.scrollTo({
                        behavior: 'smooth',
                        top:
                          document.getElementById(`container-search-content`).getBoundingClientRect().top -
                          document.body.getBoundingClientRect().top -
                          200,  
                      }), 1000);
			});
			this.props.history.push({
				pathname: '/on-demand/' + this.state.typeFilter,
				search: '?keyword=' + this.state.search_data
			})
		} else {
			if (this.props.is_auth) {
				this.props.getCompletedWorkoutCount(this.state.typeFilter);
			}
			this.getOnDemandList();
		}

	}

	/* Ondemand workout list */
	getOnDemandList = (searchValue = null) => {
        this.setState({ loader: true });
        let keywords;
        let existingSearch = this.props.history.location.search;
        if (existingSearch) {
            existingSearch = existingSearch.split("?");
            let searchData = existingSearch[1].split("=");
            let searchValue = searchData[1];
            keywords = searchValue.replace(/%20/g, ' ');
        } else {
            keywords = this.state.search_data;
        }
        let dataObj = {
            keywords,
			isDraft:0,
            seeMoreWorkouts: this.state.seeMoreWorkoutsList
        };
		let typeFilter = commonService.replaceChar(this.state.typeFilter, true);
		/* if (!this.state.bodyWorkoutListing) {
			this.setState({ loader: false })
		} */
		this.props.ondemandWorkoutList(dataObj, typeFilter).then(
			responseData => {
				if (responseData) {
					this.setState({ loader: false });
					this.setState({ ondemandWorkoutList: this.props.ondemand_list })
					// var endIndex = responseData.indexOf('}') + 1;
					// var modifiedResponse = responseData.slice(endIndex).trim();
					// var copyData = JSON.parse(modifiedResponse);
					// console.log("come", copyData)
					let onDemandWorkouts = responseData.onDemandWorkouts; // To avoid api delay and overlap the search results
					if (onDemandWorkouts && Object.keys(onDemandWorkouts).length > 0) {
						onDemandWorkouts.BodySection = onDemandWorkouts.BodySection
							&& onDemandWorkouts.BodySection.length > 0 &&
							onDemandWorkouts.BodySection.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
						let ondemandList = onDemandWorkouts;
						this.setState({
							ondemandWorkoutList: ondemandList,
						});
						if (onDemandWorkouts.BodySection.length && onDemandWorkouts.BodySection[0].type == "drafts") {
							this.setState({ draftSection: onDemandWorkouts.BodySection[0] })
							if (!this.props.is_create_mode) {
								ondemandList.BodySection.shift();
							}
						}
						this.setState({
							ondemandWorkoutList: ondemandList,
							loader: false,
						});
						this.loader = false;
					} else {
						this.setState({
							ondemandWorkoutList: {},
							loader: false
						});
						this.loader = false;
					}

				} else {
					this.setState({ loader: false, ondemandWorkoutList: {}, })
					this.loader = false;
				}
			},
			error => {
				this.setState({ loader: false })
				this.loader = false
			}
		);
	}

	/**
	 * On enter search
	 * @param {*} e
	 */
	searchBarEnter = (e) => {
		if (e.key === "Enter") {
			this.setState({ isShowSearchWorkout: true, seeMoreWorkoutsList: false })
			this.searchFunction();
		}
	}

	/* See more workout list. */
	seeMoreWorkouts = () => {
		this.setState({ seeMoreWorkoutsList: true, }, function () {
			this.getOnDemandList();
		})
	}

	/* See less workout list. */
	seeLessWorkouts = () => {
		this.setState({ seeMoreWorkoutsList: false, }, function () {
			this.getOnDemandList();
		})
	}

	/* Back to search results. */
	backToSearch = () => {
		this.setState({ search_data: "", isShowSearchWorkout: false, seeMoreWorkoutsList:false }, function () {
			if (this.props.is_auth) {
				this.props.getCompletedWorkoutCount(this.state.typeFilter);
			}
			this.getOnDemandList();
		})
		this.props.history.push({
			pathname: '/on-demand/' + this.state.typeFilter,
			search: ''
		})
	}

	/* On-demand workout search suggestion list. */
	getOnDemandSearchSuggestion = () => {
		this.props.workoutSearchSuggestionList(this.state.typeFilter).then((searchKeywordsRes) => {
			if (searchKeywordsRes && searchKeywordsRes.data) {
				this.setState({ searchKeywordsList: searchKeywordsRes.data })
			}
		})
	}

	/*Search bar inupt handling. */
	onSearchResult = (searchInput) => {
		this.setState({ search_data: searchInput })
		if (!this.state.search_data) {
			//this.searchFunction();
			this.setState({ isShowSearchWorkout: false, seeMoreWorkoutsList: false })
		}
	}

	getOnDemandDetail = (workoutId) => {
		this.props.ondemandDetail(workoutId, this.props.params.type).then(
			response => {
				this.props.ondemandCMSModelOpen({ open: true, type: 'edit' })
			}, error => {
				console.log('err', error)
			});
	}

	/* Call on-demand list after saving workout CMS. */
	afterSaveCMS = () => {
		this.getOnDemandList();
	}

	/**
	 * Search workout render.
	 * @param {Array} searchWorkouts 
	 * @returns 
	 */
	renderSearchWorkouts = (searchWorkouts) => {
		return (
			<div className="col-sm-12 four_grid pr-0 four_cardvid" id="container-search-content">
				<div className="row justify-content-center align-items-center m-t-30">
					{searchWorkouts && searchWorkouts.length > 0 && searchWorkouts.map((workout, key) => {
						return (
							<>
								{workout.id &&
									<div class="col-lg-3 col-md-6 col-sm-12 m-b-15 pl-0" key={"session_" + key}>
										<div class="border-white">
											<div class="position-relative">
												<Link
													to={"/detail/" + workout.id + '/' + this.props.params.condition}
													className={"pointer class-anchor position-relative d-block position-relative border-0"}
													onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
												>
													{/* completed workout count label */}
													{this.renderCompletedWorkoutCount(workout)}
													<ImageTag ref={node => { this.wrapperRef = node; }}
														className="img-fluid image-size-new img-responsive"
														src={
															constand.WORKOUT_IMG_PATH +
															workout.id +
															"-img.png"
														}
														thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
													/>
													<span className="position-absolute vid_time"><span>{workout.length} mins</span></span>
												</Link>
												{this.props.is_create_mode && <span className="card-edit-icon" onClick={() => this.getOnDemandDetail(workout.id)}><i className="fa fa-pencil orangefont pointer"></i></span>}
											</div>
										</div>
									</div>
								}
							</>

						)
					})}
					{!this.state.loader && searchWorkouts.length && !searchWorkouts[0].id &&
						<div class="row">
							<div class="text-center w-100">Watch this space! On-demand videos for this health condition are coming soon...
							</div>
						</div>
					}
				</div>
			</div>
		)
	}

	/**
	 * See more workout render.
	 * @param {Array} seeMoreWorkouts 
	 * @returns 
	 */
	renderSeeMoreWorkouts = (seeMoreWorkouts) => {
		return (
			<div className="col-sm-12 four_grid pr-0 four_cardvid">
				<div className="row justify-content-center align-items-center m-t-30">
					{seeMoreWorkouts && seeMoreWorkouts.length > 0 && seeMoreWorkouts.map((workout, key) => {
						return (
							<>
								{workout.id &&
									<div class="col-lg-3 col-md-6 col-sm-12 m-b-15 pl-0" key={"session_" + key}>
										<div class="border-white">
											<div class="position-relative">
												<Link
													to={"/detail/" + workout.id + '/' + this.props.params.condition}
													className={"pointer class-anchor position-relative d-block position-relative border-0"}
													onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
												>
													{/* completed workout count label */}
													{this.renderCompletedWorkoutCount(workout)}
													<ImageTag ref={node => { this.wrapperRef = node; }}
														className="img-fluid image-size-new img-responsive"
														src={
															constand.WORKOUT_IMG_PATH +
															workout.id +
															"-img.png"
														}
														thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
													/>
													<span className="position-absolute vid_time"><span>{workout.length} mins</span></span>
												</Link>
											</div>
										</div>
									</div>
								}
							</>

						)
					})}
					{!this.state.loader && seeMoreWorkouts.length && !seeMoreWorkouts[0].id &&
						<div class="row">
							<div class="text-center w-100">Watch this space! On-demand videos for this health condition are coming soon...
							</div>
						</div>
					}
				</div>
			</div>
		)
	}

	/**
	 * Body workouts list render web.
	 * @param {Object} workouts 
	 * @param {Number} key 
	 * @returns 
	 */
	renderBodyWorkoutListWeb = (workouts, key) => {
		return (
			<div key={key} className="demand-section--3 m-t-33 d-none d-lg-block d-md-block ml-3 mr-3">
				<div key={key} className="row position-relative landing_swiper swipernavDetail">
					<Swiper
						key={key}
						containerModifierClass="w-90 "
						breakpoints={{ 1: { slidesPerView: 1, spaceBetweenSlides: 0 }, 1000: { slidesPerView: 4, spaceBetweenSlides: 50 } }}
						spaceBetween={20}
						initialSlide={this.props.active_slider}
						observeParents={true}
						observer={true}
						observeSlideChildren={true}
						watchSlidesProgress={true}
						onSlideChange={() => { }}
						loop={false}
						navigation={{ nextEl: '.swipernavDetail .swiper-button-next-' + key, prevEl: '.swipernavDetail .swiper-button-prev-' + key }}
						className="mySwiper"
						onSwiper={(swiper, key) => {
							//console.log("swiper")
							this.swiperRef.current = swiper;
						}}
					>
						{(<div className="col-md-12 col-lg-12 col-sm-12 p-0">
							<div className="row">
								{workouts && workouts.workout && workouts.workout.length > 0 && workouts.workout.map((item, key) => {
									return (
										<SwiperSlide key={key}>
											<div className="card complet_items_an border-0" key={"session_" + key}>
												<div className="position-relative" key={key}>
													<Link
														to={"/detail/" + item.id + '/' + this.props.params.condition}
														className={"pointer class-anchor position-relative d-block position-relative border-0"}
														onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
													>
														{/* completed workout count label */}
														{this.renderCompletedWorkoutCount(item, workouts)}
														<ImageTag ref={node => { this.wrapperRef = node; }}
															className="img-fluid image-size-new img-responsive"
															src={
																constand.WORKOUT_IMG_PATH +
																item.id +
																"-img.png"
															}
															thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
														/>
														<span className="position-absolute vid_time"><span>{item.length} mins</span></span>
													</Link>
													{this.props.is_create_mode && <span className="card-edit-icon" onClick={() => this.getOnDemandDetail(item.id)}><i className="fa fa-pencil orangefont pointer"></i></span>}
												</div>
											</div>
										</SwiperSlide>
									)
								})}
							</div>
						</div>
						)}
					</Swiper>
					{workouts && workouts.workout.length >= 5 && <div class={"swiper-button-prev swiper-button-prev-" + key}></div>}
					{workouts && workouts.workout.length >= 5 && <div class={"swiper-button-next swiper-button-next-" + key}></div>}
				</div>
			</div>
		)
	}

	/**
	 * Body workouts list render Mobile.
	 * @param {Object} workouts 
	 * @param {Number} key 
	 * @returns 
	 */
	renderSwiperBodyWorkoutListMobile = (workouts, key) => {
		return (
			<div key={key} className="demand-section--3  mt-0">
				<div key={key} className="row position-relative">
					<div className="col-md-12 col-lg-12 col-sm-12 p-0 single-workout-img-mobile">
						<Swiper
							spaceBetween={50}
							slidesPerView={1}
							speed={500}
						>
							{workouts.workout && workouts.workout.length > 0 && workouts.workout.map((item, key) => {
								return (
									<SwiperSlide>
										<div className={"card complet_items_an border-0" + (item.length == 1 ? " single-workout-img-mobile" : "")} key={"session_" + key}>
											<div className="position-relative" key={key}>
												<Link
													to={"/detail/" + item.id + '/' + this.props.params.condition}
													className={"pointer class-anchor position-relative d-block position-relative"}
													onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
												>
													{/* completed workout count label */}
													{this.renderCompletedWorkoutCount(item, workouts)}
													<ImageTag ref={node => { this.wrapperRef = node; }}
														className="img-fluid image-size-new img-responsive"
														src={
															constand.WORKOUT_IMG_PATH +
															item.id +
															"-img.png"
														}
														thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
													/>
													<span className="position-absolute vid_time"><span>{item.length} mins</span></span>
												</Link>
												{this.props.is_create_mode && <span className="card-edit-icon" onClick={() => this.getOnDemandDetail(item.id)}><i className="fa fa-pencil orangefont pointer"></i></span>}
											</div>
										</div>
									</SwiperSlide>
								)
							})}
						</Swiper>
					</div>
				</div>
			</div >
		)
	}

	/**
	 * Body workouts list render Mobile - not in use right and feature changed to renderSwiperBodyWorkoutListMobile .
	 * @param {Object} workouts 
	 * @param {Number} key 
	 * @returns 
	 */
	renderBodyWorkoutListMobile = (workouts, key) => {
		return (
			<div key={key} className="demand-section--3  mt-0">
				<div key={key} className="row position-relative">
					<div className="col-md-12 col-lg-12 col-sm-12 p-0 single-workout-img-mobile">
						<Carousel
							key={key}
							showArrows={false}
							showIndicators={false}
							showStatus={false}
							showThumbs={false}
							centerMode={false}
							autoFocus={false}
							autoPlay={false}
							infiniteLoop={false}
							emulateTouch={true}
							stopOnHover={false}
							swipeable={true}
							transitionTime={300}
							swipeScrollTolerance={100}
						>
							{workouts.workout && workouts.workout.length > 0 && workouts.workout.map((item, key) => {
								return (
									<div className={"card complet_items_an border-0" + (item.length == 1 ? " single-workout-img-mobile" : "")} key={"session_" + key}>
										<div className="position-relative" key={key}>
											<Link
												to={"/detail/" + item.id + '/' + this.props.params.condition}
												className={"pointer class-anchor position-relative d-block position-relative"}
												onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
											>
												{/* completed workout count label */}
												{this.renderCompletedWorkoutCount(item, workouts)}
												<ImageTag ref={node => { this.wrapperRef = node; }}
													className="img-fluid image-size-new img-responsive"
													src={
														constand.WORKOUT_IMG_PATH +
														item.id +
														"-img.png"
													}
													thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
												/>
												<span className="position-absolute vid_time"><span>{item.length} mins</span></span>
											</Link>
											{this.props.is_create_mode && <span className="card-edit-icon" onClick={() => this.getOnDemandDetail(item.id)}><i className="fa fa-pencil orangefont pointer"></i></span>}
										</div>
									</div>
								)
							})}
						</Carousel>
					</div>
				</div>
			</div>
		)
	}


	/**
	 * Current Mobile view body section.
	 * @param {Object} workouts 
	 * @param {Number} key 
	 * @returns 
	 */
	renderSwipableContent = (workouts, key) => {
		return (
			<div key={key} className="demand-section--3  mt-0 ">
				<div key={key} className="row position-relative">
					<div className={"col-md-12 col-lg-12 col-sm-12 p-0 single-workout-img-mobile "}>

						{workouts.workout && workouts.workout.length > 0 && workouts.workout.map((item, key) => {
							return (
								<div className={"card complet_items_an border-0 slide " + (item.length == 1 ? " single-workout-img-mobile" : "")} key={"session_" + key}>
									<div className="position-relative" key={key}>
										<Link
											to={"/detail/" + item.id + '/' + this.props.params.condition}
											className={"pointer class-anchor position-relative d-block position-relative"}
											onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
										>
											{/* completed workout count label */}
											{this.renderCompletedWorkoutCount(item, workouts)}
											<ImageTag ref={node => { this.wrapperRef = node; }}
												className="img-fluid image-size-new img-responsive"
												src={
													constand.WORKOUT_IMG_PATH +
													item.id +
													"-img.png"
												}
												thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
											/>
											<span className="position-absolute vid_time"><span>{item.length} mins</span></span>
										</Link>
										{this.props.is_create_mode && <span className="card-edit-icon" onClick={() => this.getOnDemandDetail(item.id)}><i className="fa fa-pencil orangefont pointer"></i></span>}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div >
		)
	}


	/* Spotlight banner section web view. */
	renderSpotlightSectionWeb = () => {
		return (
			<div className="container-fluid mx-auto p-0 d-none d-lg-block d-md-block">
				<section class="condemand spotlight_page">
					<div class="section-1 position-relative">
						<ImageTag
							className="sp-banner position-absolute"
							src={
								this.state.ondemandWorkoutList.SpotlightSection.image
									? constand.WEB_IMAGES + this.state.ondemandWorkoutList.SpotlightSection.image
									: constand.WEB_IMAGES + "spotlight_banner.png"
							}
							thumb={constand.WEB_IMAGES + "spotlight_banner.png"}
						/>

						<div class="section-header p-t-30 p-b-16 position-relative top-0 p-l-10">
							<div class="col-md-12">
								<div class="row">
									<div class="col-md-7 col-sm-12 banner-top-section">
										<h5 class="font-54 font-medium m-t-58 m-b-88 col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12 pl-0 banner-text" style={{ color: this.state.ondemandWorkoutList.SpotlightSection.titleColour }}>
											{this.state.ondemandWorkoutList.SpotlightSection && this.state.ondemandWorkoutList.SpotlightSection.title}</h5>
									</div>
								</div>
								<div>
									<div className="demand-section--3 d-none d-lg-block d-md-block ml-3 mr-3">
										<div className="row position-relative  landing_swiper swipernavDetail">
											<div class="col-md-12 col-lg-12 col-sm-12 p-0 single-workout-img-mobile spotlight-swiper-web">
												<Swiper
													containerModifierClass="w-100 "
													breakpoints={{ 1: { slidesPerView: 1, spaceBetweenSlides: 0 }, 1000: { slidesPerView: 2, spaceBetweenSlides: 50 } }}
													spaceBetween={20}
													initialSlide={this.props.active_slider}
													observeParents={true}
													observer={true}
													observeSlideChildren={true}
													watchSlidesProgress={true}
													onSlideChange={() => { }}
													loop={false}
													navigation={{ nextEl: '.swipernavDetail .swiper-button-next-', prevEl: '.swipernavDetail .swiper-button-prev-' }}
													className="mySwiper"
													onSwiper={(swiper, key) => {
														//console.log("swiper")
														this.swiperRef.current = swiper;
													}}
												>
													{this.state.ondemandWorkoutList.SpotlightSection.workoutDetails && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.length > 0 && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.map((workout, key) => {
														return (
															<div className={"card bg-transparent complet_items_an border-white " + (workout.length == 1 ? " single-workout-img-mobile" : "")} key={"session_" + key}>
																<SwiperSlide key={key}>
																	<div className="card complet_items_an bg-transparent complet_items_an border-white web-workouts ml-0" key={"session_" + key}>
																		<div className="position-relative" key={key}>
																			<Link
																				to={"/detail/" + workout.id + '/' + this.props.params.condition}
																				className={"pointer class-anchor position-relative d-block position-relative border-0"}
																				onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
																			>
																				<ImageTag ref={node => { this.wrapperRef = node; }}
																					className="img-fluid image-size-new img-responsive"
																					src={
																						constand.WORKOUT_IMG_PATH +
																						workout.id +
																						"-img.png"
																					}
																					thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
																				/>
																				<span class="position-absolute vid_time vid_time_sp"><span>{workout.length} mins</span></span>
																			</Link>
																		</div>
																	</div>
																	{this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.length == key + 1 && <div>
																		{<button class="btn btn-orange btn-see btn-see-webview font-book font-18" onClick={() => this.seeMoreWorkouts()}>See more</button>}
																		{/* this.state.seeMoreWorkoutsList && <button class="btn btn-orange btn-see btn-see-webview font-book font-18 " onClick={() => this.seeLessWorkouts()}>See less</button> */}
																	</div>}
																</SwiperSlide>
															</div>
														)
													})
													}

												</Swiper>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}

	/* Spotlight banner section Mobile view. */
	renderSpotlightSectionMobile = () => {
		return (
			<div className="d-block d-lg-none d-md-none">
				<section class="condemand spotlight_page">
					<div class="section-1 position-relative">
						<ImageTag
							className="sp-banner position-absolute"
							src={
								this.state.ondemandWorkoutList.SpotlightSection.mobileImage
									? constand.WEB_IMAGES + this.state.ondemandWorkoutList.SpotlightSection.mobileImage
									: constand.WEB_IMAGES + "spotlight_banner.png"
							}
							thumb={constand.WEB_IMAGES + "spotlight_banner.png"}
						/>
						<div class="section-header position-relative top-0 pl-3">
							<div class="col-md-12">
								<div class="row">
									<h5
										class="font-54 font-semibold m-t-80 m-b-70 col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12 ml-3 spotlight-mobile-tilet" style={{ color: this.state.ondemandWorkoutList.SpotlightSection.titleColour }}>
										{this.state.ondemandWorkoutList.SpotlightSection && this.state.ondemandWorkoutList.SpotlightSection.title}</h5>
								</div>
								<div className={(this.state.ondemandWorkoutList.SpotlightSection.workoutDetails && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.length == 1 ? " single-slide-width" : "wrapper")}>
									<div className="demand-section--3  mb-5 ">
										<div className="row position-relative">
											<div class="col-md-12 col-lg-12 col-sm-12 p-0 single-workout-img-mobile ">
												{this.state.ondemandWorkoutList.SpotlightSection.workoutDetails && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.length > 0 && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.map((workout, key) => {
													return (
														<div className={"card bg-transparent complet_items_an border-0 slide " + (workout.length == 1 ? " single-workout-img-mobile" : "")} key={"session_" + key}>
															<div className="position-relative" key={key}>
																<div>
																	<Link
																		to={"/detail/" + workout.id + '/' + this.props.params.condition}
																		className={"pointer class-anchor position-relative d-block position-relative border-0"}
																		onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
																	>
																		<ImageTag ref={node => { this.wrapperRef = node; }}
																			className="img-fluid image-size-new img-responsive border-white"
																			src={
																				constand.WORKOUT_IMG_PATH +
																				workout.id +
																				"-img.png"
																			}
																			thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
																		/>
																		<span class="position-absolute vid_time vid_time_sp"><span>{workout.length} mins</span></span>
																	</Link>
																</div>
															</div>
														</div>
													)
												})}
												{this.state.ondemandWorkoutList.SpotlightSection.workoutDetails && this.state.ondemandWorkoutList.SpotlightSection.workoutDetails.length > 0 && <>
													{<button class="btn btn-orange btn-see font-book font-18 mt-0" onClick={() => this.seeMoreWorkouts()}>See more</button>}
													{/* this.state.seeMoreWorkoutsList && <button class="btn btn-orange btn-see font-book font-18 mt-0" onClick={() => this.seeLessWorkouts()}>See less</button> */}
												</>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}

	/**
	 * completed workout count label design.
	 * @param {Object} item 
	 * @returns 
	 */
	renderCompletedWorkoutCount = (item) => {
		let workoutId = item.id
		return (
			<>
				{(Object.keys(this.props.completed_workout_count).length && this.props.completed_workout_count[workoutId]) ?
					<div className="workout-status">
						<span className="oval"><img src={constand.WEB_IMAGES + "tick_mark.png"} />
						</span>
						<span className="rectangle">
							<span className="completed-times"> Completed {(Object.keys(this.props.completed_workout_count).length > 0 && this.props.completed_workout_count[workoutId] && this.props.completed_workout_count[workoutId] === 1) ? this.props.completed_workout_count[workoutId] + ' time' : this.props.completed_workout_count[workoutId] + ' times'} </span>
							{/* <span className="completed-times"> Completed {item.no_watch} {item.no_watch == 1 ? 'time' : 'times'}</span> */}
						</span>
					</div>
					: null}
			</>
		)
	}

	/* Render browse categories  design. */
	renderBrowseCategories = () => {
		let typeFilter = commonService.replaceChar(this.state.typeFilter, true);
		return (
			<>
				<OndemandBrowseCategories
					is_filter_open={this.props.show_browse_categories}
					available_filter={this.props.ondemand_browse_categories}
					is_web_view={this.state.isWebView}
					condition={typeFilter}
					scrollToCategory={this.scrollToCategory}
					history={this.props.history}
				/>
			</>
		)
	}

	/**
	 * Show brose categories list.
	 * @param {Boolean} flag 
	 */
	showBrowseCategoriesList = (flag) => {
		this.setState({ isWebView: flag })
		document.body.classList.add('removebbg-popup');
		this.props.showBrowseCategories(!this.props.show_browse_categories)
	}

	/* Hide brose categories list. */
	hideBrowseCategoriesList = () => {
		document.body.classList.remove('removebbg-popup');
		this.props.showBrowseCategories(false)
	}

	/* Scroll to category section. */
	scrollToCategory = (ref) => {
		let me = this;
		//var yourHeight = document.getElementsByClassName('navbar navbar-expand-lg navbar-light bg-white fixed-top')[0].getBoundingClientRect().height;
		//me.refs[ref].scrollIntoView(true);
		// now account for fixed header
		setTimeout(() => {
			/* me.refs[ref].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				//inline: 'start'
			}); */

			//me.refs[ref].scrollIntoView(true);
			/* var scrolledY = window.scrollY;
			if (scrolledY) { */
			let dims = me.refs[ref] && me.refs[ref].getBoundingClientRect();
			//window.scrollTo(window.scrollX, dims.top - 80 + window.scrollY); //working code
			window.scrollTo({ top: dims && dims.top - 80 + window.scrollY, behavior: "smooth" });
			//}
		}, 200);
	}

	refreshOndemandWorkout = async () => {
		this.setState({ category_loader: true });
		let percentage = 0;
		const percentageUpdate = setInterval(() => {
			if (percentage < 99) {
				percentage += 3;
				this.setState({ percentage });
			}
		}, 1000);
		try {
			await this.props.refreshWorkoutList();
			this.props.clearOndemandDetail();
			if (this.props.is_auth) {
				this.props.getCompletedWorkoutCount(this.state.typeFilter);
			}
			this.getOnDemandList();
			clearInterval(percentageUpdate);
			this.setState({ percentage: 100 }, () => {
				this.clearCategoryLoader()
			});
		} catch (error) {
			console.log("Loader Error", error)
			this.clearCategoryLoader();
		}
	};

	clearCategoryLoader = () => {
		setTimeout(() => {
			this.setState({ percentage: 0, category_loader: false });
		}, 500)
	}

	browseCategoryName = (workouts, key) => {
		if (workouts.category) {
			return commonService.removeSpaceAndTrim(workouts.category.value)
		} else if (workouts.category_name) {
			return commonService.removeSpaceAndTrim(workouts.category_name)
		} else {
			return key;
		}
	}

	renderCategoryLoader = () => {
		return (
			<>
				{this.state.category_loader ? this.state.percentage == 100 ? (("Completed " + `${this.state.percentage}%`)) : ("Loading... " + `${this.state.percentage}%`) : "Refresh Categories"}
			</>
		)
	}

	render() {
		let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
		return (
			<React.Fragment>
				{!this.loader ? <div className="pb-4 pt-0 active demand-list">
					<Helmet>
						<title>{constand.ONDEMAND_TITLE}{this.state.typeFilter}{constand.BEAM}</title>
						<meta property="og:title" content={constand.ONDEMAND_TITLE + this.state.typeFilter + constand.BEAM} />
						<meta property="og:description" content={constand.ONDEMAND_DESC} />
						<meta property="og:image" content={constand.ONDEMAND_PAGE_IMAGE} />
						<meta property="og:url" content={window.location.href} />
						<meta name="twitter:card" content="summary_large_image" />
						<meta property="og:site_name" content="Beam" />
						<meta name="twitter:image:alt" content={constand.ONDEMAND_PAGE_IMAGE_ALT} />
					</Helmet>
					<AdminBanner condition={this.props.params.condition} />
					{this.state.ondemandWorkoutList && Object.keys(this.state.ondemandWorkoutList).length > 0 ? (<div>
						{/* Spotlight banner section web view */}
						{this.state.ondemandWorkoutList && Object.keys(this.state.ondemandWorkoutList).length > 0 ? (this.renderSpotlightSectionWeb()) : null}

						{/* Spotlight banner section mobile view */}
						{this.state.ondemandWorkoutList && Object.keys(this.state.ondemandWorkoutList).length > 0 ? (this.renderSpotlightSectionMobile()) : null}
						<section class="condemand m-l-2 mr-5">
							<div class="container-xxl mx-auto p-0">
								<div class="row">
									{/* mobile view search bar */}
									<div className="filter-sm-section col-md-12 m-t-20 d-block d-sm-block d-md-none d-lg-none justify-content-center">

										<div className="row">
											<OndemandSearchComponent
												searchSuggestion={this.state.searchKeywordsList}
												searchBarEnter={this.searchBarEnter.bind(this)}
												handleChange={this.handleChange}
												searchFunction={this.searchFunction}
												loader={this.state.loader}
												isMobileView={true}
												backToSearch={this.backToSearch}
												onSearchInput={this.onSearchResult}
												pageFrom='ondemand'
												searchData={this.state.search_data}
												options={this.state.ondemandWorkoutList.SearchBarSection}
											/>
										</div>

										{(this.state.isShowSearchWorkout || this.state.seeMoreWorkoutsList) && <div className="col-md-5 col-sm-12 col-xl-2 browse-all" style={{ margin: "auto 0px" }}>
											<div className="back-btn m-b-20">
												<span onClick={() => this.backToSearch()}> <i class="fa fa-chevron-left" aria-hidden="true"></i> Back </span>
											</div>
										</div>
										}
										{this.props.ondemand_browse_categories && Object.keys(this.props.ondemand_browse_categories).length > 0 && !this.state.isShowSearchWorkout && !this.state.seeMoreWorkoutsList &&
											<div className="col-md-5 col-sm-12 col-xl-2 browse-all" style={{ margin: "auto 0px" }}>
												<span className="browse-all font-16 font-medium" onClick={() => this.showBrowseCategoriesList(false)}> Browse all categories <i class="fa fa-chevron-down" aria-hidden="true"></i> </span>
											</div>}

										{this.props.is_auth && this.props.is_create_mode && <div className="col-md-4 col-sm-12 col-xl-2 add-class-btn d-none">
											<button className="btn btn-add-class font-18 w-100 text-center" onClick={() => { this.props.ondemandCMSModelOpen({ open: true, type: 'add' }); this.props.clearOndemandDetail() }}>
												<i class="fa fa-plus" aria-hidden="true"></i>
												Add class
											</button >
										</div>
										}
									</div>

									{/* web view search bar */}
									<div className="filter-section col-md-12 m-t-10 mb-0 d-none d-sm-none d-md-block d-lg-block p-0 ">
										<div className="row m-t-20 mb-0 search-section-order">
											<div className="col-md-3 col-sm-12 col-xl-2 browse-all pr-0 p-l-30" style={{ margin: "auto 0px" }}>
												{(this.state.isShowSearchWorkout || this.state.seeMoreWorkoutsList) && <div className="back-btn">
													<span className="font-16 font-medium back-arrow-black" onClick={() => this.backToSearch()}> <i class="fa fa-chevron-left" aria-hidden="true"></i> Back </span>
												</div>
												}
												{this.props.ondemand_browse_categories && Object.keys(this.props.ondemand_browse_categories).length > 0 && !this.state.isShowSearchWorkout && !this.state.seeMoreWorkoutsList &&
													<span className="browse-all font-16 font-medium" onClick={() => this.showBrowseCategoriesList(true)}> Browse all categories <i class="fa fa-chevron-down" aria-hidden="true"></i> </span>
												}

											</div>
											<div className={"col-md-5 col-sm-12  input-search-clear position-relative " + (this.props.is_create_mode ? "col-xl-6 search-bar-width" : "col-xl-8 search-bar-tab")} ref={this.searchRef} >
												<OndemandSearchComponent
													searchSuggestion={this.state.searchKeywordsList}
													searchBarEnter={this.searchBarEnter.bind(this)}
													handleChange={this.handleChange}
													searchFunction={this.searchFunction}
													loader={this.state.loader}
													isMobileView={false}
													backToSearch={this.backToSearch}
													onSearchInput={this.onSearchResult}
													pageFrom='ondemand'
													searchData={this.state.search_data}
													options={this.state.ondemandWorkoutList.SearchBarSection}
												/>
											</div>
											{this.props.is_auth && this.props.is_create_mode && <div className="col-md-2 col-sm-12 col-xl-2 add-class-btn">
												<button className="btn btn-add-class  font-18 w-100 text-center" onClick={() => { this.props.ondemandCMSModelOpen({ open: true, type: 'add' }); this.props.clearOndemandDetail() }}>
													<i class="fa fa-plus" aria-hidden="true"></i>
													Add class
												</button >
											</div>
											}
											{this.props.is_auth && this.props.is_create_mode && <div className="col-md-2 col-sm-12 col-xl-2 add-class-btn">
												<button className="btn font-18 w-100 text-center btn-refresh-workouts" disabled={this.state.category_loader} onClick={() => { this.refreshOndemandWorkout() }}>
													{this.renderCategoryLoader()}
												</button >
											</div>
											}
										</div>
									</div>

									{/* browse all category list */}
									{this.props.ondemand_browse_categories && Object.keys(this.props.ondemand_browse_categories).length > 0 &&
										this.renderBrowseCategories()
									}
								</div>
								{this.state.category_loader &&
									<p className="text-center m-t-20 m-b-10 font-16 font-medium">You should not reload or moving any other pages until the loading complete. Otherwise you might lost the data.
									</p>
								}
							</div>

							<div class="container-fluid mx-auto p-0">
								<div class="row">

									{/* body workout section web view */}
									{!this.state.isShowSearchWorkout && !this.state.seeMoreWorkoutsList && this.state.ondemandWorkoutList.BodySection && this.state.ondemandWorkoutList.BodySection.length > 0
										&& this.state.ondemandWorkoutList.BodySection.map((workouts, key) => {
											let browseType = this.browseCategoryName(workouts, key)
											return (
												<>
													{workouts.workout && workouts.workout.length > 0 && <div id={browseType} class="col-sm-12 four_grid pr-0 m-t-30 four_cardvid d-none d-lg-block d-md-block" ref={browseType}>
														<div class="col-lg-12 col-md-12 col-sm-12 pl-0">
															<h4 class="m-b-15 darkblue-text font-semibold font-normal font-24 card-block" style={{ color: workouts.titleColour }}>{workouts.title}
															</h4>
														</div>
														{this.renderBodyWorkoutListWeb(workouts, workouts.position)}
													</div>}
												</>
											)
										})}

									{/* body workout section mobile view */}
									{!this.state.isShowSearchWorkout && !this.state.seeMoreWorkoutsList && this.state.ondemandWorkoutList.BodySection && this.state.ondemandWorkoutList.BodySection.length > 0
										&& this.state.ondemandWorkoutList.BodySection.map((workouts, key) => {
											let browseType = this.browseCategoryName(workouts, key)
											return (
												<div key={key} id={'mob-' + browseType} class="col-sm-12 four_grid pr-0 four_cardvid d-block d-lg-none d-md-none m-b-0 " ref={'mob-' + browseType}>
													{workouts.workout && workouts.workout.length > 0 && <div class="col-lg-12 col-md-12 col-sm-12 m-b-0 pl-0 py-1_5em">
														<h4 class="m-b-0 darkblue-text font-semibold font-normal font-18 card-block" style={{ color: workouts.titleColour }}>{workouts.title}
														</h4>
													</div>}
													{//workouts.workout && workouts.workout.length > 0 && this.renderBodyWorkoutListMobile(workouts, key)
													}
													{//workouts.workout && workouts.workout.length > 0 && this.renderSwiperBodyWorkoutListMobile(workouts, key)
													}
													{workouts.workout && workouts.workout.length > 0 && (<div className={(workouts.workout && workouts.workout.length == 1 ? " single-slide-width" : "wrapper")}>{this.renderSwipableContent(workouts, workouts.position)} </div>)}
												</div>
											)
										})
									}

									{/* search workout result render */}
									{
										!this.state.seeMoreWorkoutsList && this.state.search_data && this.state.isShowSearchWorkout && this.renderSearchWorkouts(this.state.ondemandWorkoutList.BodySection)
									}

									{/* seeMore workout result render */}
									{
										this.state.seeMoreWorkoutsList && this.renderSeeMoreWorkouts(this.state.ondemandWorkoutList.BodySection)
									}
								</div>
							</div>
						</section>
					</div>) : null}
					{!this.state.loader && this.state.ondemandWorkoutList.BodySection && !this.state.ondemandWorkoutList.BodySection.length > 0 &&
						<div class="row">
							<div class="text-center w-100">Watch this space! On-demand videos for this health condition are coming soon...
							</div>
						</div>
					}
					{!this.state.loader && this.state.ondemandWorkoutList && !this.state.ondemandWorkoutList.length > 0 && !this.state.ondemandWorkoutList.BodySection &&
						<div class="row">
							<div class="text-center w-100">Watch this space! On-demand videos for this health condition are coming soon...
							</div>
						</div>
					}
					<div className="text-center w-100">
						{this.state.loader && (this.state.seeMoreWorkoutsList || this.state.search_data) && (<AnimateLoaderComponent />)}
					</div>
				</div > : null}
				<div className="text-center w-100">
					{this.state.loader && !this.state.seeMoreWorkoutsList && !this.state.search_data && <AnimateLoaderComponent />}
				</div>
				<div>
					{this.props.is_cms_model &&
						<WorkoutCMSComponent getList={this.afterSaveCMS} />
					}
				</div>
			</React.Fragment >
		);
	}
}

const mapStateToProps = state => {
	return {
		is_auth: state.auth.is_auth,
		workout_list: state.workout.workout_list,
		isGoback: state.workout.isGoback,
		currentPosition: state.workout.currentPosition,
		is_create_mode: state.header.is_create_mode,
		workout_list_tags: state.workout.workout_list_tags,
		workout_list_level: state.workout.workout_list_level,
		ondemand_list: state.workout.ondemand_list,
		is_cms_model: state.workout.is_cms_model,
		ondemand_browse_categories: state.workout.ondemand_browse_categories,
		show_browse_categories: state.workout.show_browse_categories,
		body_workout_list: state.workout.ondemand_body_workout_list,
		body_workout_json: state.header.body_workout_json,
		completed_workout_count: state.workout.completed_workout_count

	};
};

const mapDispatchToProps = {
	pushHistory,
	ondemandWorkoutList,
	workoutSearchSuggestionList,
	ondemandCMSModelOpen,
	clearOndemandDetail,
	ondemandDetail,
	getOnDemandBrowseCategories,
	showBrowseCategories,
	getBodyWorkoutList,
	refreshWorkoutList,
	getCompletedWorkoutCount,
	openClinicianPostSessionPopup,
    cliniciansGroupSessionData

};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OndemandListComponent);