import React from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { submitPostPoll } from "../../actions";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import InputRange from "react-input-range";
import ReactGA from 'react-ga';
import { Cookies } from "react-cookie-consent";
import { commonService } from "../../_services";

class ResearchPostPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: false,
      rating: 5,
      irehabSelectRating: 0,
      yogaCfQuestions:constand.YOGACF_QUESTIONARY,
      yogaValue:[{"By doing this yoga session, do you think you have cleared more or less sputum than what you usually would have cleared had you have just rested for the same time?":"",
    },{"Has your breathing changed after this session?":""}]
    };
  }


  onCloseModal() {
    this.props.closeResearchPostModel();
  }

  submitPoll(isChangeUrl = false,isYogaCF=false) {
    let dataObj = {
      roomId: this.props.current_attendee.RoomId,
      rateOfExertion: this.state.rating,
      classType: this.props.classType,
      isYogaCF:false
    };
    if(isYogaCF){
      dataObj = {
        roomId:this.props.current_attendee.RoomId,
        classType: this.props.classType,
        stateSinceLastSession:this.state.yogaValue,
        isYogaCF:true
      };
    }
    this.props.submitPostPoll(dataObj).then(
      response => {
        if (response) {
          this.props.closeResearchPostModel();
          if (isChangeUrl) {
            let community = this.props.logged_userData.Members.length ? this.props.logged_userData.Members[0].Community.community_name : '';
            let pathname_data = community ? '/group/feed/' + community + '/' + this.props.condition : '/groups/' + this.props.condition;
            const { from } = { from: { pathname: pathname_data } };
            this.props.history.push(from);
          }
          // toast.success(response.message);
        }
      },
      error => {
        this.props.closeResearchPostModel();
        toast.error(error);
      }
    );
  }

  handleSelect(event) {
    this.setState({ rating: event.target.value, enable: true });
  }

  closeModel() {
    this.props.closeResearchModel();
  }

  irehabPopupRender = (irehabRating) => {
    return (
      <div >
        <h6 class="sub-title font-qregular font-16 w-100 mb-4">How useful did you find today's exercise session?</h6>
        <div class="progressbar">
          {irehabRating && irehabRating.map((list, index) => {
            index += 1;
            return (
              <div class="progresses">
                <button key={index} className={list.class + " " +
                  (index <= (this.state.irehabSelectRating) ? list.roundClass : "")} id={list.id} onClick={() => this.selectIrehabRating(index, list.id)} ></button>
                <span>{list.id}</span>
                <span>{list.value}</span>
              </div>
            )
          })
          }
        </div>
      </div>
    )
  }

  selectIrehabRating = (index, rating) => {
    this.setState({
      irehabSelectRating: index,
      rating: rating,
      enable: true
    });
  }
  submitButtonRender = (isCreateC = false,isYogaCF=false) => {
    return (

      <div class="text-center submit-btn mt-4 col-md-10 mx-auto mb-4 clearfix">
        <button class={this.state.enable ? "bluebtn float-left w-100 text-center pointer font-semibold font-14" : "btn-disabled float-left w-100 text-center pointer font-semibold font-14"} onClick={() => this.submitPoll(isCreateC,isYogaCF)} disabled={!this.state.enable}>Submit</button>
      </div>
    )
  }
  submitYogaButtonRender = (isCreateC = false,isYogaCF=false) => {
    return (

      <div class="text-center submit-btn mt-4 col-md-12 mx-auto mb-4 clearfix">
        <button class={this.state.enable ? "bluebtn  w-auto text-center pointer font-semibold font-14 px-5 py-2" : "btn-disabled  w-auto text-center pointer font-semibold font-14  px-5 py-2"} onClick={() => this.submitPoll(isCreateC,isYogaCF)} disabled={!this.state.enable}>Submit</button>
      </div>
    )
  }
  handleYogaSubmit(event, label,index) {
    var newValue = [...this.state.yogaValue];
    var exist, isComplete2
    const { name, value } = event.target;
    newValue.forEach((item,key) => {
      if((key+1)==index){
        item[name]=event.target.value 
      }
    })
    this.setState({ yogaValue:newValue })
  }
   componentDidUpdate(preProps,prevState){
    if (this.state.yogaValue !== prevState.yogaValue) {
      const isComplete= this.state.yogaValue.every(question => !Object.values(question).some(value => !value));
      this.setState({enable:isComplete? true:false})
    }
  };
  yogacfPopupRender = () => {
    return (
      <div >
        <h6 class="sub-title font-qregular font-16 w-100 mb-4 mt-3">How useful did you find today's exercise session?</h6>
        <div class="progressbar">
        <div class="col-md-12">
          {this.state.yogaCfQuestions && this.state.yogaCfQuestions.map((list, index) => {
            index += 1;
            return (
              <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 col-6 p-0">
                  <p class="font-qmedium font-16 lable-rad mt-4 mb-4">{list.ques}</p>
                </div>
                <div class="col-md-12 col-6 p-0">
                {list.options.map((item,key) => (
                  <span class="float-left font-qbold option-head w-20">
                    <div class="mradio m-0">
                      <input id={key+item.id} name={list.ques} type="radio" value={item.value} onClick={(e) => this.handleYogaSubmit(e, list.ques,index)} />
                      <label for={key+item.id} class="radio-label yogacf">{item.value}</label>
                    </div>
                  </span>
                ))}
                </div>
              </div>
            </div>
            )
          })
          }
          </div>
        </div>
      </div>
    )
  }


  render() {
    let createcTrial = commonService.replaceChar(this.props.condition.toLowerCase(), true).replace('research studies: ', '');
    let isCreateC = (createcTrial.includes(constand.CREATEC_CONDITION));
    console.log('ResearchPostPopup=', createcTrial)
    console.log('isCreateC=', isCreateC,this.props.is_model_open,this.state)
    let ratingRange = isCreateC ? constand.RESEARCH_STUDY_POST_POLL_CREATEC : constand.RESEARCH_STUDY_POST_POLL;
    let isIrehab = (createcTrial.includes(constand.IREHAB_CONDITION))
    let irehabRating = isIrehab ? constand.RESEARCH_STUDY_POST_POLL_IREHAB : null;
    let isYogaCF = createcTrial == constand.YOGACF_CONDITION ?true: false;
    return (
      <Modal
        className="removebbg-popup"
        open={this.props.is_model_open}
        onClose={this.props.closeResearchModel}
        center
      >
        <div class="modal-beam-how" id="myModal">
          <div class="modal-dialog-post-poll mx-auto">
            <div class="modal-content">

              <div class="heading-section-beam">

                <button type="button" class="close" data-dismiss="modal" onClick={() => this.onCloseModal()}>&times;</button>
              </div>


              <div class="modal-body border-0">
                <div class="mx-auto col-md-11">
                  <h4 class="modal-title font-semibold font-24 w-100">{isIrehab || isYogaCF? ' Questionnaire Poll' : 'How did it go?'}</h4>
                  {isCreateC && !isIrehab && !isYogaCF &&
                    <h3 class="sub-title font-qregular font-16 mb-4 text-left lengthy-text">
                      <p>A rate of perceived exertion (RPE) scale is a handy tool used to report on how intensely you feel that you're  exercising.</p>
                      <p>Using the scale below let us know how it went by selecting the point on the scale that most closely matches how you felt during the session.</p></h3>
                  }
                  {!isCreateC && !isIrehab && !isYogaCF &&
                    <h3 class="sub-title font-qregular font-16 w-100 mb-4">On the exertion scale, how would you rate the session: </h3>
                  }
                  {!isCreateC && !isIrehab && !isYogaCF && ratingRange.map((list) => (
                    <>
                      <div class={"col-md-12 rate-sec " + list.class + " mb-1"}>
                        <div class="row">
                          <div class="col-md-2 col-2">
                            <span class={'value-rat ' + list.roundClass + ' font-medium font-16'}>{list.id}</span>
                          </div>
                          <div class="col-md-8 col-8 text-center">
                            <span class="value-txt font-medium font-16">{list.value}</span>
                          </div>
                          <div class="col-md-2 col-1 p-r-0 radio-btn">
                            <span class="val-inp">
                              <div class="radionew">
                                <input id={list.id} name="radio" type="radio" value={list.id} onClick={(e) => this.handleSelect(e)} />
                                <label for={list.id} class="radio-label mb-0"></label>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  {/* irehab popup */}
                  {isIrehab && !isYogaCF &&
                    this.irehabPopupRender(irehabRating)
                  }

                  {/* createC submit button  */}
                  {isCreateC && !isIrehab && !isYogaCF && 
                    this.submitButtonRender(isCreateC)
                  }
                  
                  {
                    isYogaCF && this.yogacfPopupRender()
                  }
                  {!isCreateC && !isYogaCF &&
                    this.submitButtonRender(false,isYogaCF)
                  }
                  {isYogaCF && 
                    this.submitYogaButtonRender(false,isYogaCF)                  
                  }

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    logged_userData: state.header.logged_userData,
  };
};

const mapDispatchToProps = {
  submitPostPoll
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchPostPopup);
