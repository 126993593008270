import React, {useState,useEffect} from "react";
import WeightLineChart from "../chart/weightChart";
const WeightTab = ({
    updateCallback,
    data,
    weightChartData,
    tabSelected
}) => {
    const [weightValue,setWeightValue] = useState({
        value:0,
        metric:'kg'
    })
    const weightOnchange = (type)=>(e) => {
      if (e.target.value < 0) return false;
        weightValue[type] = e.target.value;
        setWeightValue({...weightValue})
    }
    const onUpdate = () => {
        updateCallback('weightUpdate',weightValue)
    }

    useEffect(()=>{
      if(data?.weight) {
        setWeightValue({
          ...weightValue,
          value:data?.weight
        })
      }
    },[data?.weight])
  return (
    <React.Fragment>
      <div
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
        className={"tab-pane fade "+(tabSelected==1?"show active":"") }
      >
        <p className="font-16 fw500 font-qmedium ash-txt mobile-font14 hometab-info">
          Please enter your weight to one decimal point.
          <br />
          For example 10.1 stone or 64.1 Kilograms.
        </p>
        <div className="values-update update-btn">
          <input
            type="number"
            min={0}
            // placeholder="5"
            value={weightValue.value}
            className="font-qregular font-14 fw400 black-txt"
            onChange={weightOnchange('value')}
          />
          <div class="form-group custom-select-arrow">
            <select
              class="form-control font-16 fw500 font-qmedium ash-txt"
              id="exampleSelect"
              onChange={weightOnchange('metric')}
              value={weightValue.metric}
            >
              <option value={'kg'}>Kg</option>
              <option value={'mg'}>Mg</option>
            </select>
          </div>
          <a href="javascript:void(0)" className="bluebutton1 font-16 font-qbold fw600 bg-lightblue"
            onClick={onUpdate}
          >
            Update
          </a>
        </div>
        {/* <div className="plcment"> */}
          {weightChartData ? <WeightLineChart weightData={weightChartData}/> : null}
        {/* </div> */}
          
        {/* <figure>
          <img
            src="/images/dashboard/this-week.png"
            className="img-fluid"
            alt="Chart"
          />
        </figure> */}
      </div>
    </React.Fragment>
  );
};

export default WeightTab;
