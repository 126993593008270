import React from "react";
import { truncateString } from "../../dynamicDashboard/utlity";

export default function DashboardGoalsComponent({ goals, handleClick }) {
  return (
    <div className="golas-datas mt-3">
      <div className="data-scroll">
        {goals.length == 0 && (
          <div className="goals-list-item d-flex justify-content-between align-items-center mb-3">
            {" "}
            <div className="col col-wide black-txt font-16 fw700 font-qbold text-center">
              No goals found
            </div>
          </div>
        )}
        {goals.map((item) => {
          return (
            <div
              key={item.id}
              className="goals-list-item d-flex justify-content-between align-items-center mb-3"
            >
              <p className="mb-0 font-16 fw600">
                {truncateString(item.goal, 10)}
              </p>
              {item.isCompleted ? (
                <>
                  <a
                    href="javascript:void(0)"
                    onClick={() => handleClick(item, false)}
                    className="completed-link font-14 font-qbold fw600 black-txt achived-btn "
                  >
                    <img
                      src="/images/dashboard/tick.svg"
                      className="img-fluid align-baseline"
                      alt="tick image"
                    />
                    Achieved
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="javascript:void(0)"
                    className="completed-button font-14 font-qbold fw600 bg-lightblue achived-btn "
                    onClick={() => handleClick(item, true)}
                  >
                    Mark as done
                  </a>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
