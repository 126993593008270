import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import PhoneInput from 'react-phone-input-2'
import { commonService } from "../../_services";
import * as constand from "../../constant";

class LiveClassAttendeesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isemergencyDetail: false,
            emergencyData: [],
            primaryCare: [],
            userList: []
        };
    }
    componentDidMount() {
        console.log("attendee-list", this.props)
    }
    onCloseModal = () => { }
    getEmergency = (list) => {
        console.log('getEmergencylist', list)
        if (list.User.UserEmergencyContacts.length > 0) {
            var emergencyData = list.User.UserEmergencyContacts;
            this.setState({ emergencyContact: emergencyData[0], primaryCare: emergencyData[1], isemergencyDetail: true, userList: list })
        }
    }
    getBack() {
        this.setState({ isemergencyDetail: false })
    }
    render() {
        var attendees = this.props.attendeesClassList.attendees;
        // var physioLed = this.props.attendeesClassList.attendees.Room.Workout.isPhysioLed;
        const { isemergencyDetail, emergencyContact, primaryCare, userList } = this.state;
        console.log('emergencyContact', emergencyContact)
        console.log('primaryCare', primaryCare)
        return (
            <Modal
                className="removebbg-popup"
                open={this.props.is_model_open}
                onClose={this.onCloseModal}
                center
            >
                <div className="modal-dialog modal-width--custom live-classes--popup liveclasses_modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header header-styling  border-0 d-flex align-items-center">
                            {isemergencyDetail &&
                                <a href="javascript:void(0)" onClick={() => this.getBack()} className="arrow_span_long"><span class="fa fa-long-arrow-left"></span></a>
                            }
                            <h5 className="modal-title1 text-center white-txt col-md-12 p-0 font-bold font-22"
                                id="exampleModalLabel "
                            >Class List</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={this.props.closeModel}
                                >&times;</span>
                            </button>
                        </div>
                        {/* Class List Starts Here */}
                        <div className={isemergencyDetail ? "modal-body body-padding--value pb-0 d-none" : "modal-body body-padding--value pb-0 "}>
                            <div className="col-md-12  m-b-30 mr-auto ml-auto">
                                {attendees.length && <ul className="class_list_persons_names">
                                    {attendees.map((list, index) => (
                                        <li>
                                            {this.props.isPhysioLed && list.User.UserEmergencyContacts.length > 1 ?
                                                <a href="javascript:void(0)" onClick={() => this.getEmergency(list)}>{index + 1}. {list.fullName}</a>
                                                :
                                                <div>{index + 1}. {list.fullName}</div>
                                            }
                                        </li>
                                    ))}
                                </ul>
                                    ||
                                    <p className="">No attendees found.</p>
                                }

                            </div>
                            <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                                <a className="btn btn-blue-inverse font-14 read-later m-r-20 pointer" onClick={this.props.closeModel}>Close</a>
                            </div>
                        </div>

                        {/* Class List Ends Here */}
                        {isemergencyDetail &&
                            <div className={isemergencyDetail ? "modal-body body-padding--value pb-0 " : "modal-body body-padding--value pb-0 d-none"}>
                                <div className="col-md-12  m-b-30 mr-auto ml-auto">

                                    <div className="media col-md-12 col-lg-12 p-0 align-items-center mb-4 pb-3">
                                        <img
                                            className="img-fluid rounded-circle pull-left mr-4"
                                            src={
                                                userList.User.profilePic ? constand.USER_IMAGE_PATH + userList.User.profilePic :
                                                    constand.WEB_IMAGES + 'instructor-placeholder.png'}
                                            onError={(e) => commonService.checkImageCrop(e, 'instructor-placeholder.png')}
                                            alt="" width="75" height="75"
                                        />
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-24 font-medium black-txt"
                                        >
                                            {userList.fullName}

                                        </label>
                                    </div>
                                    <div className="form-group phone_inputs_field" >
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            Phone number
                                        </label>
                                        <PhoneInput
                                            country={userList.User.phoneNumber}
                                            value={userList.User.phoneNumber ? userList.User.phoneNumber.toString() : userList.User.phoneNumber}
                                            inputClass="w-100 input_field font-qregular"

                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input_field font-qregular input-control"
                                            id="city"
                                            aria-describedby="emailHelp"
                                            placeholder=""
                                            name=""
                                            value={userList.User.countryState}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="row">
                                                <div class="col-lg-5 col-md-6 col-sm-12 m-b-10 p-l-0">
                                                    <label class="font-14 font-semibold black-txt">Flat, Apt, Suite etc.</label>
                                                    <input type="text" class="form-control input_field font-qregular " id="address1" aria-describedby="emailHelp" placeholder="" name="address1" value={userList.User.address1}
                                                    />
                                                </div>
                                                <div class="col-lg-7 col-md-6 col-sm-12 m-b-10 p-l-18">
                                                    <label class="font-14 font-semibold black-txt">Street address</label>
                                                    <input type="text" class="form-control input_field font-qregular" id="address2" aria-describedby="emailHelp" placeholder="" name="address2" value={userList.User.address2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="row">
                                                <div class="col-lg-5 col-md-6 col-sm-12 m-b-10 p-l-0">
                                                    <label class="font-14 font-semibold black-txt">City</label>
                                                    <input type="text" class="form-control input_field font-qregular " id="address1" aria-describedby="emailHelp" placeholder="" name="address1" value={userList.User.city}
                                                    />
                                                </div>
                                                <div class="col-lg-7 col-md-6 col-sm-12 m-b-10 p-l-18">
                                                    <label class="font-14 font-semibold black-txt">Country or State</label>
                                                    <input type="text" class="form-control input_field font-qregular" id="address2" aria-describedby="emailHelp" placeholder="" name="address2" value={userList.User.countryState}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            Postcode or Zipcode
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input_field font-qregular input-control"
                                            id="postcode"
                                            aria-describedby="emailHelp"
                                            placeholder=""
                                            name="postcode"
                                            value={userList.User.postcode}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            Emergency contact name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input_field font-qregular input-control"
                                            id="name"
                                            aria-describedby="name"
                                            placeholder=""
                                            name="name"
                                            value={emergencyContact.name}


                                        />

                                    </div>
                                    <div className="form-group phone_inputs_field">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            Emergency contact number
                                        </label>
                                        <PhoneInput
                                            country={emergencyContact.phoneNumber}
                                            value={emergencyContact.phoneNumber ? emergencyContact.phoneNumber.toString() : emergencyContact.phoneNumber}
                                            inputClass="w-100 input_field font-qregular"

                                        />

                                    </div>

                                    <div
                                        className="form-group">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >
                                            GP or primary care doctor name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input_field font-qregular input-control"
                                            id="name"
                                            aria-describedby=""
                                            placeholder=""
                                            name="name"
                                            value={primaryCare.name}


                                        />

                                    </div>

                                    <div
                                        className="form-group phone_inputs_field">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-14 font-semibold black-txt"
                                        >GP or primary care contact number
                                        </label>

                                        <PhoneInput
                                            country={primaryCare.phoneNumber}
                                            value={primaryCare.phoneNumber ? primaryCare.phoneNumber.toString() : primaryCare.phoneNumber}
                                            inputClass="w-100 input_field font-qregular"

                                        />
                                    </div>
                                </div>
                                <div className="buttons-read w-100 float-left text-center pointer">
                                    <a href="javascript:void(0)" onClick={() => this.getBack()} className="btn btn-beam-blue-inverse font-14 m-r-20 pointer col-md-6 m-t-20">Back to class list</a>
                                    <a className="btn btn-beam-blue font-14 col-md-5 m-t-20" onClick={this.props.closeModel}>Close</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
    };
};
const mapDispatchToProps = {
    
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveClassAttendeesModal);
