import axios from "axios";
import { BACKEND_URL } from "../constant";
import Cookies from "universal-cookie";

axios.interceptors.request.use(
  (config) => {
    const jsonUser = JSON.parse(localStorage.getItem("user"));
    const token = jsonUser?.token;

    if (token) {
      // Add Authorization header if token exists
      config.headers["Authorization"] = token;
    }

    console.log("Axios Request:", config);
    return config;
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(
  (response) => {
    console.log("Axios Response:", response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config; // Keep track of the original request

    // Handle 401 error
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      console.log("Axios Unauthorized - 401, attempting token refresh.");

      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        try {
          // Make sure we don't retry this request more than once
          originalRequest._retry = true;

          // Attempt to refresh token using the /reauth endpoint
          const reauthResponse = await axios.post(`${BACKEND_URL}/api/reauth`, {
            refreshToken: refreshToken,
          });

          const reauthData = reauthResponse.data;
          const jsonUser = JSON.parse(localStorage.getItem("user")) || {};

          // Store the new tokens
          jsonUser.token = reauthData?.accessToken;
          localStorage.setItem("user", JSON.stringify(jsonUser));
          localStorage.setItem("refreshToken", reauthData.refreshToken);

          console.log(
            "Token refreshed successfully. Retrying the original request."
          );

          // Set the new Authorization header
          originalRequest.headers["Authorization"] = reauthData.accessToken;

          // Retry the original request with the new token
          return axios(originalRequest);
        } catch (reauthError) {
          console.error("Token refresh failed:", reauthError);

          // If reauth fails, clear localStorage and log out user
          // localStorage.clear();
          return Promise.reject({
            response: {
              status: 401,
              message: "Unauthorized",
            },
          });
        }
      } else {
        console.warn("No refresh token available, logging out.");
        // localStorage.clear();
        return Promise.reject({
          response: {
            status: 401,
            message: "Unauthorized",
          },
        });
      }
    }

    return Promise.reject({
      response: {
        status: 500,
      },
    });
  }
);

// Fetch Override
const originalFetch = window.fetch;

window.fetch = async (input, init) => {
  console.log("Fetch Request:", input, init);
  let requestUrl;
  // if (init && init.headers) {
  //   init.headers['Authorization'] = `Bearer YOUR_TOKEN`;
  // }
  // if (input instanceof Request) {
  //   requestUrl = input.url; // Extract URL from Request object
  // } else {
  //   requestUrl = input; // Assume it's a URL string
  // }

  console.log("Fetch Request URL:", requestUrl);
  try {
    const response = await originalFetch(input, init);

    const endpoint = response.url.split("/").pop(); // Get the last part of the URL
    console.log("here is  Request URL:", endpoint);

    console.log(response);
    const jsonUser = JSON.parse(localStorage.getItem("user"));
    const cookie = new Cookies();
    localStorage.getItem("refreshToken");
    if (
      response.status === 401 &&
      endpoint !== "reauth" &&
      localStorage.getItem("refreshToken")
    ) {
      console.log(
        "401 error for non-reauth route. Attempting to refresh token."
      );

      // Make a request to the /reauth endpoint to get a new refresh token

      const reauthResponse = await originalFetch(`${BACKEND_URL}/api/reauth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });

      // If reauth succeeds, store the new tokens
      if (reauthResponse.ok) {
        const reauthData = await reauthResponse.json();
        const jsonUser = JSON.parse(localStorage.getItem("user"));
        console.log(jsonUser);
        jsonUser.token = reauthData?.accessToken;
        localStorage.setItem(
          "user",
          JSON.stringify(jsonUser ?? { token: reauthData.accessToken })
        );
        // localStorage.setItem("refreshToken", reauthData.refreshToken);

        console.log(
          "Token refreshed successfully. Retrying the original request."
        );

        // Retry the original request with the new token
        init.headers = {
          ...init.headers,
          Authorization: `${reauthData.accessToken}`,
        };

        return originalFetch(input, init);
      } else {
        // If reauth fails, log out the user or clear tokens
        // localStorage.clear();
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
        console.log("Reauth failed. Tokens cleared.");
      }
    }

    return response;
  } catch (error) {
    // localStorage.clear();
    console.error("Fetch Error:", error);
    throw error;
  }
};
