import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass } from "../../actions";
import { commonService } from "../../_services";
import AddToCalendar from 'react-add-to-calendar';

class EventCalendarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            event: {
                title: '',
                description: 'Friendly reminder: you scheduled some feel-good \'you\' time on Kidney Beam. Are you ready to get moving? You can do it! ',
                location: '',
                startTime: '',
                endTime: ''
            }
        };

    }
    componentDidMount() {
        console.log('calendar-props', this.props);
        var item = this.props.item;
        const temp = this.state.event;
        var titleText = (this.props.type!="Live") ? "Kidney Beam Class: ":"Live Kidney Beam Class: ";
        temp.title = titleText + this.props.title;
        temp.description = this.state.event.description + window.location;
        //temp.location = this.props.logged_userData.membershipData.country.countryName;
        temp.startTime = moment(new Date(item.scheduledFor));
        temp.endTime = moment(commonService.addMintues(item.scheduledFor, this.props.length));
        this.setState({
            event: temp
        });
    }

    /**
        * render 
        */
    render() {
        let items = [
            { apple: 'iCal' },
            { google: 'Google' },
            { yahoo: 'Yahoo' },
            { outlook: 'Outlook' }
        ];
        return (
            <div>
                <AddToCalendar event={this.state.event} listItems={items} />
            </div>
        );

    }

}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
    };
};

const mapDispatchToProps = {
    fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventCalendarComponent);
