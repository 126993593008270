import React from "react";
import { connect } from "react-redux";
import {
    cautionModalOpen
} from "../../actions";
import * as constand from "../../constant";
import _ from 'lodash';
import Modal from "react-responsive-modal";

class CautionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
componentDidMount(){
    console.log('Didmount===',this.props)
}
    closeModel = () => {
        this.props.cautionModalOpen(false);
    }
    /**
     * To render caution popup
     * @returns 
     */
    render() {
        console.log('Cautionmodal==', this.props.is_caution_modal_open)
        return (
            <React.Fragment>
                <Modal
                    classNames={{
                        modal: "m-t-50"
                    }}
                    open={this.props.is_caution_modal_open}
                    onClose={() => this.closeModel()}
                    center
                >
                    <div className="modal-dialog modal-width--custom" role="document">
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0 bg-dark-orange">
                                <h5
                                    className="modal-title text-center col-md-11 p-0 font-semibold white-txt"
                                    id="exampleModalLabel font-medium"
                                >
                                    {this.props.title}
                                </h5>
                                <button
                                    type="button"
                                    className="close white-txt"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={() => this.closeModel()}>
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body body-padding--value pb-0" id="register-box">
                                <p className="font-book font-16 text-center" dangerouslySetInnerHTML={{ __html: (this.props.content) }}></p>
                                {this.props.hasButton &&
                                    <div id="register-link" className=" w-100 m-t-30 m-b-20 float-left">
                                        <span
                                            className="bluebtn float-left w-100 text-center pointer"
                                        >
                                            {'Close'}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        is_caution_modal_open: state.header.is_caution_modal_open,
    };
};

const mapDispatchToProps = {
    cautionModalOpen
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CautionModal);