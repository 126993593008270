import React from "react";
import { useLocation,Link,useParams } from "react-router-dom";
// import './dynamicDashboardHeader.scss'

const DynamicDashboardHeader = () => {
  const location = useLocation();

  const { condition, programId } = useParams();
  const getNavLinkClass = (path) => {
    return location.pathname.includes(path) ? "nav-item active" : "nav-item";
  };

  const generateto = (base) => {
    return `/programmes/${base}/${condition}/${programId}`;
  };

  return (
    <React.Fragment>
      {/*secondary header*/}
      <header className="header header-custom">
        <nav className="navbar navbar-expand-lg navbar-light bg-white secondary-header">
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className={getNavLinkClass("/dashboard")}>
                <Link className="nav-link" to={generateto("dashboard")}>
                  Dashboard <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className={getNavLinkClass("/goals")}>
                <Link className="nav-link" to={generateto("goals")}>
                  Goals
                </Link>
              </li>
              <li className={getNavLinkClass("/resources")}>
                <Link className="nav-link" to={generateto("resources")}>
                  Resources
                </Link>
              </li>
              <li className={getNavLinkClass("/messages")}>
                <Link className="nav-link" to={generateto("messages")}>
                  Messages
                </Link>
              </li>
              <li className={getNavLinkClass("/faq")}>
                <Link className="nav-link" to={generateto("faq")}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default DynamicDashboardHeader;
