import React from 'react';
import { connect } from "react-redux";
import {
    fetchGroupOndemand,
    start_loader,
    stop_loader,
    clearGroupOndemandList,
    pushHistory,
    clearOndemandDetail,
    ondemandDetail
} from "../../actions";
import { Link } from 'react-router-dom';
import * as constand from "../../constant";
import { commonService } from "../../_services";
import { ImageTag, TextWrap } from "../../tags";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import WorkoutSaveComponent from "../WorkoutsPage/workoutSaveComponent";
import WorkoutCMSComponent from "../WorkoutsPage/WorkoutCMSComponent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import FilterComponent from '../Common/FilterComponent';
import SortByComponent from '../Common/SortByComponent';
import OndemandSearchComponent from '../Common/OndemandSearchComponent';

class GroupWorkoutSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            offset: 0,
            pageCounter: 1,
            count: 0,
            limit: constand.CONSTSIX,
            workoutList: [],
            group: this.props.params.group,
            condition: this.props.params.condition,
            showAll: 0,

            sort_filter: "Duration",//"Newest first",
            search_data: "",
            lenthFilter_max: constand.VIDEO_RANGE_MAX,
            is_filter_open: false,
            available_filter: {},
            selected_filter: {},
            filterNames: [
                "condition",
                "lengthFilter",
                "videoType",
                "level",
                "benefit",
                "discipline",
                "equipment",
                "instructor"
            ],
            is_filter_clicked: false
        };
        this.fetchGroupOndemand = this.fetchGroupOndemand.bind(this);
        this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);

    }
    /** fetch live class list on page did mount */
    componentDidMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        if (this.props.history.location.pathname && (!this.props.groupState.isMember && (!authData || (authData && !authData.isCreator && !authData.isStudyLeader))) && (!this.props.groupState.isMember && !this.props.groupState.canEdit)) {
            const { from } = { from: { pathname: "/group/about/" + this.props.groupState.group + "/" + this.props.groupState.condition } };
            this.props.history.push(from);
        } else {
            this.props.clearGroupOndemandList()
            //this.fetchGroupOndemand();
        }
    }
    /**
     * call after sign in
     */
    componentWillReceiveProps(nextProps) {
        if (!this.props.is_auth && nextProps.is_auth) {
            //this.fetchGroupOndemand();
        }
    }
    componentWillMount() {
        //this._isMounted = true;
        this.props.clearGroupOndemandList();
        this.onHeaderUrlChange();
        // this.getOnDemandList();
    }
    /**
   * loadMoreVideos
   */
    loadMoreVideos(isNext) {
        console.log('offset****' + this.state.offset + 'Limit***' + this.state.limit)
        var offset = isNext
            ? this.state.offset + this.state.limit
            : this.state.offset - this.state.limit;

        if (isNext)
            this.setState({ pageCounter: this.state.pageCounter + 1 })
        else
            this.setState({ pageCounter: this.state.pageCounter - 1 })

        this.setState(
            {
                offset: offset
            },
            () => {
                this.fetchGroupOndemand();
            }
        );
    }
    incrementShowmoreCnt(type) {

        if (type == 'all') {
            var lastpageCount = this.state.count;
            this.props.clearGroupOndemandList()
            //var offsetCount = this.state.offset + constand.ONDEMAND_LIST_CNT;
            //this.props.updateOffset(lastpageCount)
            this.setState(
                {
                    Loading: true,
                    //limit: 0,
                    offset: lastpageCount,
                    showAll: lastpageCount,
                    //this.state.offset + constand.ONDEMAND_LIST_CNT
                },
                function () {
                    this.fetchGroupOndemand(true);
                }
            );
        } else {
            var offsetCount = this.state.offset + constand.CONSTSIX;
            // this.props.updateOffset(offsetCount)
            this.setState(
                {
                    Loading: true,
                    offset: offsetCount,
                    showAll: 0 //this.state.offset + constand.ONDEMAND_LIST_CNT
                },
                function () {
                    this.fetchGroupOndemand();
                }
            );
        }
    }
    /**
     * fetch class list data
     */
    fetchGroupOndemand(isFull = false) {
        console.log('this.state.offset', this.state.offset)
        console.log('Round***', this.state.pageCounter)
        //this.props.clearGroupOndemandList();
        this.setState({ Loading: true })
        this.props.start_loader();
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset, //this.props.isGoback ? this.props.offsetFull : this.state.offset,
            "showAll": isFull ? this.state.showAll : 0,
            "limit": this.state.limit, //constand.ONDEMAND_LIST_CNT
            "condition": condition,
            "group": this.state.group,
            sortby: this.state.sort_filter,
            keywords: this.state.search_data,
            filters: this.checkFilterEmpty(this.state.selected_filter)
        };

        this.props.fetchGroupOndemand(dataObj).then(
            response => {
                this.setState({ Loading: false })

                this.props.stop_loader();
                if (response) {
                    this.setState({
                        disableButton: false,
                        count: response.count,
                        available_filter: response.availableFilters,
                        workoutList: this.props.group_ondemand_list
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false,
                    disableButton: false
                });
                // toast.error(error);
                this.props.stop_loader();
            }
        );
    }

    //set current position for focusing again back from details page
    handlePosition(e) {
        this.props.setGoback(false);
        console.log('current postiion', e.target.getBoundingClientRect())
        this.props.setCurrentPosition(e.target.getBoundingClientRect().top)
    }
    getOnDemandDetail(workoutId) {
        this.props.ondemandDetail(workoutId, this.state.condition).then(
            response => {
                this.props.ondemandCMSModelOpen({ open: true, type: 'edit' })
            }, error => {
                console.log('err', error)
            });
    }
    /**
   *
   * @param {*} item
   */
    renderActionComponent(item) {
        return (
            <div>
                <WorkoutScheduleComponent
                    from="group_ondemand"
                    ondemand_data={item}
                    location={this.props.location}
                    history={this.props.history}
                />
                <b className="m-l-10">
                    <WorkoutSaveComponent
                        className="m-l-10"
                        page="group_ondemand"
                        workoutData={item}
                    />
                </b>

            </div>
        );
    }
    /**
     * 
     * @returns 
     */
    sortByFunction = (item) => {
        this.props.clearGroupOndemandList()
        this.setState({ workoutList: [], all_levels: [], all_tags: [], sort_filter: item, offset: 0 }, function () {
            //this.getOnDemandList();
            this.onHeaderUrlChange(null, null, item);
        });
    }
    /**
     * 
     * @returns 
     */
    /**
     * on url change
     */
     onHeaderUrlChange = (isFilter = null, filterName = null, isSort = null, isLengthFilter = null) => {
        var existingSearchData = this.props.history.location.search;
        var existingSearch = this.props.history.location.search;
        var temp = {
            condition: [],
            lengthFilter: "",
            videoType: [],
            level: [],
            benefit: [],
            discipline: [],
            equipment: [],
            instructor: []
        };
        var sortbyOld = '';
        var tempOld = { ...this.state.selected_filter };
        let newUrl = "";
        if (existingSearch) {
            existingSearch = existingSearch.split("?");
            var searchQuery = existingSearch[1];
            searchQuery = searchQuery.split("&");
            if (searchQuery) {
                console.log('searchQuery', searchQuery)
                var searchNameArr = [];
                var array_data = [];

                searchQuery.map((item, key) => {
                    let searchData = item.split("=");
                    let searchName = searchData[0];
                    searchNameArr.push(searchName);
                    let searchParams = searchData[1];
                    let searchParamsSplit = searchParams.split("-").join(" ");
                    searchParamsSplit = searchParamsSplit.split(",");
                    if (searchName !== 'sortby' && (searchName !== 'len%3E' && searchName !== 'len%3C' && searchName !== 'len>' && searchName !== 'len<')) {
                        temp[searchName] = [];
                        temp[searchName] = searchParamsSplit;
                    }
                    if (filterName !== searchName && isFilter === "filter") {
                        let newParams =
                            tempOld && tempOld[searchName]
                                ? tempOld[searchName].join(",")
                                : "";
                        newParams = newParams.split(" ").join("-");
                        if (newParams) {
                            newUrl = newUrl
                                ? `${newUrl}&${searchName}=${newParams}`
                                : `${newUrl}?${searchName}=${newParams}`;
                        }
                    }
                    console.log('searchName', searchName)
                    if (searchName === 'sortby') {
                        if (isSort) {
                            console.log('searchName-isSort-', isSort)
                            let newParams = isSort.split(" ").join("-");
                            newUrl = newUrl
                                ? `${newUrl}&sortby=${newParams}`
                                : `${newUrl}?sortby=${newParams}`;
                        } else {
                            sortbyOld = searchParams.split("-").join(" ");
                            console.log('seachname-else-issort')
                            newUrl = newUrl
                                ? `${newUrl}&sortby=${searchParams}`
                                : `${newUrl}?sortby=${searchParams}`;
                        }
                    } else if (searchName == 'len%3E' || searchName == 'len%3C' || searchName == 'len>' || searchName == 'len<') {
                        console.log('seachname-length', searchParams)
                        var newValue = this.state.selected_filter['lengthFilter'] ? this.state.selected_filter['lengthFilter'].split(',') : [];
                        if (!isLengthFilter) {
                            console.log('newValue', newValue)
                            if (searchName === 'len%3E' || searchName === 'len>') {
                                newUrl = newUrl
                                    ? `${newUrl}&${searchName}=${newValue.length > 0 ? newValue[0] : searchParams}`
                                    : `${newUrl}?${searchName}=${newValue.length > 0 ? newValue[0] : searchParams}`;
                                array_data.push(newValue.length > 0 ? newValue[0] : searchParams);
                                searchName = 'len>';
                            }
                            if (searchName == 'len%3C' || searchName === 'len<') {
                                newUrl = newUrl
                                    ? `${newUrl}&${searchName}=${newValue.length > 0 ? newValue[1] : searchParams}`
                                    : `${newUrl}?${searchName}=${newValue.length > 0 ? newValue[1] : searchParams}`;
                                array_data.push(newValue.length > 0 ? newValue[1] : searchParams);
                                searchName = 'len<';
                            }
                            console.log('array_data', array_data)
                            if (filterName)
                                tempOld.lengthFilter = array_data.toString();
                            else
                                temp.lengthFilter = array_data.toString();

                            this.setState({ rangeSelectedValue: array_data.toString() })

                            console.log('newUrl***', newUrl)
                        }
                    }
                    else if (!isFilter) {
                        let newParams =
                            tempOld && tempOld[searchName]
                                ? tempOld[searchName].join(",")
                                : "";
                        newParams = newParams.split(" ").join("-");
                        if (newParams) {
                            newUrl = newUrl
                                ? `${newUrl}&${searchName}=${newParams}`
                                : `${newUrl}?${searchName}=${newParams}`;
                        }
                    }
                });
            }
        }

        if (filterName) {
            if (
                searchNameArr &&
                searchNameArr.indexOf(filterName) > -1 &&
                isFilter === "filter"
            ) {
                console.log('isfilter')
                let newParams =
                    tempOld && tempOld[filterName] ? tempOld[filterName].join(",") : "";
                newParams = newParams.split(" ").join("-");
                if (newParams) {
                    newUrl = newUrl
                        ? `${newUrl}&${filterName}=${newParams}`
                        : `${newUrl}?${filterName}=${newParams}`;
                }

            }
            else {
                console.log('searchNameArr', searchNameArr)
                if (filterName == 'lengthFilter') {
                    var newValue = this.state.selected_filter['lengthFilter'] ? this.state.selected_filter['lengthFilter'].split(',') : [];
                    console.log('newValue', newValue)

                    if (searchNameArr && newValue.length &&
                        (searchNameArr.indexOf('len>') == -1 && searchNameArr.indexOf('len<') == -1 && searchNameArr.indexOf('len%3E') == -1 && searchNameArr.indexOf('len%3C') == -1)) {
                        console.log('if-searchNameArr', newUrl)
                        newUrl = newUrl
                            ? `${newUrl}&len>=${newValue[0]}&len<=${newValue[1]}`
                            : `${newUrl}?len>=${newValue[0]}&len<=${newValue[1]}`;
                    } else if (!searchNameArr && newValue.length) {
                        console.log('else-searchNameArr', newUrl)
                        newUrl = newUrl
                            ? `${newUrl}&len>=${newValue[0]}&len<=${newValue[1]}`
                            : `${newUrl}?len>=${newValue[0]}&len<=${newValue[1]}`;
                    }
                } else if (filterName != 'lengthFilter') {
                    console.log('else isfilter')
                    let newParams =
                        tempOld && tempOld[filterName] ? tempOld[filterName].join(",") : "";
                    newParams = newParams.split(" ").join("-");
                    if (newParams) {
                        newUrl = newUrl
                            ? `${newUrl}&${filterName}=${newParams}`
                            : `${newUrl}?${filterName}=${newParams}`;
                    }
                }
            }
        } else if (isSort) {
            if (
                searchNameArr &&
                searchNameArr.indexOf('sortby') < 0 &&
                isSort
            ) {
                console.log('indexofissort', searchNameArr.indexOf('sortby'));
                console.log('if issort', isSort)
                //newUrl = existingSearchData;
                var newParams = isSort.split(" ").join("-");
                newUrl = newUrl
                    ? `${newUrl}&sortby=${newParams}`
                    : `${newUrl}?sortby=${newParams}`;
            } else if (!searchNameArr) {
                //console.log('else indexofissort', searchNameArr.indexOf('sortby'));

                var newParams = isSort.split(" ").join("-");
                newUrl = newUrl
                    ? `${newUrl}&sortby=${newParams}`
                    : `${newUrl}?sortby=${newParams}`;
            }
        } else {
            console.log('else islenghtfilter', existingSearchData)
            newUrl = existingSearchData;
        }
        var url = this.props.history.location.pathname + newUrl;
        this.props.history.push(url);
        console.log('sortbyOld', url)
        this.setState(
            { sort_filter: isSort ? isSort : (sortbyOld ? sortbyOld : 'Duration'), selected_filter: filterName ? tempOld : temp, offset: 0 },
            function () {
                this.fetchGroupOndemand();
            }
        );
    }
    /**
     * 
     * @param {*} e 
     */
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    /**
     * on enter search
     * @param {*} e
     */
    searchBarEnter = (e) => {
        if (e.key === "Enter") {
            this.searchFunction();
        }
    }
    /**
     * 
     * @returns 
     */
    searchFunction = () => {
        this.props.clearGroupOndemandList();
        this.setState({ sort_filter: "", offset: 0, workoutList: [], all_levels: [], all_tags: [], }, function () {
            this.fetchGroupOndemand();
        });
    }
    /**
     * To display selected item
     */
    renderSelectedFilter = () => {
        let value = [];
        this.state.filterNames.map((item, key) => {
            if (
                this.state.selected_filter &&
                this.state.selected_filter[item] &&
                this.state.selected_filter[item].length > 0
            ) {
                if (item !== "lengthFilter") {
                    this.state.selected_filter[item].map((val, index) =>
                        value.push(
                            <div
                                key={"filter_rm_" + index + key}
                                onClick={() => {
                                    this.removeFilterItem(item, val);
                                }}
                            >
                                <span className="pointer"> x </span>
                                <span className="capitalize_text pointer">{val}</span>
                            </div>
                        )
                    );
                } else {
                    let leng = this.state.selected_filter[item].split(",");
                    console.log('lengvalue', leng)
                    value.push(
                        <div
                            key={"filter_rm_" + key}
                            onClick={() => {
                                this.removeFilterItem('lengthFilter', '');
                                //this.removeLengthFilter(item, this.state.selected_filter[item]);
                            }}
                        >
                            <span className="pointer"> x </span>
                            <span className="capitalize_text pointer">
                                Class Length: {leng[0] + "-" + leng[1]} mins
              </span>
                        </div>
                    );
                }
            }
        });
        return value;
    }
    /**
     * to remove selected filter item
     * @param {*} item
     * @param {*} val
     */
    removeFilterItem = (item, val) => {
        this.filterFunction(item, val);
    }
    removeLengthFilter = (name) => {
        var temp = { ...this.state.selected_filter };
        temp[name] = "";
        this.setState({ selected_filter: temp, offset: 0 }, function () {
            this.fetchGroupOndemand();
        });
    }
    filterFunction = (name, value) => {

        this.props.clearGroupOndemandList();
        var temp = { ...this.state.selected_filter };
        if (!temp[name]) {
            temp = {
                condition: [],
                lengthFilter: "",
                videoType: [],
                level: [],
                benefit: [],
                discipline: [],
                equipment: [],
                instructor: []
            };
        }
        if (name != 'lengthFilter') {
            var index = temp[name].indexOf(value);
            if (index > -1) {
                temp[name].splice(index, 1);
            } else {
                temp[name].push(value);
            }
            this.setState({ workoutList: [], all_levels: [], all_tags: [], selected_filter: temp, offset: 0 }, function () {
                // this.getOnDemandList();
                this.onHeaderUrlChange("filter", name);
            });
        } else {
            temp[name] = '';
            this.setState({ workoutList: [], all_levels: [], all_tags: [], selected_filter: temp, offset: 0,rangeSelectedValue: 0 + ',' + 0 }, function () {
                // this.getOnDemandList();
                this.onHeaderUrlChange(null, name, null, true);
            });
        }

    }
    checkFilterEmpty = (dataObj) => {
        var return_obj = dataObj;
        if (Object.keys(dataObj).length > 0 && dataObj.constructor === Object) {
            if (
                dataObj.lengthFilter.length <= 0 &&
                dataObj.videoType.length <= 0 &&
                dataObj.equipment.length <= 0 &&
                dataObj.level.length <= 0 &&
                dataObj.benefit.length <= 0 &&
                dataObj.discipline.length <= 0 &&
                dataObj.instructor.length <= 0
            ) {
                return_obj = {};
                this.setState({ isInitFilter: true })
            }
        }
        return return_obj;
    }
    rangeChangeFunction = (value) => {
        if (value) {
            this.props.clearGroupOndemandList();
            var temp = { ...this.state.selected_filter };
            if (Object.keys(temp).length === 0 && temp.constructor === Object) {
                temp = {
                    condition: [],
                    lengthFilter: "",
                    videoType: [],
                    level: [],
                    benefit: [],
                    discipline: [],
                    equipment: [],
                    instructor: []
                };
            }
            var array_data = [value.min, value.max];
            temp.lengthFilter = array_data.toString();
            this.setState({ selected_filter: temp, workoutList: [], offset: 0 }, () => { this.onHeaderUrlChange('filter', 'lengthFilter'); });
        }
    }
    handleChangeRangeSelector = (value) => {
        this.setState({ rangeSelectedValue: value.min + ',' + value.max })
    }
    /**
   *
   * render video list
   */
    renderOndemandVideo() {
        return this.state.workoutList.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4 vidClass" key={index}>
                <div className="card h-100 ">
                    <Link
                        className="position-relative"
                        to={"/detail/" + item.id + "/" + (commonService.replaceChar(this.props.params.condition, false))}
                        onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                    >
                        <ImageTag ref={node => { this.wrapperRef = node; }}
                            className="card-img-top img-fluid"
                            src={
                                constand.WORKOUT_IMG_PATH +
                                item.id +
                                "-img.png"
                            }
                            thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
                        />
                        <span className="position-absolute video_time">
                            <span>{item.length} mins</span>
                        </span>
                    </Link>
                    <div className="card-body">
                        <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                            <div className="col-md-10 col-lg-10 col-sm-10 col-10 float-left pl-0">
                                <h3 className="black-txt font-16 font-semibold capitalize_text float-left w-90">
                                    {item.title}
                                </h3>
                            </div>
                            <div className="bookmark-left float-right">
                                {this.renderActionComponent(item)}
                            </div>
                        </div>
                        <div className="w-100 float-left">
                            <div className="col-12 float-left m-b-10 p-0">
                                <div className="left-image leftwidth-set p-0 border-0 float-left">
                                    <img
                                        className="img-fluid rounded-circle"
                                        src={
                                            item.Instructor &&
                                                item.Instructor.img
                                                ? constand.USER_IMAGE_PATH +
                                                item.Instructor.img
                                                : constand.WEB_IMAGES + "no-image.png"
                                        }
                                    />
                                </div>
                                <p className="float-left font-16 black-txt font-qregular p-l-10 p-t-15">
                                    {commonService.bindUsername(item.Instructor.User)}
                                </p>
                            </div>
                            <div className="col-11 float-left p-0">
                                <div className="font-medium w-100">
                                    <div className="col-md-12 col-sm-12 float-left p-0">
                                        <span className="w-40  font-16 black-txt font-qbold float-left">
                                            Discipline:
                                  </span>
                                        <span className=" orangefont w-60 float-left font-16 font-qregular p-l-5 capitalize_text">
                                            {(item.workoutTags) ? commonService.returnTag(
                                                "discipline", item.workoutTags, item.id
                                            ) : 'None'}
                                        </span>
                                    </div>
                                    <div className="col-md-12 col-sm-12 float-left p-0">
                                        <span className="w-40  font-16 black-txt font-qbold float-left">
                                            Level:
                                  </span>

                                        <span className="font-16 font-qregular capitalize_text orangefont w-60 float-left p-l-5 capitalize_text">
                                            {" "}
                                            {(item.workoutTags) ? commonService.returnTag(
                                                "level", item.workoutTags, item.id
                                            ) : 'None'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {this.props.is_auth && this.props.is_create_mode &&
                                <div className="col-1 float-left top-20 p-0 pl-2 pointer" onClick={() => { this.getOnDemandDetail(item.id) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} /></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
    render() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        return (
            <div className="videoclasses">
                <div className="col-sm-12 four_grid pr-0">
                    <div className="row">
                        <div className="container-fluid mx-auto w-95 p-0 condemand">
                            <div className="w-100 m-b-10 float-left">
                                <div className="filter-section col-md-12 m-t-10 m-b-10 d-none d-sm-none d-md-block d-lg-block p-0">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-2">
                                            <SortByComponent sort_filter={this.state.sort_filter} sortByFunction={this.sortByFunction} />
                                        </div>
                                        <div className="col-md-8 col-sm-8">
                                            <OndemandSearchComponent search_data={this.state.search_data}
                                                searchBarEnter={this.searchBarEnter}
                                                handleChange={this.handleChange}
                                                searchFunction={this.searchFunction}
                                                loader={this.state.loader}
                                                isMobileView={false}
                                                pageFrom="group"
                                                 />
                                        </div>
                                        <div className="col-md-2 col-sm-2 text-right">
                                            {!this.state.is_filter_open && !this.state.loader && this.state.workoutList.length > 0 && (
                                                <button type="button"
                                                    className="btn btn-purple-inverse button-filter close-btn  font-medium font-14 pointer"
                                                    onClick={() => this.setState({ is_filter_open: true })}
                                                >
                                                    Filter
                                                </button>
                                            )}
                                            {this.state.is_filter_open && (
                                                <span
                                                    className="btn btn-purple close-btn  font-medium font-14 pointer"
                                                    onClick={() => this.setState({ is_filter_open: false })}
                                                >
                                                    Close
                                                </span>
                                            )}
                                        </div>
                                        {this.state.selected_filter &&
                                            this.state.filterNames &&
                                            !this.state.is_filter_open && (
                                                <div className="font-14 orangefont filter-select w-100 m-t-20 m-b-20 font-book text-center">
                                                    {this.renderSelectedFilter()}
                                                </div>
                                            )}

                                    </div>
                                </div>

                                <FilterComponent
                                    is_filter_open={this.state.is_filter_open}
                                    is_filter_clicked={this.state.is_filter_clicked}
                                    rangeSelectedValue={this.state.rangeSelectedValue}
                                    rangeChangeFunction={this.rangeChangeFunction}
                                    handleChangeRangeSelector={this.handleChangeRangeSelector}
                                    available_filter={this.state.available_filter}
                                    selected_filter={this.state.selected_filter}
                                    filterFunction={this.filterFunction}
                                />

                                <div className="col-md-12 col-lg-12 col-sm-12 p-0 ">
                                    <div className="row">
                                        {this.props.is_auth && this.props.is_create_mode &&
                                            <div
                                                className="col-lg-4 col-md-6 col-sm-12 mb-4"
                                            >
                                                <div className="card h-100" >
                                                    <div onClick={((e) => this.handlePosition(e))}>
                                                        <div
                                                            className="position-relative"
                                                        >
                                                            <ImageTag ref={node => { this.wrapperRef = node; }}
                                                                className="card-img-top img-fluid"
                                                                src={
                                                                    constand.WEB_IMAGES + "ondemand-placeholder.png"
                                                                }
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="w-100 float-left m-b-10">
                                                            <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                                                <h3 className="black-txt font-16 font-semibold capitalize_text float-left w-75">
                                                                    <TextWrap text='New On Demand Class' />
                                                                </h3>
                                                                <div className="bookmark-left float-right text-right w-25">
                                                                </div>
                                                                <div className="bookmark-left m-l-10 float-left"></div>
                                                            </div>
                                                        </div>
                                                        <div className="w-100 float-left">
                                                            <div className="col-12 float-left m-b-10 p-0 font-medium">
                                                                <p className="float-left font-16 black-txt font-qregular">
                                                                    Click the plus sign to create a new On Demand Class!
			</p>
                                                                {!authData.isStudyLeader &&
                                                                    <p className="float-left font-16 black-txt font-qregular">
                                                                        You will need...
			</p>
                                                                }
                                                            </div>
                                                            {!authData.isStudyLeader &&

                                                                <div className="col-11 float-left p-0">
                                                                    <div className="font-medium w-100">
                                                                        <ul className="col-md-12 col-sm-12 float-left black-txt">
                                                                            <li><span className="w-40 font-16 font-qregular float-left">
                                                                                Link to ondemand video
			</span></li>
                                                                            <li><span className="w-40 font-16 font-qregular float-left">
                                                                                Class thumbnail image
			</span></li>
                                                                        </ul>

                                                                    </div>
                                                                </div>
                                                            }
                                                            {this.props.is_auth && this.props.is_create_mode &&
                                                                <div className={"col-1 float-left p-0 pl-2 pointer " + (authData.isStudyLeader ? 'offset-11' : 'top-20')} onClick={() => { this.props.ondemandCMSModelOpen({ open: true, type: 'add' }); this.props.clearOndemandDetail() }}>
                                                                    <img src={constand.WEB_IMAGES + "add-plus.png"} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.workoutList &&
                                            this.state.workoutList.length > 0 && (
                                                this.renderOndemandVideo()
                                            )}
                                    </div>
                                </div>

                            </div>
                            {this.state.workoutList.length > 0 && this.state.workoutList.length < this.state.count && (
                                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                                    <button
                                        disabled={
                                            this.state.loader
                                        }
                                        onClick={() => { this.incrementShowmoreCnt('') }}
                                        className="btn btn-orange m-t-40 m-b-40 font-book"
                                    >
                                        Show more on-demand videos
</button>
                                </div>
                            )}
                            {((this.state.workoutList.length > 0) && (this.state.workoutList.length < this.state.count)) && (
                                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                                    <button
                                        disabled={
                                            this.state.Loading
                                        }
                                        onClick={() => { this.incrementShowmoreCnt('all') }}
                                        className="btn btn-purple m-b-40 font-book p-r-30 p-l-30"
                                    >
                                        Show all
</button>
                                </div>
                            )}
                            {(!this.state.Loading && (this.state.workoutList).length === 0) && (
                                <div className="row">
                                <h3 className="col-md-12 col-lg-12 col-sm-12 p-1 text-center demand-msg">
                                    No on-demand videos
                                </h3>
                                </div>
                            )}
                            <div className="text-center w-100">
                                {(this.state.Loading) && (<AnimateLoaderComponent />)}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.is_cms_model &&
                    <WorkoutCMSComponent getList={() => this.sortByFunction("Newest first")} groupId={this.props.groupState.aboutGroup.id} group={this.props.groupState} />
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        group_ondemand_list: state.workout.group_ondemand_list,
        user_data: state.header.logged_userData,
        isGoback: state.workout.isGoback,
        is_create_mode: state.header.is_create_mode,
        is_cms_model: state.workout.is_cms_model,
        is_group_mode: state.header.is_group_mode,
    };
};

const mapDispatchToProps = {
    fetchGroupOndemand,
    start_loader,
    stop_loader,
    clearGroupOndemandList,
    pushHistory,
    clearOndemandDetail,
    ondemandDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupWorkoutSchedule);
