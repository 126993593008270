import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { start_loader, stop_loader, setStep, getTagsBasedOnType, setConditionIndex, setPageTitle, updateUserConditions, checkConditionHasTagCode, onboardingUserTrack } from "../../actions";
import * as constand from "../../constant";
import { commonService } from "../../_services";

class KidneyConditionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            current_index: 0,
            loader: false,
            kidney_care_provider: [],
            kd_other_care_provider: '',
            is_kd_other_care_provider: false,
            is_provider_selected: false,
            is_care_provider_textbox_err: false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.selected_condition_list = this.props.registerFormvalues.health_condition[
            this.props.condition_index
        ];
    }
    componentDidMount() {
        this.props.getTagsBasedOnType('kidney-care-provider').then(response => {
            console.log('response', response)
            if (response.success) {
                this.setState({ kidney_care_provider: response.data.tags })
                this.defaultTextboxOpenCheck(this.state.kidney_care_provider)
            }
        })
        this.props.setPageTitle('Who provides the care for your or the person you care for\'s kidney condition?');

    }
    handleCheckboxChange(e) {
        let item = e.target.value;
        let temp = [];
        if (this.props.registerFormvalues.kidney_care_provider.length > 0) {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            let index = parseInt(this.props.registerFormvalues.kidney_care_provider.findIndex(x => x === item));
            if (index > -1) {
                temp.splice(index, 1);
            } else {
                temp.push(item);
            }
        } else {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            temp.push(item);
        }
        console.log('temp', temp)
        let filteredTag = commonService.kdProviderTagName(this.state.kidney_care_provider, temp, 'Other');
        if (temp && temp.length > 0 && filteredTag && filteredTag.tag == 'Other') {
            this.setState({ is_kd_other_care_provider: true })
        } else {
            this.setState({ is_kd_other_care_provider: false })
        }
        if (temp && temp.length > 0) {
            this.setState({ is_provider_selected: false })
        }
        this.props.registerFormvalues.kidney_care_provider = temp;
    }
    handleChangeOtherClinic = (e) => {
        this.setState({ kd_other_care_provider: e.target.value })
        if (this.state.kd_other_care_provider && this.state.kd_other_care_provider.length > 0) {
            this.setState({ is_care_provider_textbox_err: false })
        }
    }
    /**
    submit  selection
    **/
    submitData() {
        this.props.registerFormvalues.kd_other_care_provider = this.state.kd_other_care_provider
        console.log('this.props.condition_index', this.props.condition_index)
        this.setState({ submitted: true });
        console.log('kidney_care_provider', this.props.registerFormvalues.kidney_care_provider);
        let pc_cond = commonService.checkRegisterSpecificCondition(this.props.registerFormvalues.health_condition, constand.CANCER_CONDITION);
        console.log('this.props.registerFormvalues.country == constand.ukCountry', this.props.registerFormvalues.country == constand.ukCountry)
        console.log('pc_cond', pc_cond)

        if (!this.props.registerFormvalues.kidney_care_provider.length > 0) {
            this.setState({ is_provider_selected: true })
            return
        }
        if (this.state.is_kd_other_care_provider && !this.props.registerFormvalues.kd_other_care_provider.length > 0) {
            this.setState({ is_care_provider_textbox_err: true })
            return
        }
        this.props.checkConditionHasTagCode(this.selected_condition_list.id).then(() => {

            if (this.props.pageFrom == 'mycondition') {
                //myconditions
                if (this.props.isUpdateMyConditions) {
                    this.props.updateUserTags()
                }
                else
                    this.props.pageFrom == 'register' ? this.onboardingUserTrack(29, 'forward') : this.props.setStep(29, 'forward');
            }
            else {
                //onboarding
                /* if (this.props.registerFormvalues.country == constand.ukCountry && (pc_cond.length > 0) && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.CANCER_CONDITION) {
                    console.log('IFFFFFFFFFFFFFFFFFFFFFF')
                    this.props.setStep(20, 'forward')
                } else { */
                if (this.props.isConditionHasCode)
                    this.props.pageFrom == 'register' ? this.onboardingUserTrack(29, 'forward') : this.props.setStep(29, 'forward');
                else if (this.props.condition_index <
                    this.props.registerFormvalues.health_condition.length - 1) {
                    this.props.setConditionIndex(this.props.condition_index + 1)
                    this.props.pageFrom == 'register' ? this.onboardingUserTrack(11, 'forward') : this.props.setStep(11, 'forward');//profession
                } else
                    this.props.pageFrom == 'register' ? this.onboardingUserTrack(23, 'forward') : this.props.setStep(23, 'forward'); // straight away go for membership options
            }
        })

    }

    defaultTextboxOpenCheck = (item) => {
        let temp = [];
        if (this.props.registerFormvalues.kidney_care_provider.length > 0) {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            let index = this.props.registerFormvalues.kidney_care_provider.findIndex(x => x === item);
            if (index > -1) {
                temp.splice(index, 1);
            } else {
                temp.push(item);
            }
        } else {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            temp.push(item);
        }
        let filteredTag = commonService.kdProviderTagName(this.state.kidney_care_provider, temp, 'Other');
        if (temp && temp.length > 0 && filteredTag && filteredTag.tag == 'Other') {
            this.setState({ is_kd_other_care_provider: true })
        } else {
            this.setState({ is_kd_other_care_provider: false })
        }

    }

    onboardingUserTrack = (step, flow = false) => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            let direction = flow ? flow : '';
            this.props.setStep(step, direction)
        })
    }

    render() {
        let count = constand.kc_provider_id;
        return (

            <div className="step17">
                {this.props.pageFrom != 'mycondition' &&
                    <h3 className="text-center ">
                        <span
                            className="pull-left pointer"
                            onClick={() => this.props.setStep(18, 'backward')}

                        >
                            <img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} />
                        </span>{this.props.registerFormvalues.firstname ? this.props.registerFormvalues.firstname + ': ' : ''}
                        Who provides the care for your or the person you care for's kidney condition?<Link
                            to="/home"
                            className="close-register orangefont"
                        >
                            X
                        </Link>
                    </h3>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-6 m-t-30">
                        <button className={"capitalize_text con-btn position-relative float-left font-semibold font-15 m-b-10 " + commonService.getConditionColorClass(this.selected_condition_list.tag) //commonService.getColor(this.selected_condition_list.tag.replace(' ', '-').toLowerCase())
                        } >{this.selected_condition_list.tag}
                        </button>
                        {this.state.kidney_care_provider && this.state.kidney_care_provider.map((item, key) => {
                            return (
                                <div key={key} className="toggle-btns float-left w-100 m-b-10">
                                    <span className="font-14 font-qregular black-txt">{item.tag}</span>
                                    <label className="switch m-l-10 pull-right" htmlFor={"checkbox-" + (item.id)}>
                                        <input type="checkbox" name="comorbidities" id={"checkbox-" + (item.id)} defaultChecked={parseInt(this.props.registerFormvalues.kidney_care_provider) === (item.id)}
                                            onChange={this.handleCheckboxChange}
                                            value={item.id} />
                                        <div className="slider round"></div>
                                    </label>
                                    {count++ ? "" : ""}
                                </div>
                            )
                        })}
                        {this.state.is_provider_selected &&
                            <p className="text-danger">Providers is required</p>}
                        {this.state.is_kd_other_care_provider &&
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="kd_other_care_provider"
                                    id="kd_other_care_provider"
                                    placeholder="Enter care provider"
                                    className="form-control input-control"
                                    value={this.state.kd_other_care_provider || ''}
                                    onChange={this.handleChangeOtherClinic}
                                />
                                {this.state.is_care_provider_textbox_err &&
                                    <p className="text-danger">please enter your care provider</p>
                                }
                            </div>
                        }
                        <div id="register-link" className=" w-100 m-t-50 m-b-20 float-left">
                            <span
                                onClick={() => this.submitData()}
                                className="bluebtn float-left w-100 font-medium font-14 text-center pointer"
                            >
                                {this.props.isUpdateMyConditions ? "Save Changes" : "Next"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        condition_index: state.register.condition_index,
        isConditionHasCode: state.register.isConditionHasCode,
    };
};

const mapDispatchToProps = { start_loader, stop_loader, setStep, getTagsBasedOnType, setConditionIndex, setPageTitle, updateUserConditions, checkConditionHasTagCode, onboardingUserTrack };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KidneyConditionComponent);
