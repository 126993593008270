export const convertToQueryString = (queryParams) => {
    const searchParams = new URLSearchParams();
    for(let key in queryParams) {
        searchParams.append(key, queryParams[key]);
    }

    return searchParams.toString();
}

export const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };