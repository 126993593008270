import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Errors from "../../Errors";
import { getPromocodePaln, start_loader, stop_loader, registerNewUser, setStep, getConditionBasedInstructor, getPlanByCountry, setConditionIndex, validateTagCodes, setPageTitle, onboardingUserTrack, checkConditionHasTagCode } from '../../actions';
import { toast } from "react-toastify";
import * as constand from "../../constant";
import ReactGA from 'react-ga';

class KidneyYouthPilotComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            know_via: this.props.registerFormvalues.know_via,
            tagKDYCode: '',
            submitted: false,
            promoCodeErr: false,
            subscribedErr: false,
            showPromo: false,
            errorMessage: '',
            KR_Cond: [],
            instructors: []
        }
        this.selected_condition_list = this.props.registerFormvalues.health_condition[
            this.props.condition_index
        ];
    }

    componentDidMount() {
        this.props.getPlanByCountry(this.props.registerFormvalues.country);
        //this.props.setConditionIndex(0);

        //finding kidney disease tag    
        var KR_Cond_temp = [];
        KR_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
            return (item.tag == constand.KR_CONDITION)
        })
        this.setState({ KR_Cond: KR_Cond_temp })
        //finding cystic fibrosis tag
        var CF_Cond_temp = [];
        CF_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
            return (item.tag == constand.KR_CONDITION)
        })
        if (CF_Cond_temp.length > 0 && this.props.registerFormvalues.beamchallenging)
            this.props.registerFormvalues.beamchallenging = this.props.registerFormvalues.beamchallenging;
        else
            this.props.registerFormvalues.beamchallenging = '';


        if (this.props.registerFormvalues.country == constand.usCountry &&
            this.props.registerFormvalues.profession == constand.livingCondition &&
            (this.props.registerFormvalues.health_condition.length > 0 && this.props.registerFormvalues.health_condition[0].tag == constand.CONDITION) &&
            this.props.registerFormvalues.age >= constand.adultAge) {
            this.props.registerFormvalues.usUser = true;
            //this.setState({ showPromo: false })
        }
        if (KR_Cond_temp.length > 0 && this.props.registerFormvalues.health_condition.length == 1) {
            this.props.registerFormvalues.krUser = true;
            this.setState({ showPromo: true })
        }
        this.props.setPageTitle('Are you a participant in our Kidney Beam Kids Pilot?')
    }

    handleChange = (e) => {  //for twoway binding
        const { name, value } = e.target;
        this.setState({ tagKDYCode: value, subscribedErr: false });
        this.props.registerFormvalues[name] = value;

        if (constand.KD_BEAM_RESEARCH == value) {
            this.props.registerFormvalues.isKBResearcheUser = true; //kidney beam research user
        }
    }

    tagCodeSubmit = () => {
        var tagKDYCode = this.props.registerFormvalues.tagKDYCode;
        var KDY_Cond_temp = [];
        KDY_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
            return (item.tag == constand.KD_YOUTH)
        })
        this.setState({ submitted: true })
        var conditionId = KDY_Cond_temp[0].id;
        if (this.props.registerFormvalues.tagKDYCode.length && conditionId && this.props.registerFormvalues.tagKDYCode.trim()) {
            this.props.validateTagCodes(this.props.registerFormvalues.tagKDYCode.trim(), conditionId).then(
                response => {
                    this.props.stop_loader();
                    this.props.registerFormvalues.tagKDYCode = {};
                    this.setState({ submitted: false })

                    if (response) {
                        this.props.registerFormvalues.tagKDYCode = response.tagCode;
                        this.props.registerFormvalues.tagCodeScreenEnable = true;
                        this.props.registerformUpdate(this.props.registerFormvalues);
                        /* if (promocode && response.tagCode.modalContent) {
                            this.props.gotoStep(22); //research niceone page
                        } else
                            this.props.gotoStep(23); // straight away go for membership options
                        //this.props.nextStep(); */   
                        this.props.pageFrom == 'register' ? this.onboardingUserTrack() : this.props.setStep(11, 'forward'); //clinic page

                    } else {
                        this.setState({ promoCodeErr: true, errorMessage: '' })
                    }
                },
                error => {
                    this.props.stop_loader();
                    toast.error(error);
                    this.setState({ errorMessage: error })
                    this.props.registerFormvalues.tagKDYCode = {};
                }
            );
        } else {
            this.setState({ promoCodeErr: true })
            //            this.props.gotoStep(23); // straight away go for membership options
        }

        ReactGA.event({
            category: "User Acquisition",
            action: "Sign up process",
            label: "How they heard"
        })
        ReactGA.event({
            category: "User Acquisition",
            action: "Sign up flow completed ",
        })
    }

    onboardingUserTrack = () => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            if (this.props.registerFormvalues.masterUserID) {
                this.props.checkConditionHasTagCode(this.selected_condition_list.id).then(() => {
                    if (this.props.isConditionHasCode) {
                        this.props.pageFrom == 'register' ? this.props.gotoStep(29) : this.props.setStep(29, 'forward');
                    } else {
                        this.props.gotoStep(23);
                    }
                })
            } else {
                this.props.gotoStep(11);
            }
        })
    }

    goBack =()=>{
        this.props.setStep(10);
        this.props.registerFormvalues.tagKDYCode = {}
    }

    render() {
        return (
            <div className="step12">
                {this.props.pageFrom != 'mycondition' &&
                    <h3 className="text-center "><a className="pull-left" href="javascript:void(0)" onClick={() => this.goBack()}><img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} /></a>{this.props.masterUserName ? this.props.masterUserName + ': ' : ''}Are you a participant in our Kidney Beam Kids  Pilot?<Link
                        to="/home"
                        className="close-register orangefont"
                    >
                        X
                    </Link></h3>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-9 m-t-20">
                        <p className="text-label">Kidney Beam Kids is currently being piloted only with patients from selected centres (but we hope very soon to be launching this service to a wider audience).</p>
                        <p className="text-label">If you have an access code from your centre please enter it below to continue.</p>
                        <p className="text-label">If you are a patient of a pilot centre and don't have a code please contact your physiotherapy team to get one.</p>

                        <div class="mt-4 mb-5 mx-auto">
                            <label htmlFor="promocode" className="text-label font-medium">Access Code</label>
                            <div class="row">
                                <div className="col-md-9 m-b-10">
                                    <input type="text" name="tagKDYCode" id="tagKDYCode" className="form-control input-control" onChange={this.handleChange} value={this.state.tagKDYCode} />
                                </div>
                                <div id="register-link" className="col-md-3 align-self-center text-center p-15">
                                    <button disabled={this.state.isDisablePromo} className="btn button-lightblue text-center w-100 p-3" onClick={() => this.tagCodeSubmit(true)}>Apply</button>
                                </div>
                            </div>
                            {this.state.submitted && (this.state.promoCodeErr) &&
                                <p className="text-danger">Please enter valid access code</p>}

                            {this.state.subscribedErr &&
                                <p className="text-danger">{Errors.subscribed_user_1}</p>}
                        </div>

                    </div>
                    {this.props.pageFrom == 'register' &&
                        <div
                            id="register-link"
                            className="buttons-read float-left text-center col-lg-10 col-xs-10 col-md-10 m-b-30"
                        >
                            <button
                                className="btn btn-beam-blue font-14 pointer col-xs-8 m-t-20 col-lg-6 col-md-6 m-r-20"
                                disabled={this.state.disableButton}
                                onClick={() => this.goBack()}
                            >
                                Go back to continue signing up
                            </button>
                            <a
                                className="btn btn-beam-blue-inverse font-14 col-xs-8 col-lg-4 m-t-20 col-md-4"
                                href="/home"
                            >
                                Go back to Kidney Beam homepage

                            </a>

                        </div>
                    }
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        heard_from: state.register.heard_from,
        instructorList: state.register.instructorList,
        isConditionHasCode: state.register.isConditionHasCode,
        condition_index: state.register.condition_index,
    };
};

const mapDispatchToProps = { getPromocodePaln, start_loader, stop_loader, registerNewUser, setStep, getConditionBasedInstructor, getPlanByCountry, setConditionIndex, validateTagCodes, setPageTitle, onboardingUserTrack, checkConditionHasTagCode };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KidneyYouthPilotComponent);
