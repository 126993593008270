import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { completeSession } from "../../../actions";
const SurveyNavigation = ({
  currentSession,
  handleChangeSession,
  sessionLength,
  isSurvey = false,
  isCompleted,
}) => {
  let history = useHistory();
  const { condition, programId, sessionId } = useParams();
  const dispatch = useDispatch();
  const handleCompleteSession = () => {

    dispatch(
      completeSession({
        programId,
        sessionId,
        status: 'complete'
      })
    );
  
    history.push(`/programmes/dashboard/${condition}/${programId}`);
  }
  return (
    <div className="d-flex prev-complete-btn video-nextsession justify-content-center mt-4">
      {/* {currentSession !== 1 && ( */}
      <button
        type="button"
        onClick={() => {
          handleChangeSession("previous", currentSession);
        }}
        className={`session-btn btn btn-outline-dark font-16 fw600  w-50 `}
        disabled={currentSession == 1}
      >
        Previous
      </button>
      {/* )} */}
      {currentSession < sessionLength && (
        <button
          type={isSurvey ? "submit" : "button"}
          onClick={() => {
            if (isSurvey) return false;
            handleChangeSession("next", currentSession);
          }}
          className={`completed-button1 btn font-16 font-semibold fw600 bg-lightblue w-50 `}
          disabled={isSurvey ? false : isCompleted}
        >
          Next
        </button>
      )}
      {currentSession === sessionLength && (
        <button
          type={isSurvey ? "submit" : "button"}
          onClick={() => {
            if (isSurvey) return false;
            handleCompleteSession();

            // handleChangeSession("next", currentSession);
          }}
          className={`completed-button1 font-16 font-semibold fw600 bg-lightblue w-50`}
        >
          Complete Session
        </button>
      )}
    </div>
  );
};

export default SurveyNavigation;
