import React from "react";
import { connect } from "react-redux";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import moment from 'moment';
import { fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass } from "../../actions";
import ScheduleListView from "./ScheduleListView";
import { commonService } from "../../_services";
import { Link } from 'react-router-dom';
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import EventCalendarComponent from "./EventCalendarComponent";
import { Cookies } from "react-cookie-consent";
import CalendarViewComponent from "../Common/CalendarViewComponent";
import ListViewComponent from "../Common/ListViewComponent";
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";

class ScheduleCalenderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            calenderLiveClassList: [],
            allLiveClassData: [],
            selectDate: new Date(),
            isCurrentWeek: 0,
            displayLayout: true,
            condition: this.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            total_levels: [],
            init_weeks_days: [],
            Liveclass_Weeks: constand.Liveclass_Weeks,
            openResearchModel: false,
            current_attendee: {},
            redirectUrl: '',
            workoutId: ''
        };
        this.fetchLiveClassesForInitiate = this.fetchLiveClassesForInitiate.bind(this);

        this.fetchLiveClassList = this.fetchLiveClassList.bind(this);
        this.submitStartNow = this.submitStartNow.bind(this);
        this.modelClose = this.modelClose.bind(this);
        this.joinClassService = this.joinClassService.bind(this);
        this.closeResearchModel = this.closeResearchModel.bind(this);
        this.viewPrepoll = this.viewPrepoll.bind(this);
        this.beforeReview = this.beforeReview.bind(this);
        console.log('schedule view props', props)
    }

    closeResearchModel() {
        this.setState({ openResearchModel: false });
    }

    beforeReview() {
        window.open(this.state.redirectUrl);
    }

    viewPrepoll(live) {
        console.log('prepoll', live.Attendees[0].reference);
        this.setState({ openResearchModel: true, redirectUrl: live.Attendees[0].reference, current_attendee: live.Attendees[0], workoutId: live.WorkoutId })
        console.log('url', this.state.redirectUrl)
    }

    submitStartNow(item) {
        if (this.props.is_auth) {
            this.joinClassService(item);
        } else {
            this.props.loginModelOpen(true);
        }
    }

    joinClassService(item) {
        var dataObj = { "roomId": item.id };
        this.props.start_loader();
        this.props.joinClass(dataObj).then(
            response => {
                this.props.stop_loader();
                if (response) {
                    this.setState({
                        modelState: true,
                        modelData: item
                    });
                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    modelState: false,
                    modelData: {}
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        this.setState({ modelState: false, modelData: {} });
    }

    /** fetch live class list on page did mount */
    componentDidMount() {
        var date = new Date();
        var startDate = date;
        var endDate = moment().add(6, 'd');

        var temp = [commonService.getWeekDays(startDate, endDate)];
        for (var i = 1; i < this.state.Liveclass_Weeks; i++) {
            startDate = moment(endDate).add(1, 'd');
            endDate = moment(endDate).add(7, 'd');
            temp.push(commonService.getWeekDays(startDate, endDate));
        }
        this.setState({ init_weeks_days: temp });

        var condition = commonService.replaceChar(this.state.condition, true)

        this.setState({
            selectDate: new Date(),
            selectedWeek: temp[0],
            condition: condition
        }, () => {
            this.fetchLiveClassesForInitiate()
            //this.fetchLiveClassList();
        })
    }

    componentWillReceiveProps(prevProps) {
        if (this.props.params && prevProps.params.condition && this.props.params.condition !== prevProps.params.condition) {
            var condition = commonService.replaceChar(prevProps.params.condition, true)
            this.setState({ condition: condition }, () => {
                this.fetchLiveClassList();
            })
        }
    }
    /**
     * fetch class list data for initiate
     */
    fetchLiveClassesForInitiate() {
        this.setState({ Loading: true, disableButton: true });
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition
        };
        this.props.fetchDashboardSchedule(dataObj).then(
            response => {
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {
                        this.setState({ allLiveClassData: list.liveClasses });

                        //this.groupListResult(list.liveClasses);
                        var firstScheduleDate = "";
                        list.liveClasses.map(function (item, key) {
                            if (key === constand.CONSTZERO) {
                                firstScheduleDate = item.scheduledFor;
                            }
                        });

                        var date = new Date();
                        var startDate = date;
                        var endDate = moment().add(6, 'd');
                        var temp = [commonService.getWeekDays(startDate, endDate)];
                        for (var i = 1; i < this.state.Liveclass_Weeks; i++) {
                            startDate = moment(endDate).add(1, 'd');
                            endDate = moment(endDate).add(7, 'd');
                            temp.push(commonService.getWeekDays(startDate, endDate));
                        }
                        this.setState({ init_weeks_days: temp });
                        let selectedLiveClassDate = temp[0];
                        let isCurrentWeek = 0;
                        let liveSelectedDate = new Date();

                        if (firstScheduleDate) {
                            let liveClassDate = moment(firstScheduleDate).format('YYYY-MM-DD');
                            for (var j = 0; j < this.state.Liveclass_Weeks; j++) {

                                if (temp[j].indexOf(liveClassDate) >= constand.CONSTZERO) {

                                    selectedLiveClassDate = temp[j];
                                    isCurrentWeek = j;
                                }
                                liveSelectedDate = firstScheduleDate;
                            }
                        }
                        this.setState({

                            selectDate: liveSelectedDate,
                            selectedWeek: selectedLiveClassDate,
                            isCurrentWeek: isCurrentWeek,
                            firstScheduleDate: firstScheduleDate
                        }, () => {
                            //this.fetchLiveClassList();
                        })

                    }
                }
            },
            error => {
                this.setState({
                    Loading: false,
                    disableButton: false
                });
                // toast.error(error);
            }
        );
    }

    /**
     * fetch class list data
     */
    fetchLiveClassList() {
        this.props.start_loader();
        this.setState({ disableButton: true });
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": this.state.condition,
            "dateChosen": moment(this.state.selectDate).format('YYYY-MM-DD')
        };
        this.props.fetchDashboardSchedule(dataObj).then(
            response => {
                this.props.stop_loader();
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {

                        this.groupListResult(list.liveClasses);
                    }
                    this.setState({
                        Loading: false,
                        count: response.count,
                        disableButton: false,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false,
                    disableButton: false
                });
                this.props.stop_loader();
                // toast.error(error);
            }
        );
    }
    /**
     * render start now or go to class
     */
    renderSchedule(item) {
        /*        var currentDateTime = moment().format('YYYY-MM-DD hh:mm::ss');
                var scheduleTime = moment(item.scheduledFor).format('YYYY-MM-DD hh:mm::ss');
                if (scheduleTime < currentDateTime) {
                    return (
                        <a
                            className="btn btn-purple w-100 font-medium m-b-10"
                            href={item.reference}
                        >
                            {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
                    );
                } else {
                    return (
                        <a onClick={() => this.submitStartNow(item)}
                            className="btn btn-purple w-100 font-medium m-b-10"
                        >
                            {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
                    );
                }
        
               if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
                   return (
                       <a className="btn btn-purple w-100 font-medium m-b-10"> {item.reference} </a>
                   );
               } else {
                   if (item.Signedup) {
                       return (
                           <a className="btn btn-purple w-100 font-medium m-b-10" href={item.reference} target="_blank"> Go to room </a>
                       );
                   }
                   else {
                       return (
                           <a onClick={() => this.submitStartNow(item)} className="btn btn-purple pad_list_btn clearfix pointer" > Sign up </a>
                       );
                   }
               }*/
    }
    //cancel class
    cancelCalss(item, key) {
        if (!this.state.cancelLoading) {
            this.setState({ cancelLoading: true });
            var dataObj = {
                "roomId": item.id
            };
            this.props.cancelClass(dataObj).then(
                response => {
                    if (response) {
                        toast.success(response.message);
                    }
                    var array = [...this.state.calenderLiveClassList];
                    array.splice(key, 1);

                    this.setState({
                        calenderLiveClassList: array,
                        cancelLoading: false
                    });
                },
                error => {
                    this.setState({
                        cancelLoading: false
                    });
                }
            );
        }
    }
    /**
     * render item list
     */
    renderSubItemList() {
        if (this.state.calenderLiveClassList.length > 0) {
            var tagCondition = commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();
            return this.state.calenderLiveClassList.map((item, index) => (
                <div className="list-group-item" key={index}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="media col-md-3 col-lg-3 p-0">
                                <figure className="pull-left position-relative">
                                    <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }} >
                                        <ImageTag
                                            className="media-object image-size img-rounded img-fluid"
                                            src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                        />
                                    </Link>
                                    <div className="time-box">
                                        <i className="fa fa-clock-o w-100"></i>
                                        <div className="w-100">{item.Workout.length} mins</div>
                                    </div>
                                </figure>
                            </div>
                            <div className="col-md-9 col-lg-9 p-0">
                                <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">
                                    <div className="col-12 float-left p-b-20">
                                        <div className="p-0 border-0 float-left w-100">
                                            <img
                                                className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                                src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                                alt="" width="75" height="75"
                                            />
                                            <div className="font-24 font-medium orangefont m-b-5 w-80">
                                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>  {item.Workout.title}</Link>
                                            </div><div className="w-80">with
                                                {item.Workout.Instructor.hasProfile &&
                                                    <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (commonService.replaceChar(this.props.params.condition, false))} className="font-16 font-semibold black-txt p-l-5">
                                                        {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                    </Link>
                                                }
                                                {!item.Workout.Instructor.hasProfile &&
                                                    <span className="font-16 font-semibold black-txt p-l-5">
                                                        {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                    </span>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 float-left">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Discipline:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "discipline", this.state.total_tags, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-12 float-left p-b-20">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Difficulty:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "level", this.state.total_levels, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                    {(item.type == 'Live' && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition)) ?
                                        <a target="_blank" className="btn btn-purple-inverse w-100 font-medium m-b-10" onClick={() => this.viewPrepoll(item)}>Go To Class</a>
                                        :
                                        ((item.type == 'Live' && !this.props.logged_userData.isStudyParticipant) || (item.type == 'Live' && this.props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition)))
                                            ?
                                            <a target="_blank" className="btn btn-purple-inverse w-100 font-medium m-b-10" href={item.Attendees[0].reference || '#'}>Go To Class </a>
                                            :
                                            <a className="btn btn-purple-inverse w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (commonService.replaceChar(this.props.params.condition, false))} onClick={() => { localStorage.setItem('scheduleRoomId', item.id); }}>Start Now</a>
                                    }
                                    {(item.Signedup) &&
                                        <a href="javascript:void(0)"
                                            className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(item, index)}
                                        >
                                            Oops, I can't make it
                                        </a>}
                                    <span
                                        className="btn btn-default-list-blue clearfix pad_list_btn"
                                    >
                                        {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                    </span>
                                    {item.type == 'Live' && <span className="btn btn-blue-inverse w-100 btn-white redtext bg-white font-medium" >Live session</span>}
                                    <EventCalendarComponent item={item} type={item.type} title={item.Workout.title} length={item.Workout.length} />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            ));
        } else {
            return (<div className="border-bottom w-100 float-left" >
                <h3 className="m-t-20 text-center">Oh no - you have no classes scheduled on this day. Why not schedule an on-demand class right now?</h3></div>);
        }
    }
    /**
     * render load more option
     */
    renderLoadMore() {
        if (this.state.calenderLiveClassList && this.state.count > this.state.calenderLiveClassList.length) {
            return (
                <div className="w-100 text-center">
                    <a onClick={() => {
                        if (!this.state.disableButton) {
                            this.incrementShowmoreCnt()
                        }
                    }} className="btn btn-orange m-t-40 m-b-40 text-center font-book">  Show more schedule list</a>
                </div>
            );
        }
    }

    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {
        let classList = [];
        let existingData = this.state.calenderLiveClassList;
        let newData = originalList;
        classList = [...existingData, ...newData];
        this.setState({ calenderLiveClassList: classList })
    }


    /**
    * setSelectedDate
    */
    changeSelectedDate(item) {
        this.setState({
            selectDate: item,
            calenderLiveClassList: [],
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * get day
     */
    getDate(item) {
        var weekDay = moment(item).format('DD');
        var dt = moment(item, "YYYY-MM-DD HH:mm:ss")
        var dayName = dt.format('ddd');;
        return dayName + ' ' + weekDay;
    }
    /**
     * render week days
     */
    renderWeekDays(weeks) {
        if (weeks) {
            var selectedDate = this.state.selectDate;
            var currentDate = moment(selectedDate).format('YYYY-MM-DD');
            return weeks.map((item, index) => (

                <div className="months-list float-left" key={index}>
                    <span onClick={() => { this.changeSelectedDate(item) }}
                        className={item === currentDate ? 'active' : 'pointer'}>{this.getDate(item)}
                    </span>
                </div>
            ));
        }
    }

    /**
     * get day
     */
    getMobileDate(item) {
        var dt = moment(item, "YYYY-MM-DD HH:mm:ss")
        var dayName = dt.format('dd');;
        return dayName.charAt(0);
    }
    getMobileDay(item) {
        var weekDay = moment(item).format('DD');
        return weekDay;
    }
    /**
     * render week days
     */
    renderMobileWeekDays(weeks) {
        if (weeks) {
            var selectedDate = this.state.selectDate;
            var currentDate = moment(selectedDate).format('YYYY-MM-DD');
            return weeks.map((item, index) => (

                <div className="months-list float-left" key={index}>
                    <span onClick={() => { this.changeSelectedDate(item) }}
                        className={item === currentDate ? 'active' : 'pointer'}><div className="text-center">{this.getMobileDate(item)}</div><div className="text-center">{this.getMobileDay(item)}</div>
                    </span>
                </div>
            ));
        }
    }

    /**
     * selected month & day
     */
    seletedMonthDay() {
        var selectDate = this.state.selectDate;
        var monthName = moment(selectDate).format('MMMM');
        return monthName;
    }
    /**
     * goToPreviousWeek
     */
    goToPreviousWeek(week) {
        this.setState({
            selectedWeek: this.state.init_weeks_days[week],
            isCurrentWeek: week,
            calenderLiveClassList: [],
            selectDate: this.state.init_weeks_days[week][0],
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * goToNextWeek
     */
    goToNextWeek(week) {
        this.setState({
            selectedWeek: this.state.init_weeks_days[week],
            isCurrentWeek: week,
            calenderLiveClassList: [],
            selectDate: this.state.init_weeks_days[week][0],
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * render previous week icon
     */
    renderPreviousWeekIcon() {
        if (this.state.isCurrentWeek > 0) {
            return (
                <i onClick={() => { this.goToPreviousWeek(this.state.isCurrentWeek - 1) }} className="fa fa-angle-left float-left"></i>
            )
        }
    }
    /**
     *  render NextWeek Icon
     */
    renderNextWeekIcon() {
        if (this.state.isCurrentWeek < constand.Liveclass_Weeks - 1) {
            return (
                <i onClick={() => { this.goToNextWeek(this.state.isCurrentWeek + 1) }} className="fa fa-angle-right float-right"></i>
            )
        }
    }
    /**
    * change Layout
    */
    changeViewLayout() {
        var layout = this.state.displayLayout;
        this.setState({
            displayLayout: !layout
        })
    }
    /**
     * renderLayout()
     */
    renderLayout() {
        return (
            <p className="row">
                <i className={this.state.displayLayout ? "fa fa-calendar-o m-r-5" : "fa fa-list-ul"} aria-hidden="true"></i>
                <a onClick={() => { this.changeViewLayout() }} className="orangefont font-medium"><u className="pointer">
                    {this.state.displayLayout ? 'Switch to calendar view' : 'Switch to list view'}</u></a></p>
        )
    }
    /**
     * renderLiveClassComponent
     */
    renderLayoutComponent() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
        var community = '';
        if (authData && authData.isStudyUser) {
            community = authData.Members.length ? authData.Members[0].Community.community_name : '';
        }
        if (this.state.displayLayout) {
            /*  return (
                 <div className="row border-box m-b-50">
                     <h4 className="card-header date-heading w-100 font-medium text-center text-white">{this.seletedMonthDay()}</h4>
                     <div className="listname w-100 desktop-view">
                         {this.renderPreviousWeekIcon()}
                         {this.renderWeekDays(this.state.selectedWeek)}
                         {this.renderNextWeekIcon()}
                     </div>
 
                     <div className="listname w-100 mobile-view">
                         {this.renderPreviousWeekIcon()}
                         {this.renderMobileWeekDays(this.state.selectedWeek)}
                         {this.renderNextWeekIcon()}
                     </div>
                     <div className="text-center w-100">
                         {(this.state.Loading) && (<AnimateLoaderComponent />)}
                     </div>
                     {(!this.state.Loading) && this.renderSubItemList()}
                     {(!this.state.Loading) && this.renderLoadMore()}
                 </div>
 
             ); */
            return <ListViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.props.params.condition} location={this.props.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Dashboard' history={this.props.history} />

        } else {
            return <CalendarViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.props.params.condition} location={this.props.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Dashboard' group={community} />
        }
    }
    /**
        * render list
        */
    renderListData() {
        return (
            <div>
                {this.renderLayoutComponent()}
            </div>
        );

    }
    //main render
    render() {
        return (
            <div className="container">
                <ResearchStudyPollPopup
                    is_model_open={this.state.openResearchModel}
                    closeResearchModel={this.closeResearchModel}
                    classType="Live"
                    beforeReview={this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                />
                <Helmet>
                    <title>{constand.DASHBOARD_SCHEDULE_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.DASHBOARD_SCHEDULE_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.DASHBOARD_SCHEDULE_DESC} />
                    <meta property="og:image" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE_ALT} />
                </Helmet>
                <div className="row">
                    {Object.keys(this.props.schedule_live_class_list).length > 0 &&
                        <>
                            <div className="col-lg-3 col-md-12 col-sm-12 float-left">
                                {this.renderLayout()}
                            </div>
                            <div className="col-md-6">
                                <p className="w-40 font-16 font-semibold black-txt text-center">All class times shown in {" " + commonService.localTimeZoneName()} time</p>
                            </div>
                        </>
                    }
                    <div className="col-lg-12 col-md-12 col-sm-12 float-left">
                        {this.renderListData()}

                    </div>
                </div>
                <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        schedule_live_class_list: state.dashboard.schedule_live_class_list,
    };
};

const mapDispatchToProps = {
    fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleCalenderView);
