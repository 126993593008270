import React from "react";
import { connect } from "react-redux";
import ContactForm from "../ContactPage/ContactFormComponent";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [{ 'one': false, 'two': false, 'three': false, 'four': false, 'five': false, 'six': false, 'seven': false, 'eight': false, 'nine': false, 'ten': false }]
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    var condition = Cookies.get('condition');
    if(condition === undefined) {
      let get_userDetails = JSON.parse(localStorage.getItem("userDetails"));
      if(get_userDetails) {
        let conditionObjects = get_userDetails.Tags.filter(obj => obj.type === 'condition');
        condition = conditionObjects.length > 0 ? conditionObjects[0].tag : '';
      }
    }
    console.log('about-us condition:', condition);
    if(condition == constand.KR_CONDITION || condition == constand.KD_YOUTH) {
      this.props.history.push('/about-us/kidney-disease');
    }
  }
  ChangeStateReadLess(type, value) {

    const newItems = Object.assign({}, this.state.expanded);
    newItems[type] = value;
    console.log('newitems', newItems)
    this.setState({
      expanded: newItems
    });
  }
  render() {
    return (
      <div className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'aboutcontent' : 'aboutcontent m-t-80'}>
        <Helmet>
          <title>{constand.ABOUT_US_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.ABOUT_US_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.ABOUT_US_DESC} />
          <meta property="og:image" content={constand.ABOUT_US_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.ABOUT_US_PAGE_IMAGE_ALT} />
        </Helmet>
        <section id="aboutsection1" className="aboutsection1-bg p-t-30 p-b-30">
          <div className="container">
            <div className="section-header text-center ">
              <h3 className="darkblue-text font-37 font-medium">About </h3>
              <p className="w-80 mx-auto p-t-20 font-21 black-txt p-b-10 font-medium">
                We know you want to be more active in order to keep healthy and
                feel good. But

                we also know that there are lots of hurdles that life can throw
                in your way...

              </p>
              <p className="w-80 mx-auto font-21 black-txt  p-b-10 font-medium">
                Keeping active and doing exercise can be especially hard when
                you're living with

                a health condition and the symptoms, treatments and pressures
                that come with

                it. And all while trying to stay on top of 'normal' life.
              </p>
              <p className="w-79 mx-auto font-21 black-txt  p-b-10 font-medium">
                That's why we're here. Hi!<img className="img-fluid m-l-5 m-b-5" src={constand.WEB_IMAGES + "hand.png"} />
              </p>
            </div>
          </div>
        </section>
        <section id="aboutsection2" className="aboutsection2-bg  p-t-30 p-b-80">
          <div className="container-fluid mx-auto w-75">
            <div className="section-header text-center">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 p-t-30 text-center">
                  <figure className="text-center">
                    <img
                      src={constand.WEB_IMAGES + "About_blog_img1.png"}
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
                <div className="col-12 col-md-6 col-lg-6 text-left m-t-60">
                  <h4 className="m-b-20 font-24 font-normal font-semibold golden-yellow-text font-medium">
                    An intro to Kidney Beam
                  </h4>
                  <p className="black-txt font-qregular m-b-10">
                    With Kidney Beam, you can access fun exercise that's tailored for
                    you and your health needs, online, any time, any place.
                  </p>
                  <p className="black-txt font-qregular  m-b-10">
                    You can exercise along with on demand classes at a time that
                    suits you or join a live class that allows you to interact
                    with the instructor and other Kidney Beam members.
                  </p>
                  <p className="black-txt font-qregular  m-b-10">
                    For those of you who would like an extra bit of motivation
                    or social interaction, we've created groups where you can
                    connect with physiotherapists, instructors and other people
                    living with similar challenges to you. Let's lift each other
                    up!
                  </p>
                  <p className="black-txt font-qregular m-b-10">
                    We know it's hard, but we want to help. Let us make things
                    easier for you by bringing the experts, your community and
                    your health studio to you, wherever you are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="aboutsection3" className="aboutsection3-bg p-t-80 p-b-80">
          <div className="container">
            <div className="section-header text-center ">
              <h4 className="orangefont font-24 font-normal font-semibold ">How we started</h4>
              <p className="w-75 mx-auto p-t-30 black-txt w-56 font-qregular">
                Our journey began when Katie, our Co-founder and Techie, put her
                coding skills to use to try and
                solve a problem she had: lack of motivation to exercise. She
                thought that by being able to create

                a workout pact with her friends, wherever they were in the
                world, she'd improve her motivation to

                keep active. And she was right!
              </p>{" "}
              <p className="w-75 mx-auto p-t-10 black-txt w-56 font-qregular">
                Then, Katie met Pamela, our other Co-founder and a
                Physiotherapist, who had an 'aha!' moment.

                Pamela realised that a platform like this would be super helpful
                to people who face challenges

                getting active because of a medical condition... like how people
                with cystic fibrosis struggle to find

                the time to exercise, can't meet in person to do fun activity or
                may even struggle to leave the

                house some days.
              </p>{" "}
              <p className="w-75 mx-auto p-t-10  black-txt font-qregular">
                So, Pamela and Katie joined forces and decided to try to help
                people with specific health needs to
                <br />
                feel a bit more badass.
              </p>
            </div>
          </div>
        </section>
        <section id="aboutsection4" className="aboutsection4-bg p-t-60 p-b-80">
          <div className="container-fluid mx-auto w-55">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 text-left  ">
                <h4 className="m-b-30 m-t-50 darkblue-text font-semibold font-24">Our Mission</h4>
                <p className="black-txt font-qregular">
                  We're all about helping people with a health condition feel
                  good, no matter where they are along their health journey.
                </p>
                <p className="black-txt font-qregular">
                  We're supporting people worldwide to get that 'punch the sky'
                  feeling every day by offering health-condition-specific
                  exercise videos with fun, friendly and super-empathetic
                  support.
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-6 text-center">
                <figure>
                  <img src={constand.WEB_IMAGES + "Floor_mat_pair_2_RGB.png"} className="img-fluid m-t-20" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        {1 == 0 && // hide for now
          <section id="aboutsection5" className="aboutsection5-bg p-t-40 p-b-80">
            <div className="container-fluid mx-auto w-90">
              <div className="col-md-12 col-lg-12 text-center">
                <h4 className="m-b-50 m-t-50 orangefont font-semibold font-24 font-normal">Our Team</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Katie.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Katie Bell </h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Co-Founder and CEO
                            {/* <span className="orangefont font-14 font-semibold">Tech</span> */}
                          </p>
                          {/* <a href="https://www.linkedin.com/in/linkedinkatiebell/" className="socialLink" target="_blank">
                          <span className=" p-r-5">
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a> */}
                          {/* <span className=" p-r-5">
                          <i className="fa fa-twitter"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-facebook"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-instagram"></i>
                        </span> */}
                          {!this.state.expanded.one &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('one', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.one &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('one', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.one &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              She's a general boss who's always busy making
                              improvements to our product with her coding skills,
                              while running the business
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Katie is a socially-driven, serial entrepreneur with a
                              technical background. After an early career in IT
                              consultancy she found her comfort zone in the world of
                              tech startups.
                            </p>
                            <p className="box--par font-qregular">
                              When not engrossed in code or uplifting
                              the team with her dedication and endless
                              encouragement, Katie will mainly be found on the side
                              of a mountain and is a qualified ski instructor.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">Katie lives in New York City, USA, with her husband, two young children and dog, Roxy.</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Katie's feel good formula
                            </p>
                            <p className="box--par font-qregular">
                              Tech for good + family time + mountain air + gin + tonic
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Pamela.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Pamela Scarborough</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Co-Founder and Clinical Director
                            {/* <span className="orangefont font-14 font-semibold">Health</span> */}
                          </p>
                          {/* <a href="https://www.linkedin.com/in/pamelascarborough/" className="socialLink" target="_blank">
                          <span className=" p-r-5">
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a> */}
                          {/* <span className=" p-r-5">
                          <i className="fa fa-twitter"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-facebook"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-instagram"></i>
                        </span> */}
                          {!this.state.expanded.two &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('two', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.two &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('two', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.two &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              She figures out how the tech can best be used to help the people who matter: our community members.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Pamela has a MSc in Physiotherapy and over 15 years'
                              experience working as a physiotherapist (mainly spent
                              helping people with cystic fibrosis).
                            </p>
                            <p className="box--par font-qregular">
                              She's also a yoga instructor who regularly lifts
                              the team up with her generosity, words of wisdom
                              and compassionate, calming character.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">Pamela lives in Sydney, Australia, with her husband, two children and dog, Sol.</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Pamela's Feel good formula
                            </p>
                            <p className="box--par font-qregular">
                              Coffee + blue skies + ocean swims + yoga + people you love
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Mel.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Mel Bennardo</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Operations Manager
                          </p>
                          {/* <a href="https://www.linkedin.com/in/nataliegoodchild/" className="socialLink" target="_blank">
                          <span className=" p-r-5">
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a> */}
                          {/*  <span className=" p-r-5">
                          <i className="fa fa-twitter"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-facebook"></i>
                        </span>
                        <span className=" p-r-5">
                          <i className="fa fa-instagram"></i>
                        </span> */}
                          {!this.state.expanded.three &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('three', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.three &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('three', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.three &&

                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              Looking at how we work as a team, making things run smoothly and generally helping out across
                              the business with anything people may need help with, specifically process driven usually.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Coming from an investment banking background, Mel’s career has spanned PR,
                              charity and most recently in to the realm of health and wellbeing.
                              Teaching dance fitness, yoga and meditation, Mel is well versed in both
                              participating and supporting individuals with their health and wellbeing needs
                              while using his corporate trained mind to help solve problems.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you’ll find him
                            </p>
                            <p className="box--par font-qregular">London, UK</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Mel's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Hugs +  Pizza +  A good belly laugh
                            </p>
                          </div>
                        </div>

                      }
                    </div>

                  </div>

                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Natasha.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Natasha Wynn</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Online Studio Manager - Renal & Cancer
                          </p>
                          {!this.state.expanded.five &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('five', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.five &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('five', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.five &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              Looking after the online classes for Kidney Beam, and Cancer Care. Also, onboarding clinics
                              and physios ahead of their very first Kidney Beam classes, as well as working with Olivia on general
                              online studio tasks.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Natasha comes from a dance and theatre background, having performed professionally in London and Paris.
                              After too many surgeries stopped her dancing in her late 20s, she retrained a personal trainer, nutrition
                              advisor and has completed courses in barre fitness, and exercise for cancer rehab.
                            </p>
                            <p className="box--par font-qregular">
                              More recently, since the diagnosis of connective tissue disorder Ehlers Danlos Syndrome, Natasha stepped back
                              from teaching full time and has been managing boutique fitness studios.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">London, UK</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Natasha's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Sunshine + Hugs + Coffee + Cats (+ the ocean for a bonus)
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Olivia.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Olivia Clark</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Online Studio Manager - Cystic Fibrosis
                          </p>
                          {!this.state.expanded.six &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('six', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.six &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('six', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.six &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              Looking after the online classes for Cystic Fibrosis. Also, onboarding independent
                              instructors and working with Natasha on general online studio tasks.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Olivia was diagnosed with CF at the age of 11. It's believed that
                              she had a late diagnosis due to dance keeping her healthy for most of her childhood.
                            </p>
                            <p className="box--par font-qregular">
                              Olivia began dancing at the age of 4 and trained for 14 years. She is also a 200-hour
                              registered yoga teacher,  that specializes in yin and restorative yoga, as well as children's yoga.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">
                              In South Carolina, US with her husband and two rescue fur babies, Irma (cat) and Winnie (dog).
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Olivia's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Animals + Dance + Being Outside  + Wine + Fleetwood Mac
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0 " href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Michael.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Michael Jones</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Digital Marketing & Communications Manager
                          </p>
                          {!this.state.expanded.eight &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('eight', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.eight &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('eight', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.eight &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              Help to generate awareness of Kidney Beam through our communications channels and ensure
                              that Kidney Beam’s value proposition is meeting the needs of customers to support and empower
                              them to be more physically active
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Michael has spent the last 8 years working on digital marketing projects in the fitness industry.
                              Most recently Michael has completed an MBA at the University of Surrey. Michael has worked on projects
                              as wide ranging as optimising BMF’s paid media strategy, launching new clubs and fitness products and
                              developing an retention model for online platforms in the fitness industry for his dissertation.
                            </p>
                            <p className="box--par font-qregular">
                              Michael has a long standing passion for physical activity, coming from a background as a competitive
                              swimmer and now can often be found training for endurance events and has a long standing ambition to
                              swim the English channel
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you’ll find him
                            </p>
                            <p className="box--par font-qregular">Surrey, UK</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Michael's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Travel + Outdoor Sports + Red Wine
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0" href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Sharlene.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <br></br>
                          <h4 className="black-txt font-medium font-20 m-b-5">Sharlene Greenwood</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Kidney Beam Lead
                          </p>
                          {!this.state.expanded.nine &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('nine', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.nine &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('nine', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.nine &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              She's helping set up our new kidney service on Beam, affectionately known as ‘Kidney Beam’
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              Sharlene has a PhD and specialises in all things related to exercise and wellbeing for people
                              living with kidney disease. She is also the President of the British Renal Society. As well as having
                              16 years of experience working with people with kidney disease, Sharlene is also a qualified Pilates
                              Instructor who teaches the Physio-led Pilates sessions on Kidney Beam.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">London, UK</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Sharlene's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Family + Coco our dog + exercise with my team + coffee!
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>


                  <div className="col-md-6 col-lg-4 m-b-20">
                    <div className="box">
                      <div className="media row">
                        <a className="col-md-12 d-inline-block mx-auto col-sm-4 col-4 p-0" href="javscript:void(0)">
                          <img
                            className="media-object dp img-fluid"
                            src={constand.WEB_IMAGES + "Sue.png"}
                          />
                        </a>
                        <div className="col-md-12 p-0 text-center m-t-10">
                          <h4 className="black-txt font-medium font-20 m-b-5">Sue Mercer</h4>
                          <p className="black-txt m-b-5 font-semibold ">
                            Video Editor
                          </p>
                          {!this.state.expanded.ten &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('ten', true)}
                              className="box--head font-semibold m-b-10">Read More</a></p>
                          }
                          {this.state.expanded.ten &&
                            <p><a href="javascript:void(0)" onClick={() => this.ChangeStateReadLess('ten', false)} className="box--head font-semibold m-b-10">Read Less</a></p>
                          }
                        </div>
                      </div>
                      {this.state.expanded.ten &&
                        <div className="col-md-12 col-lg-12 p-0 m-t-20 text-left">
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              And that means...
                            </p>
                            <p className="box--par font-qregular">
                              Exactly what it says on the tin! Sue is the one who downloads the (huge!) recorded live classes,
                              chops off the front and end (the bits where the instructors are right up close to the
                            </p>
                            <p className="box--par font-qregular">
                              camera pressing the stop/start button!) and adds the titles and logos to make them ready for going on demand.
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              What else you should know
                            </p>
                            <p className="box--par font-qregular">
                              A qualified mechanical engineer, Sue has always worked in ‘tech’ roles, starting out as a project engineer
                              on tunnelling machines for digging sewers (glamorous!) to more recently producing and editing videos and podcasts,
                              alongside creating websites. She definitely prefers being behind the camera rather than in front of it. Sue took up
                              running relatively recently, and has completed a few ultra marathons and other long distance events, in addition to
                              open water swimming, having last year achieved (one of) her childhood dreams of swimming to the Isle of Wight!
                            </p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Where in the world you'll find her
                            </p>
                            <p className="box--par font-qregular">In the beautiful World Heritage City of Bath, UK, with my two teenage kids.</p>
                          </div>
                          <div className="m-t-15">
                            <p className="box--head font-semibold m-b-10">
                              Sue's Feel-good formula
                            </p>
                            <p className="box--par font-qregular">
                              Long coastal run + hot shower afterwards + cup of tea (or glass of champagne)
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-4 m-b-20">
                  <div className="box">
                    <div className="media">
                      <a className="pull-left col-md-4 col-sm-2 p-0 " href="javscript:void(0)">
                        <img
                          className="media-object dp img-fluid"
                          src={constand.WEB_IMAGES + "Jon.png"}
                        />
                      </a>
                      <div className="col-md-8 p-r-0 text-left m-t-10">
                        <h4 className="black-txt font-medium font-20 m-b-5">Jonathan King </h4>
                        <p className="black-txt m-b-5 font-14 font-semibold ">
                          {" "}
                          <span className="orangefont font-14 font-semibold">
                            Business Development
                          </span>
                        </p>
                        <a href="https://www.linkedin.com/in/jonathan-king-239171195/" className="socialLink" target="_blank">
                          <span className=" p-r-5">
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-md-6 col-lg-4 m-b-20">
                  <div className="box">
                    <div className="media">
                      <a className="pull-left col-md-4 col-sm-2 p-0 " href="javscript:void(0)">
                        <img
                          className="media-object dp img-fluid"
                          src={constand.WEB_IMAGES + "Kat.png"}
                        />
                      </a>
                      <div className="col-md-8 p-r-0 text-left m-t-10">
                        <h4 className="black-txt font-medium font-20 m-b-5">Kat Chesculescu </h4>
                        <p className="black-txt m-b-5 font-14 font-semibold ">
                          {" "}
                          <span className="orangefont font-14 font-semibold">
                            Support
                          </span>
                        </p>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-6 col-lg-4 m-b-20">
                  <div className="box">
                    <div className="media">
                      <a className="pull-left col-md-4 col-sm-2 p-0 " href="javscript:void(0)">
                        <img
                          className="media-object dp img-fluid"
                          src={constand.WEB_IMAGES + "Amanda.png"}
                        />
                      </a>
                      <div className="col-md-8 p-r-0 text-left m-t-10">
                        <h4 className="black-txt font-medium font-20 m-b-5">Amanda Savage </h4>
                        <p className="black-txt m-b-5 font-14 font-semibold ">
                          {" "}
                          <span className="orangefont font-14 font-semibold">
                            Women's Health Advisor
                          </span>
                        </p>
                        <a href="https://www.linkedin.com/in/amanda-savage/" className="socialLink" target="_blank">
                          <span className=" p-r-5">
                            <i className="fa fa-linkedin"></i>
                          </span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div> */}
                </div>

              </div>
            </div>
          </section>
        }
        <section id="aboutsection6" className="aboutsection6-bg p-t-30 p-b-100">
          <div className="container-fluid mx-auto w-75">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 text-center">
                <figure>
                  <img
                    src={constand.WEB_IMAGES + "About_blog_img2.png"}
                    className="img-fluid"
                    alt=""
                  />
                </figure>
              </div>
              <div className="col-12 col-md-6 col-lg-6 text-left  m-t-60">
                <h4 className="m-b-30 m-t-50 font-semibold font-24 font-normal darkblue-text">Our Company</h4>
                <p className="black-txt font-qregular w-75">
                  We’re a bootstrapped startup who got off the ground thanks to lots of time and money investment from our
                  founding team, whose dedication and passion for our mission to make you feel good is unwavering.
                </p>
                <p className="black-txt  font-qregular">
                  We’ve also had a helping hand from grants and the support of our partners…
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="work-with-us">
          <div className="container">
            <div class="col-lg-12">
              <div class="partner-title-purple">Our Friends & Collaborators</div>
              <div class="row">
                <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0">
                  <div class="outer-wrapper">
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/CFF.png"} /></div>
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/CFT.png"} /></div>
                    <div class="wrap"></div>
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/CFA.png"} /></div>
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/CF_Ireland.jpg"} /></div>
                    <div class="wrap"></div>
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/Breas.png"} /></div>
                    <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/ACPCF.png"} /></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0">
                  <div class="outer-wrapper">
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/Brompton.png"} /></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/NHS-newcastle.png"} /></div>
                    <div class="wrap"></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/UofE.png"} /></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/Philips.png"} /></div>
                    <div class="wrap"></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/peppy.png"} /></div>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0">
                  <div class="outer-wrapper">
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "KRUK-logo.png"} /></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/BRS-Logo.jpg"} /></div>
                    <div class="wrap"></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/renal_logo_cmyk.jpg"} /></div>
                    <div class="frame frame-low"><img width="100%" src={constand.WEB_IMAGES + "logos/King-sCollegeHospital-NHS-Foundation-Trust-RGB-BLUE-right.jpg"} /></div>
                    <div class="wrap"></div>
                    <div class="frame frame-low"><img width="100%" className="w-55" src={constand.WEB_IMAGES + "logos/WoSACF.png"} /></div>
                    <div class="wrap"></div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="section-4 aboutsection8-bg">
          <div className="col-md-6 ml-auto mr-auto">
            <h4 className="text-center purplefont font-semibold font-normal font-24 m-b-60">Contact Us</h4>
            <ContactForm location={this.props.location} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutPage);
