import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {registerables, Chart } from "chart.js";
import { Line } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from 'moment';

import * as constand from "../../constant";
import { commonService } from "../../_services";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { fitnessTestModalOpen, fitnessTestForm, isTakeTestClicked, getUserFitnessTestScore } from "../../actions";

var _ = require('lodash');
Chart.register(...registerables);
Chart.register(ChartDataLabels);

class ProgressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            testScore: [],
            testDate: [],
            testGraphScore: [],
            testGraphDate: [],

            testGraphScoreSitToStand: [],
            testGraphDateSitToStand: [],
            
            testGraphScorePAVS: [],
            testGraphDatePAVS: [],
        };
        this.isTestTakenToday = false
    }

    /** fetch live class list on page did mount */
    componentDidMount() {
        this.props.fitnessTestForm();
        this.props.getUserFitnessTestScore();
    }

    takeTest = () => {
        let takeTestFlagObj = {
            isTakeTestClicked: true,
            clickedByTestPopup: false
        }
        this.props.isTakeTestClicked(takeTestFlagObj);
        localStorage.setItem("fitnessPopupClosed", false)
        this.props.fitnessTestForm().then(response => {
            this.props.fitnessTestModalOpen(true);
        }, error => {

        });
    }

    formatTestGraphData = () => {
        if (this.props.userTestScoreList.length) {
            this.state.testGraphScore = []
            this.state.testGraphDate = []

            this.state.testGraphScoreSitToStand = []
            this.state.testGraphDateSitToStand = []

            this.state.testGraphScorePAVS = []
            this.state.testGraphDatePAVS = []
            const testScores = Array.from(this.props.userTestScoreList); //clone user test score
            const filteredDataRes = commonService.removeDuplicateAndFilterByDate(testScores);
            const testScoresAsc = commonService.sortArray(filteredDataRes, true);
            
                testScoresAsc.length && testScoresAsc.map((item) => {
                    let scoreEnteredDateGraph = moment(item.createdAt).format('DD/MM/YYYY');
                    if (this.state.testGraphDate) {
                        this.state.testGraphDate.push(scoreEnteredDateGraph);
                        this.state.testGraphScore.push(item.testScore);
                    }
                    if (item.formId === 2) {
                        this.state.testGraphScoreSitToStand.push(item.testScore);
                        this.state.testGraphDateSitToStand.push(scoreEnteredDateGraph);
                    }
                    if (item.formId === 4) {
                        this.state.testGraphScorePAVS.push(item.testScore);
                        this.state.testGraphDatePAVS.push(scoreEnteredDateGraph);
                    }
                })
        }
    }

    getMostRecentDate = (userTestScores) => {
        const mostRecentDate = commonService.getMostRecentDate(userTestScores);
        return mostRecentDate
    }

    getHighestTestScore = (userTestList) => {
        let highestTestScore = Number.MIN_VALUE;
        userTestList.length && userTestList.map(obj => {
            const currentTestScore = parseInt(obj.testScore);
            if (currentTestScore > highestTestScore) {
                highestTestScore = currentTestScore;
            }
        });
        if (userTestList && userTestList.length == 1) {
            highestTestScore = userTestList[0].testScore
        }
        return highestTestScore;
    }

    formatTableScore = (userTestScoreList) => {
        let userTestScoreListTemp = Array.from(userTestScoreList)
        let scoreConcatVal = [];
        let tempArr = []
        userTestScoreListTemp.length && userTestScoreListTemp.reverse().map((item, key) => {
            let prevScore;
            prevScore = (key > 0) ? userTestScoreListTemp[key - 1].testScore : 0;
            let calculatedScore = prevScore > 0 ? item.testScore - prevScore : item.testScore;
            let scoreConcat = item.testScore;
            if (prevScore > 0) {
                scoreConcat = (item.testScore > prevScore ? item.testScore + " (+" + calculatedScore + ")" : item.testScore + " (" + calculatedScore + ")")
            }
            tempArr.push(scoreConcat);
            tempArr.length && scoreConcatVal.unshift(tempArr[key]);
        })
        return scoreConcatVal
    }


    checkTestTakenToday = (userTestScoreList) => {
        const mostRecentDate = commonService.getMostRecentDate(userTestScoreList);
        this.isTestTakenToday = commonService.checkIsTodayDate(mostRecentDate);
    }

    renderScoreTable = (scoreTableData, isPAVS) => {
        const userTestScoreList = scoreTableData.length && Array.from(scoreTableData); //clone user test score
        const scoreConcat = this.formatTableScore(userTestScoreList)
        return (
            <>
                <table className="table table-bordered border-bottom-0">
                    <thead>
                        <td colspan="2" className="tbody-row px-0 py-10">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="thead-table-data font-semibold font-normal font-24 text-center py-2 border-0 w-50">Date</td>
                                        <td className="thead-table-datacount font-semibold font-normal font-24 text-center py-2 border-0">{isPAVS ? 'Mins' : 'Score'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </thead>
                    <tbody>
                        {userTestScoreList.length && scoreConcat.length && userTestScoreList.map((item, key) => {
                            let scoreEnteredDate, scoreEnteredDateGraph;
                            scoreEnteredDate = moment(item.createdAt).format('Do MMM YYYY');
                            // scoreEnteredDateGraph = moment(item.createdAt).format('DD/MM/YYYY');
                            // if (this.state.testDate && !this.state.testDate.includes(scoreEnteredDateGraph)) {
                            //     this.state.testScore.push(item.testScore);
                            //     this.state.testDate.push(scoreEnteredDateGraph);
                            // }
                            let highestTestScore = this.getHighestTestScore(scoreTableData);
                            let highestTestScoreObjects = scoreTableData.filter(obj => parseInt(obj.testScore) == highestTestScore);
                            let recentHighestScoreDate = this.getMostRecentDate(highestTestScoreObjects);
                            let createdAtTime = new Date(item.createdAt)
                            this.formatTestGraphData();
                            this.checkTestTakenToday(userTestScoreList);
                            return (
                                <tr>
                                    <td colspan="2" className="tbody-row border-0">
                                        <table className="w-100">
                                            <tbody>
                                                <tr>
                                                    <td className="tbody-table-data font-semibold font-normal font-18 text-center text-blue w-50 border-0">{scoreEnteredDate}</td>
                                                    <td className="tbody-table-datacount font-semibold font-normal font-18 text-center text-blue w-50 border-0">{scoreConcat[key]}
                                                        {
                                                            (highestTestScore == item.testScore && createdAtTime.getTime() == recentHighestScoreDate.getTime()) ? <img src="/images/trophy.png" alt="beam" className="trophy-size w-50" /> : ''
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
                {!this.props.userTestScoreList.length && <div className="text-center  font-normal font-18">No test data found.</div>}</>
        )
    }


    renderChartScore = (isMobile = false, graphData, graphDate, isPAVS) => {
        const data = {
            labels: graphDate,
            datasets: [
                {
                    label: "My First dataset",
                    backgroundColor: "rgba(220, 220, 220, 0.2)",
                    borderColor: "rgb(0,115,194,1)",
                    pointBackgroundColor: "rgb(0,115,194,1)",
                    pointBorderColor: "rgb(0,115,194,1)",
                    pointRadius: 10,
                    data: graphData,
                }
            ],
        }
        let yAxisStepSize = 10;

        if (isPAVS) {
            yAxisStepSize = 25;
            let recommendedLineData;

            if (data.labels.length > 1) {
                recommendedLineData = Array.from({ length: data.labels.length }, () => '150');
            } else {
                recommendedLineData = Array.from({ length: data.labels.length + 1 }, () => '150');
                data.labels.push('');
            }
            data.datasets.push({
                label: "For recommended line",
                borderColor: "rgb(255 102 51)",
                pointRadius: 0,
                data: recommendedLineData,
                fill: false,
                borderDash: [10],
            })
        }
        return (
            <>
                {graphData?.length > 0 && <div className="position-relative">
                    <h4 className="score-title darkblue-text font-semibold font-normal font-28 m-b-10">Score</h4>
                    <Line
                        type="line"
                        className="c-line-chart"
                        redraw={true}
                        drawActiveElementsOnTop={true}
                        options={
                            {
                                // hover: null,
                                events: null,
                                tooltips: {
                                    backgroundColor: 'red',
                                    filter: function (tooltipItem) {
                                        return tooltipItem.datasetIndex === 2;
                                    }
                                },
                                plugins: {   
                                    datalabels: { 
                                        align: 'end',
                                        color: 'rgb(255, 102, 51, 1)',
                                        font: {
                                            size: '15px',
                                            weight: 200,
                                        },
                                        formatter: function(value, context) {
                                            if (context.dataset.label == 'For recommended line' && context.dataIndex == 0 && value == 150) {
                                                return '                             Recommended';
                                            } else {
                                                return '';
                                            }
                                        }
                                      },        
                                    legend: {
                                        display: false,
                                    },
                                    
                                  },
                                scales: {

                                    x: {
                                        border: {
                                            color: '#0073c2',
                                            tickColor: '#0073c2',
                                            width: 2,
                                        },
                                        grid: {
                                            display: false,
                                            color: "#0073c2",
                                            borderWidth: 3,
                                            borderColor: "#0073c2"
                                        },
                                        ticks: {
                                            autoSkip: false,
                                            maxRotation: -90,
                                            minRotation: 50,
                                            font: {
                                                size: isMobile ? 12 : 16,
                                                weight: 'bold',
                                                family: 'Montserrat',
                                            },
                                            color: '#0073c2'
                                        }
                                    },
                                    y: {
                                        /* suggestedMin: 10,
                                        suggestedMax: 100,  */
                                        display: true,
                                        border: {
                                            display: true,
                                            width: 2,
                                            color: '#0073c2',
                                            tickColor: '#0073c2'
                                        },
                                        grid: {
                                            display: true,
                                            color: (context) => {
                                                if (context.tick.value == 0) {
                                                    return "#0073c2"
                                                } else {
                                                    return "#ffff"
                                                }
                                            },
                                            borderWidth: 3,
                                            borderColor: "#0073c2"

                                        },
                                        title: {
                                            display: false,
                                            text: 'Score'
                                        }, 
                                        ticks: {
                                            autoSkip: true,
                                            stepSize: yAxisStepSize,
                                            font: {
                                                size: isMobile ? 12 : 16,
                                                weight: 'bold',
                                                family: 'Montserrat',
                                            },
                                            color: '#0073c2',
                                            padding: 25,
                                        },
                                        beginAtZero: true,
                                    },
                                },
                            }}
                            data={data}
                    />
                    <img src={"/images/bird.png"} alt="beam" className="bird-size" />
                </div>}
            </>
        )
    }

    renderWebViewContent = (testDataTable, testScoreGraph, testDateGraph, testId = false) => {
        return (
            <section className="progress-page">
                <div className="container-fluid mx-auto w-90 p-0">
                    <div className="row">
                        <div className="filter-section col-md-12 m-t-15 m-b-30 p-0">
                            {
                                testId && <h4 class="m-b-30 darkblue-text font-semibold font-normal font-28">Weekly physical activity <span className="font-16">(the number of minutes a physical activity you have told as you are completing weekly)</span></h4>
                            }
                            <div className="row">
                                <div className="col-md-4 col-sm-4 stand-table">
                                <h4 className="m-b-30 darkblue-text font-semibold font-normal font-28">{(this.props.fitness_test_form && Object.keys(this.props.fitness_test_form).length) && !testId ? this.props.fitness_test_form.name
                                        : ""}</h4>
                                    {this.renderScoreTable(testDataTable, testId)}
                                </div>
                                <div className="col-md-7 col-sm-7">
                                    {this.renderChartScore(false, testScoreGraph, testDateGraph, testId)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }

    renderMobileViewContent = (testDataTable, testScoreGraph, testDateGraph, testId = false) => {
        return (
            <section className="progress-page">
                <div className="container-fluid mx-auto w-90 p-0">
                {
                                testId && <h4 class="m-b-30 darkblue-text font-semibold font-normal font-28">Weekly physical activity <span className="font-16">(the number of minutes a physical activity you have told as you are completing weekly)</span></h4>
                            }
                    <div className="row">
                        <div className="filter-section col-md-12 m-t-15 m-b-30 p-0">
                            <div className="row">
                            <h4 className="m-b-30 darkblue-text font-semibold font-normal font-28">{(this.props.fitness_test_form && Object.keys(this.props.fitness_test_form).length) && !testId ? this.props.fitness_test_form.name
                                        : ""}</h4>
                                <div className="col-md-7 col-sm-7">
                                    {this.renderChartScore(false, testScoreGraph, testDateGraph, testId)}
                                </div>
                                <div className="col-md-4 col-sm-4 stand-table">
                                    {this.renderScoreTable(testDataTable, testId)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }

    //main render
    render() {
        const testDataTableSitToStand = this.props.userTestScoreList?.filter((item) => item.formId == 2);
        const testDataTablePAVS = this.props.userTestScoreList?.filter((item) => item.formId == 4);
        return (
          <div className="">
            <Helmet>
              <title>{constand.DASHBOARD_HISTORY_TITLE}{constand.BEAM}</title>
              <meta property="og:title" content={constand.DASHBOARD_HISTORY_TITLE + constand.BEAM} />
              <meta property="og:description" content={constand.DASHBOARD_HISTORY_DESC} />
              <meta property="og:image" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE} />
              <meta property="og:url" content={window.location.href} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta property="og:site_name" content="Beam" />
              <meta name="twitter:image:alt" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE_ALT} />
            </Helmet>

            <div className="text-center w-100 test-btn">
              {this.state.Loading && <AnimateLoaderComponent />}
            </div>
            <div class="container-fluid mx-auto w-90 p-0">
                <div class="row">
                    <div class="filter-section col-md-12 m-t-15 p-0 take-test-btn">
                        <div class="row">
                            <button
                            disabled={this.isTestTakenToday}
                            className={
                                "btn btn-darkblue m-b-30 darkblue-text font-semibold font-normal font-28 float-right test-button-padding take-test-tooltip"
                            }
                            onClick={() => this.takeTest()}
                            >
                            {" "}
                            Take the test
                            {this.isTestTakenToday && (
                                <div class="test-tooltip-text font-bold">
                                <span>Multiple tests can't be taken on the same day</span>
                                </div>
                            )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* web view content */}
            <div className="d-none d-sm-none d-md-block d-lg-block">
              {this.props.fitness_test_form?.id == 2 && (
                <>
                  {this.renderWebViewContent(testDataTableSitToStand, this.state.testGraphScoreSitToStand, this.state.testGraphDateSitToStand)}
                  {this.renderWebViewContent(testDataTablePAVS, this.state.testGraphScorePAVS, this.state.testGraphDatePAVS, 2)}
                </>
              )}    
              {this.props.fitness_test_form?.id != 2 && (
                <>{this.renderWebViewContent(this.props.userTestScoreList, this.state.testGraphScore, this.state.testGraphDate)}</>
              )}
            </div>

            {/* Mobile view content */}
            <div className="d-block d-sm-block d-md-none d-lg-none">
            {this.props.fitness_test_form?.id == 2 && (
                <>
                {this.renderMobileViewContent(testDataTableSitToStand, this.state.testGraphScoreSitToStand, this.state.testGraphDateSitToStand)}
                {this.renderMobileViewContent(testDataTablePAVS, this.state.testGraphScorePAVS, this.state.testGraphDatePAVS, 2)}
                </>
              )}
              {this.props.fitness_test_form?.id != 2 && (
                <>{this.renderMobileViewContent(this.props.userTestScoreList, this.state.testGraphScore, this.state.testGraphDate)}</>
              )}
            </div>
          </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        all_activity_list: state.dashboard.all_activity_list,
        userTestScoreList: state.dashboard.userTestScoreList,
        fitness_test_form: state.dashboard.fitness_test_form,
        is_test_taken_today: state.header.is_test_taken_today,
    };
};

const mapDispatchToProps = {
    fitnessTestModalOpen, fitnessTestForm, isTakeTestClicked, getUserFitnessTestScore
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressComponent);
