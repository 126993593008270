import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  fetchAdmin,
  fetchConversationId,
  getConversationById,
  sendMessage,
  startConversation,
} from "../../../actions/ProgrammeMessagesAction";
import { useDispatch, useSelector } from "react-redux";
import DynamicDashboardHeader from "../../dynamicDashboard/dynamicDashboarHeader/dynamicDashboardHeader";
import { MessageBox } from "./MessageBox";
import SendMessageForm from "./SendMessageForm";
import SearchIcon from "../../../assets/images/messages/search.svg";
import LockIcon from "../../../assets/images/messages/lock.svg";
import * as constand from "../../../constant";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const ConversationList = () => {
  const user = useSelector((state) => state.header.master_user_details);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const intervalRef = useRef(null);

  const [conversationId, setConversationId] = useState(null);
  const [adminDetails, setAdminDetails] = useState(null);
  const [intecomId, setIntecomId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [isUserActivated, setIsUserActivated] = useState(true);
  const [filteredConversation, setFilteredConversation] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleConv = async (id) => {
    try {
      if (!id) return null;
      const resp = await dispatch(getConversationById(id));
      setSelectedConversation(resp.conversation_parts.conversation_parts);
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const getConversations = async () => {
    try {
      const admin = await dispatch(fetchAdmin());
      setAdminDetails(admin.data);
      const result = await dispatch(fetchConversationId());

      const { success, conversationId, intecomId } = result;
      if (!success) {
        setConversationId(null);
        setLoading(false);
        return;
      }
      setIntecomId(intecomId);
      setConversationId(conversationId);
      await handleConv(conversationId);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setConversationId(null);    
      setLoading(false);
    }
  };

  useEffect(() => {
    getConversations();

    intervalRef.current = setInterval(async () => {
      handleConv(conversationId);
    }, constand.MESSAGES_CALL_TIME);

    return () => clearInterval(intervalRef.current);
  }, [conversationId]);

  const handleSendMessage = async (text) => {
    if (!text.trim()) return;
    try {
      if (conversationId) {
        await dispatch(
          sendMessage(conversationId, text, user.email, intecomId)
        );
      } else {
        handleStartConversation(text);
      }
      handleConv(conversationId);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleStartConversation = async (newMessage) => {
    if (!newMessage.trim()) return;
    try {
      await dispatch(
        startConversation(newMessage, user.email, user.name, user.id)
      );
      setTimeout(() => {
        getConversations();
      }, 1000);
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation?.length, scrollToBottom]);

  // const handleSearch = (e) => {
  //   const filtered = selectedConversation.filter((part) =>
  //     part.body?.toLowerCase().includes(e.toLowerCase())
  //   );
  //   setFilteredConversation(filtered);
  // };
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  useEffect(() => {
    const handleSearch = (query) => {
      if (!query) return  setFilteredConversation(null);
      const filtered = selectedConversation.filter((part) =>
        part.body?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredConversation(filtered);
    };

    handleSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  return (
    <>
      <DynamicDashboardHeader />
      {loading ? (
        <div className="text-center w-100">
          <AnimateLoaderComponent />
        </div>
      ) : (
        <div className="chat-app font-qregular">
          <div className="chat-container">
            <div className="row header">
              <div className="col-md-1 col-1 px-0">
                <img
                  className="profile-pic"
                  src={
                    adminDetails?.avatar?.image_url ||
                    "/images/dashboard/Avatar.png"
                  }
                  alt="Dr. Sharlene Greenwood"
                />
              </div>
              <div className="col-md-10 col-9 px-0">
                <div className="header-text">
                  <h1 className="name">
                    {adminDetails?.name || "Dr. Sharlene Greenwood"}
                  </h1>
                  <div className="status">
                    {adminDetails?.job_title ||
                      "Physiologist - available between 9am-5pm"}
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-2">
                <img
                  src={SearchIcon}
                  alt="search"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFilteredConversation(null);
                    setShowSearchPopup(!showSearchPopup);
                  }}
                />
              </div>
            </div>
            <div className="chatheader-borderbottom"></div>

            <div className="messages-list">
              {showSearchPopup && (
                <div className="sticky-input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              )}
              {(filteredConversation || selectedConversation)?.map((part) => {
                if (!part.body) return null;
                return (
                  <MessageBox
                    key={part.id}
                    text={part.body}
                    sender={part.author.type === "user"}
                    avatar={adminDetails?.avatar}
                    name={part.author.name}
                    {...part}
                  />
                );
              })}
              <div ref={messagesEndRef} />
            </div>
            <SendMessageForm
              isUserActivated={isUserActivated}
              onSendMessage={handleSendMessage}
              showSearchPopup={false}
            />

            <div className="row justify-content-center bottomText">
              <div className="col-md-1 col-1 px-0 lock-image">
                <img src={LockIcon} alt="search" />
              </div>
              <div className="col-md-11 col-11 px-0">
                <p className="pr-3" style={{ color: "#8b8c92" }}>
                  All your conversations are encrypted. We will answer your
                  message as soon as we can but response times may differ os we
                  are only available during standard UK office hours, If gou
                  need urgent support Contact the appropriate or medical
                  service.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConversationList;
