import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import SurveyCard from "./SurveyCard";
import DynamicDashboardHeader from "../../dynamicDashboard/dynamicDashboarHeader/dynamicDashboardHeader";
import { getSession, getUserSession } from "../../../actions";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";

function SurveyComponent() {
  let history = useHistory();
  const { programId, sessionId, condition } = useParams();

  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sessionContent, setSessionContent] = useState({});
  async function getSessionStep(withLoading = true) {
    if (withLoading) {
      setLoading(true);
    }

    try {
      const userSession = await dispatch(getUserSession({
        sessionId,
      }));

      if (!userSession.isUserSession) {
        history.push(`/programmes/dashboard/${condition}/${programId}`);
      }
      const response = await dispatch(
        getSession({
          programId,
          sessionId,
        })
      );
      setSessionContent(response);
    } catch (error) {
      console.error("Failed to fetch session step:", error);
    } finally {
      if (withLoading) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getSessionStep();
  }, [sessionId]);
  
  if (Object.keys(sessionContent).length == 0 && loading)
    return (
      <div className="text-center w-100">
        <DynamicDashboardHeader />
        <AnimateLoaderComponent />
      </div>
    );
  const { title, description, sessionOrder } = sessionContent;

  const handleChangeSession = (type, page) => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    console.log("+++++++ INVOKED", type, page);
    if (type === "next") {
      setCurrentStep(page + 1);
    } else {
      setCurrentStep(page - 1);
    }
  };
  return (
    <>
      <DynamicDashboardHeader />
      <SurveyCard
        sessionStep={sessionContent?.sessionSteps[currentStep - 1]}
        title={title}
        description={description}
        sessionOrder={sessionOrder}
        currentSession={currentStep}
        sessionLength={sessionContent?.sessionSteps?.length}
        getSessionStep={getSessionStep}
        handleChangeSession={handleChangeSession}
      />
    </>
  );
}

export default SurveyComponent;
