import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { goalsModalOpen, createUpdateGoal } from "../../actions/GoalActions";
import { toast } from "react-toastify";
import GoalSlider from "./GoalsSlider";

/**
 * GoalsModal is a React functional component that renders a modal for creating or editing goals.
 * It uses Redux for state management and react-responsive-modal for the modal functionality.
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.fetchGoalList - A function to fetch the list of goals.
 * @param {Number} props.programId - The ID of the program associated with the goals.
 *
 * @returns {JSX.Element} - The JSX element for the GoalsModal component.
 */
export const GoalsModal = ({ programId, onClose }) => {
  const dispatch = useDispatch();

  const is_goal_modal_open = useSelector(
    (state) => state.goals.is_goal_modal_open
  );

  const goalToEdit = useSelector((state) => state.goals.goal);
  const [disabled, setDisabled] = useState(false)
  const [goal, setGoal] = useState(goalToEdit?.goal || "");
  const [actions, setActions] = useState(goalToEdit?.actions || "");
  const [location, setLocation] = useState(goalToEdit?.location || "");
  const [withWhom, setWithWhom] = useState(goalToEdit?.withWhom || "");
  const [reviewDate, setReviewDate] = useState(goalToEdit?.reviewDate || "");
  const [confidenceLevel, setConfidenceLevel] = useState(
    goalToEdit?.confidenceLevel || 0
  );
  const [importanceLevel, setImportanceLevel] = useState(
    goalToEdit?.importanceLevel || 0
  );

  useEffect(() => {
    if (goalToEdit) {
      setGoal(goalToEdit.goal || "");
      setActions(goalToEdit.actions || "");
      setLocation(goalToEdit.location || "");
      setWithWhom(goalToEdit.withWhom || "");
      setReviewDate(goalToEdit.reviewDate || "");
      setConfidenceLevel(goalToEdit.confidenceLevel || 0);
      setImportanceLevel(goalToEdit.importanceLevel || 0);
    }
  }, [goalToEdit]);

  const clearForm = () => {
    setGoal("");
    setActions("");
    setLocation("");
    setWithWhom("");
    setReviewDate("");
    setConfidenceLevel(0);
    setImportanceLevel(0);
  };

  const closeModel = () => {
    clearForm();
    dispatch(goalsModalOpen(false));
  };

  const handleSubmit = async (e) => {
    // const scrollPosition = window.scrollY;
    try {
      e.preventDefault();
      if (!goal) throw new Error("You must enter a goal name");
      if (!reviewDate) throw new Error("You must enter a review date");
      setDisabled(true);
      const newGoal = {
        id: goalToEdit?.id,
        goal,
        actions,
        location,
        withWhom,
        reviewDate,
        confidenceLevel,
        importanceLevel,
        programID: goalToEdit?.programID || programId,
        userId: goalToEdit?.userId,
      };
      const response = await dispatch(createUpdateGoal(newGoal));
      if (!response.success) throw new Error(response.message);
      toast.success(response.message);
      clearForm();
      setDisabled(false);
      // onClose();
      closeModel();
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
      console.log(error);
    }
    //  finally {
    //   window.scrollTo(0, scrollPosition);
    // }
  };
  const handleFocus = (e) => {
    e.target.type = "date";
  };

  const handleBlur = (e) => {
    e.target.type = "text";
    if (!e.target.value) {
      e.target.placeholder = "DD/MM/YYYY";
    }
  };
  return (
    <Modal
      classNames={{
        modal: "m-t-50",
      }}
      open={is_goal_modal_open}
      onClose={closeModel}
      center
    >
      <div
        id="goalcustomModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="goalcustomModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-width--custom" role="document">
          <div className="modal-content">
            <div className="modal-header header-styling border-0 bg-white p-0">
              <button
                type="button"
                className="close white-txt"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModel}
              >
                <span aria-hidden="true">
                  <img
                    src="/images/dashboard/close.svg"
                    className="img-fluid"
                    alt="Close Icon"
                  />
                </span>
              </button>
            </div>
            <div
              className="modal-body body-padding--value p-32"
              id="register-box"
            >
              <h5 className="black-txt font-qbold font-20 fw500 mobile-font18 mb-32">
                {goalToEdit ? "Update" : "Create"} a goal
              </h5>
              <form className="create-goal-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="font-qbold font-14 fw500 black-txt mb-1">
                    My goal is
                  </label>
                  <input
                    type="text"
                    className="form-control font-qmedium font-14 fw500"
                    placeholder="e.g. To keep my bones strong"
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-qbold font-14 fw500 black-txt mb-1">
                      What I will do...
                    </label>
                    <input
                      type="text"
                      className="form-control font-qmedium font-14 fw500"
                      placeholder="e.g. Exercise twice a week"
                      value={actions}
                      onChange={(e) => setActions(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-qbold font-14 fw500 black-txt mb-1">
                      Where I will do it...
                    </label>
                    <input
                      type="text"
                      className="form-control font-qmedium font-14 fw500"
                      placeholder="e.g. At home"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-qbold font-14 fw500 black-txt mb-1">
                      With who...
                    </label>
                    <input
                      type="text"
                      className="form-control font-qmedium font-14 fw500"
                      placeholder="e.g. My family"
                      value={withWhom}
                      onChange={(e) => setWithWhom(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-qbold font-14 fw500 black-txt mb-1">
                      Date for review
                    </label>
                    <input
                      type="date"
                      className="form-control font-qmedium font-14 fw500 "
                      value={reviewDate}
                      onChange={(e) => setReviewDate(e.target.value)}
                      // onFocus={handleFocus}
                      // onBlur={handleBlur}
                      // placeholder="DD / MM / YYYY"
                    />
                  </div>
                </div>
                <p className="font-qmedium font-14 fw500 ash-txt mb-3">
                  The following is how confident and how important your goal is
                  to you. Please rate these on the scales.
                </p>
                <div className="form-row slidebar justify-content-center">
                  <div className="form-group col-md-6 mb-32 ">
                    <GoalSlider
                      key="How confidently can I achieve goal"
                      title={"How confidently can I achieve goal"}
                      propsValue={confidenceLevel}
                      handleChange={(e) => setConfidenceLevel(e * 2)}
                    />
                    {confidenceLevel <= 6 && (
                      <p className="font-12 font-qmedium fw500 colorblue mb-0">
                        Try to think about how you can make this goal more
                        achievable
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-6 mb-32 ">
                    <GoalSlider
                      key="How important is this goal to me"
                      title={"How important is this goal to me"}
                      propsValue={importanceLevel}
                      handleChange={(e) => setImportanceLevel(e * 2)}
                    />
                    {importanceLevel <= 6 && (
                      <p className="font-12 font-qmedium fw500 colorblue mb-0">
                        Try to work towards something that is meaningful to you
                      </p>
                    )}
                  </div>
                </div>

                <div className="text-right cancel-save-btn">
                  <button
                    type="button"
                    className="btn mr-3 cancelbtn font-qbold font-16 fw500"
                    onClick={closeModel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={disabled}
                    className="btn savebtn font-qbold font-16 fw500"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GoalsModal;
