import React, { useState, useEffect } from "react";

import Slider from "@mui/material/Slider";

/**
 * A React functional component that renders a customizable range slider.
 *
 * @param {Object} props - The component's props.
 * @parama {Object} props.title - title of the slider
 * @param {number} props.propsValue - The initial value of the slider.
 * @param {function} props.handleChange - A callback function to handle slider value changes.
 *
 * @returns {JSX.Element} - The rendered RangeSlider component.
 */
const GoalSlider = ({ title, propsValue, handleChange }) => {
  const [value, setValue] = useState(1);
  const marks = [
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 4,
      label: "",
    },
    {
      value: 5,
      label: "",
    },
  ];
  function valuetext(value) {
    return `${value}`;
  }
  useEffect(() => {
    setValue(propsValue / 2);
  }, [propsValue]);
  function onChange(params) {
    setValue(params);
    handleChange(params);
  }
  return (
    <>
      <label className="font-qbold font-14 fw500 black-txt mb-1">{title}</label>

      <Slider
        sx={{
          "& .MuiSlider-rail": {
            backgroundColor: "#ddd",
            height: 7,
          },
          "& .MuiSlider-track": {
            backgroundColor: "#007bff",
            height: 7,
          },
          "& .MuiSlider-mark": {
            width: "7px",
            height: "7px",
            borderRadius: "12px",
            color: "#d7d5d8",
          },
          "& .MuiSlider-thumb": {
            border: "#5076b4",
            borderWidth: "thick",
            borderStyle: "solid",
            backgroundColor: "#FFFFFF",
          },
        }}
        track={false}
        aria-labelledby="track-false-slider"
        getAriaValueText={valuetext}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        min={1}
        max={5}
        marks={marks}
      />
      <div className="confidence-labels mb-2">
        <span className="label not-much">Not Much</span>
        <span className="label very-much">Very Much</span>
      </div>
    </>
  );
};

export default GoalSlider;
