import React from 'react';
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { privacyPage } from "../../actions";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { RichText } from 'prismic-reactjs';

class PrivacyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyDetails: [],
      Loading: false
    };
    this.getPrivacy = this.getPrivacy.bind(this);
  }
  componentDidMount() {
    this.getPrivacy();
    window.scrollTo(0, 0)
  }
  getPrivacy() {
    this.setState({ Loading: true })
    this.props.privacyPage().then(response => {
      if (response) {
        var privacyDetails = response.data;
        console.log('privacyDetails',privacyDetails['page-content'])
        this.setState({ privacyDetails: privacyDetails, Loading: false })
      }
    }, error => {

    });

  }
  render() {
    return (
      <section className="privacy_policy">
        <Helmet>
          <title>{constand.PRIVACY_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.PRIVACY_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.PRIVACY_DESC} />
          <meta property="og:image" content={constand.PRIVACY_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.PRIVACY_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="privacy-bg text-center p-t-10 p-b-90" id="privacy">
          <div className="container">
            <h3 className="font-medium font-37 white-txt p-t-30 p-b-100">Privacy Policy</h3>
          </div>
        </div>
        <div class="terms_text">
          <div class="container-fluid mx-auto w-65 privacy-container">
            <div class="clearfix p-t-50 blog_details_container">
              <div class="row">
                <div className="col-md-12">
                  <div className="blog_dyn_section ">
                <RichText className="font-14 font-qregular black-txt" render={this.state.privacyDetails['page-content']} />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center w-100">
          {(this.state.Loading) && (<AnimateLoaderComponent />)}
        </div>
      </section>

    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
  };
};

const mapDispatchToProps = {
  privacyPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyComponent);
