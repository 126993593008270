import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { setCondition, updateUserChallengeTag, loginModelOpen, cautionModalOpen, getAWSImages, getConditionBanner } from "../../actions";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player';
import { Cookies } from "react-cookie-consent";
import CffBanner from "./CffBanner";
import { toast } from "react-toastify";
import { commonService } from "../../_services";
import CautionModal from "../Common/CautionModal";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import CommonForm from './CommonForm';
SwiperCore.use([Autoplay, Navigation]);
class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: 'https://vimeo.com/359842015',
      firstTime: 1,
      homePageLogos: []
    };
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
    this.returnClass = this.conditonClassList.reverse();
    this.swiperRef = React.createRef();
  }

  componentWillReceiveProps() {
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
  }
  componentDidMount() {
    console.log('home-this.props', this.props)
    var beamChallenge = this.props.match ? (this.props.match.params.beamchallenging && this.props.match.params.beamchallenging.includes('keepbeaming-signup') ? 'keepbeaming-signup' : '') : '';
    localStorage.setItem('beamchallenging', beamChallenge)
    if (localStorage.getItem('beamchallenging') && !this.props.is_auth) {
      console.log('test*********')
      this.props.loginModelOpen(true);
    }

    var isRestrictedPage = this.props.location && this.props.location.state ? this.props.location.state.isRestrictedPage : false;
    var isBeamRestrictedPage = this.props.location && this.props.location.state ? this.props.location.state.isBeamRestrictedPage : false;
    var isHiddenDataAccess = this.props.location && this.props.location.state ? this.props.location.state.isHiddenDataAccess : false;

    if (isRestrictedPage) {
      toast.error(constand.ERROR_RESTRICTED_PAGE);
    } else if (isBeamRestrictedPage) {
      toast.error(constand.ERROR_BEAM_RESTRICTED_PAGE);
    } else if (isHiddenDataAccess) {
      //show popup
      this.props.cautionModalOpen(true);
      const { state } = this.props.location;
      const stateCopy = { ...state };
      delete stateCopy.isHiddenDataAccess;
      this.props.history.replace({ state: stateCopy });
    }
    /* console.log('home-componentDidMount', localStorage.getItem('beamchallenging'));
    if (beamChallenge && this.props.is_auth) {
      console.log('home-updateUserChallengeTag')
     // this.props.updateUserChallengeTag();
    } */
    window.scrollTo(0, 0);
    this.getAWSImages();
  }

  getAWSImages = () => {
    let data = { folderName: 'home' }
    this.props.getAWSImages(data).then((res) => {
      if (res && res.data) {
        let awsImgObjects = commonService.getAWSImages(res)
        this.setState({ homePageLogos: awsImgObjects })
      }
    }).catch(e=>{
    });
  }

  conditionalGoto(condition, url) {

    this.props.setCondition(condition);
    let formatCondition = commonService.replaceChar(condition, false);
    Cookies.set("condition", formatCondition);
    this.props.getConditionBanner(commonService.replaceChar(formatCondition, true))

    if (constand.HOME_CONDITION_LIST.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: '/' + formatCondition } };
      this.props.history.push(from);
    } else if (constand.HOME_CF_YOUTH_CONDITION.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: '/cf-youth' } };
      this.props.history.push(from);
    }else if (constand.HOME_KD_YOUTH_CONDITION.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: '/kidney-youth' } };
      this.props.history.push(from);
    } 
     else {
      const { from } = { from: { pathname: url } };
      this.props.history.push(from);
    }
  }

  getColorClass(key) {
    if (this.returnClass.length > 0)
      return this.returnClass[key];
  }

  returnPartnershipUrl = () => {
    var conditions = typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : constand.CONDITION;
    return !(constand.NO_PARTNERSHIP_CONDITIONS.includes(conditions)) ? "/partnerships/" + conditions : "partnerships/" + constand.CONDITION
  }

  handleSlideChange = () => {
    if (this.swiperRef.current) {
      this.swiperRef.current.swiper.autoplay.start();
    }
  }
  render() {
    var currentPath = this.props.history ? this.props.history.location.pathname : '/home';
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
    let closeBannerCookie = Cookies.get('condition') ? 'closeBanner'+commonService.replaceSpaceToChar(Cookies.get('condition').toLowerCase(),''):'';

    return (
      <React.Fragment>
        <Helmet>
          <title>{constand.HOME_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.HOME_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.HOME_DESC} />
          <meta property="og:image" content={constand.HOME_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.HOME_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) || (Cookies.get('closeBannerKD') == 'false' && !this.props.close_banner_kd) ? 'homecontent' : 'homecontent '}>

          <section id="section1" className="section1-bg">
            <div className="container">
              <div className="section-header text-center p-t-30 p-b-30">
                <h3 className="font-37 darkblue-text heading-top1 m-t-20">Movement, education & wellbeing</h3>
                <p className="w-100 subheading-top1 font-21 mx-auto p-t-20 p-b-10 font-medium ">
                  A specialist platform for people with chronic health conditions offering access to progressive programmes, live and
                  on-demand classes, and community support.
                </p>
                <p className="w-80 subheading-topnew font-21 mx-auto p-t-20 p-b-10 font-medium ">
                  A specialist platform for people with chronic health conditions offering access to progressive programmes, live and
                  on-demand classes, and community support.
                </p>
              </div>
            </div>
          </section>

          
          <section id="section2" className="section2-bg p-b-50 p-t-20">
            <h3 class="font-37 purplefont heading-top1 m-t-20 text-center">Which health condition are you interested in?</h3>
            <div className="col-8 offset-2 btnht_set">
              <div className="row justify-content-center">
                {this.props.healthcondition_list.length > 0 &&
                  this.props.healthcondition_list.map((item, key) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <button key={key}
                          className={"text-capitalize btn btn-login w-100 mx-auto font-14 font-weight-bold cond_cystic-fibrosis text-left " + commonService.getConditionColorClass(item.tag) //this.getColorClass(key)
                          }
                          onClick={() => {
                            var url = (authData && (authData.isGroupLeader || authData.isStudyLeader || authData.isStudyInstructor) && constand.SL_MENU.includes('on-demand')) ? '/on-demand/' : '/liveClasses/';
                            if (authData && authData.isStudyUser) {
                              var community = authData.Members.length ? authData.Members[0].Community.community_name : '';
                              url = community ? '/group/feed/' + community + '/' : '/groups/';
                            }
                            this.conditionalGoto(item.tag, url + (commonService.replaceChar(item.tag, false)))
                          }}
                        >
                          {item.tag}{" "}
                        </button>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>
          <section id="section3" className="section3-bg p-t-90 p-b-90">
            <div className="container-fluid mx-auto w-75">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 text-left">
                  <h4 className="m-b-20 font-24 darkblue-text font-book">New to Kidney Beam?</h4>
                  <p className="small-txt font-18 black-txt  font-qregular">
                    Want to know a little more about Kidney Beam? We've got you covered with a simple introduction to our features and pricing.
                  </p>
                  <Link to="/howitworks" className="btn btn-blue font-medium font-14 m-t-20 m-b-20">
                    How Kidney Beam Works
                  </Link>
                </div>
                <div className="col-12 col-md-6 col-lg-6 text-center">
                  <ReactPlayer url={this.state.videoUrl} controls={true} width='100%' height='100%' style={{
                    width: '100%',
                    height: '100%'
                  }} />

                  {/* <figure>
                    <img
                      src="/images/blog_img_04.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure> */}
                </div>
              </div>
            </div>
          </section>
          {this.state.homePageLogos && this.state.homePageLogos.length ? <section id="section4" className="section4-bg work-with-us">
            <div className="container-fluid w-85">
              <div class="partner-title-purple">Who we collaborate with</div>
              <div class="row mt-3 mb-3">
                <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0 m-b-50 slider-scroll-div">
                  <Swiper
                    centeredSlides={true}
                    loop={true}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    containerModifierClass="w-90 "
                    breakpoints={{ 1: { slidesPerView: 1, spaceBetweenSlides: 0 }, 1000: { slidesPerView: 5, spaceBetweenSlides: 50 } }}
                    spaceBetween={20}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    onSlideChange={this.handleSlideChange}
                    ref={this.swiperRef}
                    observeParents={true}
                    observer={true}
                    observeSlideChildren={true}
                    watchSlidesProgress={true}
                    className="mySwiper"
                  >
                    {this.state.homePageLogos.length && this.state.homePageLogos.map((slide, i) => (
                      <>
                        {slide &&
                          <SwiperSlide key={i}>
                            <img src={constand.S3_API_IMAGES + '/' + slide.Key} alt={`Slide ${i}`} className="scrolling-img" />
                          </SwiperSlide>}
                      </>

                    ))}
                    <div className="swiper-buttons">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </section> : null}
          <section id="section5" className="section5-bg home-contact">
            <div class="container-fluid w-80 fullwidth" id="contact-form">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <p class="font-medium take-tour text-abbey m-t-30 m-b-30">Get in touch</p>

                  <p class="font-qmedium text-abbey m-t-30 section-content">Got a question or want to learn more about Kidney Beam? Please get in touch by emailing <a href="mailto:hello@kidneybeam.com">hello@kidneybeam.com</a>.</p>
                  <div class="section-content text-left">
                    <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                      <CommonForm location={this.props.location} from="kidney" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <CautionModal
          title="Content not currently available"
          content="We're sorry but this content is no longer available at this time. Feel free to look around the site and see what else you might like. We're always here to support you so feel free to message our team for other suggestions at <a href='mailto:hello@kidneybeam.com'>hello@kidneybeam.com</a>"
          type='restricted_page_access'
          isConfirmation={false}
          isCloseRequired={false}
          hasButton={false}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    IP_Details: state.accountinfo.ip_data,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    healthcondition_list: state.register.healthcondition_list,
    logged_userData: state.header.logged_userData

  };
};

const mapDispatchToProps = { setCondition, updateUserChallengeTag, loginModelOpen, cautionModalOpen, getAWSImages, getConditionBanner };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
