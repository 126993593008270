import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import { useHistory, useParams } from "react-router-dom";
import { SURVEY_PAGE_ROUTE } from "../../../constant";
import "bootstrap/dist/css/bootstrap.css";
import CustomFieldTemplate from "./SurveyFormCustomField";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateSurvey, setFormData } from "../../../actions";
import SurveyNavigation from "./SessionNavBar";
import { toast } from "react-toastify";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";
const tempschema = {
  "title": "Activity 1: What's the difference between hunger and cravings?",
  "description": "Work through the following, statements and choose whether you think it is due to hunger or cravings.",

  "type": "object",//this is mandatory NO CHANGE NEEDED

  // required field need to specify below
  "required": ["question1", "question2", "question3"],

  "properties": {
    //type string and enum will be radio
    "question1": {
      "type": "string",
      "title": "My appetite is building gradually over the past few hours.",
      "enum": ["Hunger", "Cravings", "Other"],
      "embedurl": [
        "/programmes/dashboard/kidney%20disease/73",
        "/programmes/dashboard/kidney%20disease/73",
      ], // if needed, add embed url, other don't have embed url    
      "correctAnswer": ["Hunger"],
      "correctValidationMessage": "Correct! This describes hunger.",
      "incorrectValidationMessage": "This describes hunger and not cravings, have another go at a different example."
    },

    // type array with items type string is checkbox and also Other will have input field
    "question2": {
      "type": "array", //checkbox
      "title": "My appetite is building gradually over the past few hours",
      "items": {
        "type": "string",
        "enum": ["Option 1", "Option 2", "Option 3", "Other"],
        "embedurl": [
          "/programmes/dashboard/kidney%20disease/73",
          "/programmes/dashboard/kidney%20disease/73",
          "/programmes/dashboard/kidney%20disease/73",
          "/programmes/dashboard/kidney%20disease/73"
        ] // if needed, add embed url  other don't have embed url
      },
      "correctAnswer": ["Option 1", "Option 2"],
      "correctValidationMessage": "Correct! This describes hunger.",
      "incorrectValidationMessage": "This describes hunger and not cravings, have another go at a different example."
    },
    // type array with items type string is image checkbox
    "question3": {
      "type": "string",
      "title": "Select the correct image",
      "items": {
        "type": "string",
        "enum": [
          "/api/img/workout/431-img.png",
          "/api/img/workout/439-img.png"
        ],
        "embedurl": [
          "/programmes/dashboard/kidney%20disease/73",
          "/programmes/dashboard/kidney%20disease/73"
        ] // if needed, add embed url
      }
    },

    // To load images only without input field
    "question7": {
      "type": "null",
      "title": "Borg Rating of Perceived Exertion Scale",
      "src": ["/api/img/workout/431-img.png"]
    },
    // to load text box
    "question5": {
      "type": "string",
      "title": "Fill your feedbacks"
    }
  }
}


export default function SurveyForms({
  sessionOrder,
  SurveySchema,
  formId,
  handleChangeSession,
  currentSession,
  SurveyNavigation,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { condition, programId, sessionId } = useParams();
  const formData = useSelector((state) => state.ProgrammeSurvey.formData);
  const [Loading, setLoading] = useState(false);
  const handleNextPrevious = async (params) => {
    try {
      setLoading(true);
      const data = {
        id: formData[formId].id,
        formId: formId,
        programId,
        sessionId,
        response: JSON.stringify(formData[formId]),
        responseJson: JSON.stringify(formData[formId]),
      };
      const response = await dispatch(createUpdateSurvey(data));
      toast.success(response.message);
      formData[formId] =  { id: response.survey.id, ...formData[formId] }
      dispatch(
        setFormData(formData)
      );
      handleChangeSession("next", currentSession);
      // history.push(
      //   `${SURVEY_PAGE_ROUTE}${condition}/${programId}/${sessionId}?page=${
      //     sessionOrder + 1
      //   }`
      // );
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };
  // console.log(formData);

  const transformErrors = (errors) => {
      return errors
        .filter(
          (error) =>
            error.message != "should be equal to one of the allowed values"
        )
        .map((item) => {
          return { ...item, message: "This field is required" };
        });
  };
  if (Loading)
    return (
      <div className="text-center w-100">
        <AnimateLoaderComponent />
      </div>
    );

  const CustomTitle = ({ id, description }) => {
    return null;
  };
  const uiSchema = {
    "ui:title": (props) => <CustomTitle {...props} />,
    "ui:description": (props) => <CustomTitle {...props} />,
  };
  return (
    <div
      className="col-12 col-md-12 col-lg-12 text-left interactive-activity mb-32"
      style={{ display: "contents" }}
    >
      <p className="font-18 fw600  mb-2 activity-two">{SurveySchema?.title}</p>
      <p className="font-14 fw500 mb-2 activity-one p-0">
        {SurveySchema?.description}
      </p>
      <div
        className="col-12 col-md-12 col-lg-12 text-left interactive-activity mb-32 row"
        style={{ paddingLeft: 15, display: "contents" }}
      >
        <Form
          noHtml5Validate={true}
          safeRenderCompletion={true}
          schema={SurveySchema}
          // schema={tempschema}
          uiSchema={uiSchema}
          formData={formData[formId]}
          onChange={(params) => {
            // console.log(params);
            formData[formId] = params.formData;
            dispatch(setFormData(formData));
          }}
          transformErrors={transformErrors}
          onSubmit={(params) => handleNextPrevious(params)}
          onError={(e) => {
            window.scrollTo({
              behavior: "smooth",
              top: 0,
            });
            console.log(e);
          }}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
        >
          {/* <button
          type="submit"
          className={`completed-button1 font-16 font-semibold fw600 bg-lightblue w-100`}
        >
          Submit
        </button> */}
          {SurveyNavigation}
          {/* <SurveyNavigation sessionOrder={sessionOrder} isSurvey={true} />     */}
        </Form>
      </div>
    </div>
  );
}
