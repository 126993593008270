import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { fetchRecentlyWatched, loginModelOpen, start_loader, stop_loader, joinClass, getForm, getActivityDetails, getAllActivity, clearActivityList } from "../../actions";
import moment from 'moment';
import { commonService } from "../../_services";
import { ImageTag } from "../../tags";
import WorkoutSaveComponent from "../WorkoutsPage/workoutSaveComponent";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import ActivityModal from "./ActivityModal";
var _ = require('lodash');

class ActivityComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            historyList: [],
            condition: this.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            total_levels: [],
            formFields: {},
            activityId: '',
            formData: {},
            allActivity: {},
            showAll: 0,
            limit: constand.CONSTFIVE,
            movement_data: {
                currentWeek: {
                    mins: 0,
                    count: 0
                },
                lastWeek: {
                    mins: 0,
                    count: 0
                },
                all: {
                    mins: 0,
                    count: 0
                }
            },
            filter: '',
            available_filter: {
                activityType: ['On Beam', 'Off Beam']
            },
            selected_filter: {},
            filterNames: [
                "activityType"
            ],
        };
        this.modelClose = this.modelClose.bind(this);
        this.getAllActivityList = this.getAllActivityList.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.getDetails = this.getDetails.bind(this);

        this.fetchLiveClassList = this.fetchLiveClassList.bind(this);
    }


    modelClose() {
        this.setState({ modelState: false, modelData: {}, formData: {}, activityId: '' });
        this.props.clearActivityList();
        this.getAllActivityList();
    }
    /** fetch live class list on page did mount */
    componentDidMount() {
        this.props.clearActivityList();
        this.setState({ condition: this.state.condition }, () => {
            this.props.getForm({ name: 'offlineActivity' }).then(response => {
                console.log('activityresponse-before', (response));
                console.log('activityresponse', JSON.parse(response.data));
                this.setState({ formFields: JSON.parse(response.data) })
            }, error => {

            });
            this.getAllActivityList();
        })
    }
    componentWillReceiveProps(prevProps) {
        if (this.props.params && prevProps.params.condition && this.props.params.condition !== prevProps.params.condition) {
            var condition = prevProps.params.condition.replace(/-/g, " ")
            this.setState({ condition: condition }, () => {
                this.fetchLiveClassList();
            })
        }
    }
    /**
     * fetch class list data
     */
    fetchLiveClassList() {
        this.setState({ Loading: true });
        var condition = this.state.condition;
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition
        };
        this.props.start_loader();
        this.props.fetchRecentlyWatched(dataObj).then(
            response => {
                this.props.stop_loader();

                if (response) {
                    var list = response.list;
                    if (list && list.history) {
                        this.groupListResult(list.history);
                    }
                    this.setState({
                        Loading: false,
                        count: response.count,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    });

                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    Loading: false
                });
            }
        );
    }
    /**
     * get day name
     */
    getDayNameFromDate(date) {
        var dt = moment(date, "YYYY-MM-DD")
        var day = moment(date).format('dddd');
        var monthName = moment(date).format('MMMM');
        var weekDay = moment(date).format('DD');
        weekDay = weekDay < constand.CONSTTEN ? weekDay : weekDay;
        var fromNow = moment(date).fromNow();
        var watched = moment(date).calendar(null, {
            // when the date is closer, specify custom values
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            // when the date is further away, use from-now functionality
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        })
        return watched + " - " + dt.format('dddd') + ' ' + monthName + ' ' + weekDay;
    }

    /**
     * render item list
     */
    renderSubItemList(data, key) {
        return data.map((item, index) => (

            <div className="list-group-item" key={index}>
                <div className="col-md-12 desktop-view-live">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">

                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}> {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                            <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (this.props.params.condition).replace(/ /g, '-')} className="font-16 font-semibold black-txt p-l-5">
                                                {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Discipline:
                                    </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "discipline", this.state.total_tags, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Difficulty:
                                    </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "level", this.state.total_levels, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {item.type != 'Live' &&
                                    <a className="btn btn-purple w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (this.props.params.condition).replace(/ /g, '-')}>Do Again</a>
                                }

                                <span
                                    className="btn btn-default-list-blue clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                {item.type == 'Live' && <span className="btn btn-blue-inverse w-100 btn-white redtext bg-white font-medium" >Live session</span>}

                            </div>
                            {item.type != 'Live' &&
                                <div className="col-md-3 col-lg-3 col-sm-3 btngroup bookmark-bottom--align float-left pl-0 pr-0">
                                    <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                                        <div className="bookmark-left float-right text-right align-self-end">
                                            <b className="m-r-10">
                                                <WorkoutSaveComponent
                                                    className="m-l-10"
                                                    page="recently_watched"
                                                    workoutData={item.Workout}
                                                />
                                            </b>
                                            <WorkoutScheduleComponent
                                                ondemand_data={item.Workout}
                                                location={this.props.location}
                                                history={this.props.history}
                                                pageName="recently_watched"
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* mobile view */}
                <div className="col-md-12 mobile-view-live">
                    <div className="row">
                        <div className="media col-12 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 float-left p-0 ">
                                <div className="col-12  float-left p-0">
                                    <div className="p-0 border-0 float-left w-100 m-t-5 m-b-5">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>{item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                            <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (this.props.params.condition).replace(/ /g, '-')} className="font-16 font-semibold black-txt p-l-5">
                                                {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium col-12 p-0 m-b-10 float-left ">
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Discipline:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "discipline", this.state.total_tags, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Difficulty:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "level", this.state.total_levels, item.WorkoutId
                                            )}
                                        </span>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-12 float-left p-0 text-center">

                            {item.type != 'Live' &&
                                <a className="btn btn-purple w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (this.props.params.condition).replace(/ /g, '-')}>Do Again</a>
                            }
                            <span
                                className="btn btn-default-list-blue clearfix pad_list_btn"
                            >
                                {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                            </span>
                            {item.type == 'Live' && <span
                                className="btn btn-default-list-orange clearfix pad_list_btn"
                            >
                                Live Session
                            </span>}
                        </div>
                        {item.type != 'Live' &&
                            <div className="col-md-3 col-lg-3 col-sm-3 btngroup bookmark-bottom--align float-left pl-0 pr-0">
                                <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                                    <div className="bookmark-left float-right text-right align-self-end">
                                        <b className="m-r-10">
                                            <WorkoutSaveComponent
                                                className="m-l-10"
                                                page="recently_watched"
                                                workoutData={item.Workout}
                                            />
                                        </b>
                                        <WorkoutScheduleComponent
                                            ondemand_data={item.Workout}
                                            location={this.props.location}
                                            history={this.props.history}
                                            pageName="recently_watched"
                                        />

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        ));
    }
    /**
     * render live class list
     */
    renderListView() {
        return this.state.historyList.map((item, index) => (
            <div key={index}>
                <p className="section_header font-semibold">
                    {commonService.getDayNameFromDate(item[0].scheduledFor)}
                </p>
                {this.renderSubItemList(item, index)}
            </div>
        ));
    }
    /**
   * render start now or go to class
   */
    renderSchedule(item) {

        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn-purple pad_list_btn clearfix"> {item.reference} </a>
            );
        }
    }
    /**
     * render load more option
     */
    renderLoadMore() {
        if (this.state.historyList && this.state.count > this.state.historyList.length) {
            return (
                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                    <span
                        onClick={() => { this.incrementShowmoreCnt() }}
                        className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                    >
                        Show more recently watched
                    </span>
                </div>
            );
        }
    }
    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {

        let classList = [];
        let existingData = this.state.historyList;
        let newData = originalList;
        var helper = {};
        let results = [];
        newData.map(function (item, key) {
            if (existingData && existingData.length > 0 && newData && item && moment(existingData[existingData.length - 1][0].scheduledFor).format("DD/MM/YYYY") === moment(item.scheduledFor).format("DD/MM/YYYY")) {
                existingData[existingData.length - 1].push(item);
                classList = existingData;
            } else {
                var key = item.scheduledFor;
                if (!helper[key]) {
                    helper[key] = [];
                    helper[key].push(item)
                    results.push(helper[key]);
                }
                else {
                    helper[key].push(item)
                }
                classList = [...existingData, ...results];
            }
        });
        this.setState({ historyList: classList })
    }
    /**
     * renderListComponent
     */
    renderListComponent() {
        return (
            <div id="products" className="list_section float-left w-100 p-t-20 p-b-20">
                <div className="container">
                    {this.renderListView()}
                </div>
                {this.renderLoadMore()}
            </div>
        );
    }

    getAllActivityList() {
        var dataObj = {
            "offset": this.state.offset,
            "limit": this.state.limit,
            "showAll": this.state.showAll,
            "filter": commonService.condition_implode(this.state.filter)
        };

        this.props.getAllActivity(dataObj).then(response => {
            this.setState({
                allActivity: this.props.all_activity_list, //response.data.list,
                movement_data: response.data.movementData,
                count: response.data.count,
                Loading: false,
                selected_filter: response.data.selected_filter
            })
        }, error => {
            this.setState({
                Loading: false
            })
        })
    }
    /**
    * render list
    */
    renderListData() {
        return (
            <div className="activityComponent">
                {/* this.renderListComponent() */}
                {this.renderDesktopViewActivity()}
                {this.renderMobileViewActivity()}
            </div>
        );
    }

    getDetails(id) {
        this.setState({ activityId: id });
        this.props.getActivityDetails(id).then(response => {
            var data = response.data;
            console.log('activityDetails', response);
            var dateAlone = moment(data.date).format('DD-MM-YYYY');
            var activityScheduled = moment.utc(dateAlone + ' ' + data.time, "DD.MM.YYYY HH:mm").toDate();
            var timeFormat = moment(activityScheduled).format("h:mm A");
            var dateFormat = new Date(activityScheduled);
            console.log('tiemFormat', timeFormat)
            console.log('dateFormat', dateFormat)
            data.distance = parseInt(data.distance);
            data.date = dateFormat.toString();
            data.time = data.time == null ? "" : timeFormat.toString();
            this.setState({ formData: data, modelState: true })
        })
    }

    loadMore(isShowAll) {
        if (isShowAll) {
            this.props.clearActivityList();
            var lastpageCount = this.state.count;
            this.setState(
                {
                    Loading: true,
                    limit: 0,
                    offset: lastpageCount,
                    showAll: lastpageCount,
                },
                function () {
                    this.getAllActivityList();
                }
            );
        } else {
            var offsetCount = this.state.offset + this.state.limit;
            this.setState(
                {
                    Loading: true,
                    offset: offsetCount,
                    showAll: 0
                },
                function () {
                    this.getAllActivityList();
                }
            );
        }

    }
    filterFunction = (name, value) => {
        console.log('filterFunction', name)
        this.props.clearActivityList();
        var temp = { ...this.state.selected_filter };
        var removeFilter = false;
        if (!temp[name]) {
            temp = {
                activityType: []
            };
        }

        var index = temp[name].indexOf(value);
        if (index > -1) {
            temp[name].splice(index, 1);
            removeFilter = true;

        } else {
            temp[name].push(value);
        }
        console.log('tesmp-index', index)
        console.log('tesmp', temp)
        this.setState(
            {
                Loading: true,
                allActivity: [],
                movement_data: [],
                count: 0,
                offset: 0,
                showAll: 0,
                filter: removeFilter ? '' : value,
                selected_filter: temp,
            },
            function () {
                this.getAllActivityList();
            }
        );

    }
    renderSelectedFilter = () => {
        let value = [];
        this.state.filterNames.map((item, key) => {
            if (
                this.state.selected_filter &&
                this.state.selected_filter[item] &&
                this.state.selected_filter[item].length > 0
            ) {
                this.state.selected_filter[item].map((val, index) =>
                    value.push(
                        <div
                            key={"filter_rm_" + index + key}
                            onClick={() => {
                                this.filterFunction(item, val);
                            }}
                        >
                            <span className="pointer"> x </span>
                            <span className="capitalize_text pointer">{commonService.condition_explode(val)}</span>
                        </div>
                    )
                );

            }
        });
        return value;
    }
    renderDesktopViewActivity() {
        const { movement_data } = this.state;
        var me = this;
        var allActivity = _.sortBy(this.state.allActivity, (e) => {
            return e.recentDate
        }).reverse();
        return (
            <div className="desktopview" >
                <div class="row m-0 font-medium mx-auto justify-content-center">
                    <div class="col-lg-9 col-md-12 col-sm-8 col-xs-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <h4 class="mwy_title">This week</h4>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set orange_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.currentWeek.count, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement sessions </h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set orange_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.currentWeek.mins, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement minutes</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <h4 class="mwy_title">Last week</h4>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set yellow_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.lastWeek.count, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement sessions </h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set yellow_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.lastWeek.mins, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement minutes</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <h4 class="mwy_title">All time</h4>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set purple_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.all.count, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement sessions </h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        <div class="circle_set purple_circle">
                                            <span class="blue_count">{commonService.kFormatter(movement_data.all.mins, 9999)}</span>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                            <h5 class="mwy_content">Movement minutes</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row m-0 text-center my-4 pb-2 justify-content-center font-bold">
                    <div className="col-md-12">
                        <button type="button" class="blue_btn" onClick={() => { this.setState({ modelState: true, formData: {}, activityId: '' }) }}>Manually add activity <span class="fa fa-plus"></span></button>
                    </div>
                    <div className="col-md-12 p-0 mt-2">
                        <div className="offset-md-10 col-md-2 col-sm-2 text-right">
                            {!this.state.is_filter_open && !this.state.loader && (
                                <button type="button"
                                    className="btn btn-purple-inverse button-filter close-btn  font-medium font-14 pointer mt--75"
                                    onClick={() => this.setState({ is_filter_open: true })}
                                >
                                    Filter
                                </button>
                            )}
                            {this.state.is_filter_open && (
                                <span
                                    className="btn btn-purple close-btn  font-medium font-14 pointer"
                                    onClick={() => this.setState({ is_filter_open: false })}
                                >
                                    Close
                                </span>
                            )}
                        </div>

                    </div>
                </div>
                <div class="row">
                    {this.state.selected_filter &&
                        this.state.filterNames &&
                        !this.state.is_filter_open && (
                            <div className="font-14 orangefont filter-select w-100 m-t-20 m-b-20 font-book text-center">
                                {this.renderSelectedFilter()}
                            </div>
                        )}
                </div>

                {this.state.is_filter_open &&
                    <div className="col-md-12 filter_grid m-t-50 ">
                        <div className="row">
                            <div className="offset-9 col-md-3 condemand ">
                                <div className="filter_head black-txt small-txt">
                                    {" "}Activity Location
                                </div>
                                {this.state.available_filter.activityType &&
                                    this.state.available_filter.activityType.map(
                                        (item, key) => {
                                            return (
                                                <span
                                                    key={"activitytype_" + key}
                                                    className="font-medium black-txt extra-small-txt pointer clearfix capitalize_text"
                                                    onClick={() =>
                                                        this.filterFunction("activityType", item)
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            this.state.selected_filter.activityType &&
                                                                this.state.selected_filter.activityType.includes(
                                                                    commonService.condition_implode(item)
                                                                )
                                                                ? "orangefont"
                                                                : ""
                                                        }
                                                    >
                                                        {item}
                                                    </p>
                                                </span>
                                            );
                                        }
                                    )}
                            </div>

                        </div>
                    </div>
                }
                <div class="row m-0 margin_tb">
                    <div class="col-lg-12 p-0">
                        <div class="row">
                            {allActivity.length > 0 &&
                                <table class="table_border_full w-100">
                                    <tbody>
                                        {allActivity.map(function (item, index) {
                                            var isBeamClass = (item.data_type == 'beam') ? true : false;
                                            if (!isBeamClass) {
                                                var dateAlone = moment(item.date).format('DD-MM-YYYY');
                                                if (item.time == null) {
                                                    var activityScheduled = moment.utc(dateAlone, "DD.MM.YYYY").toDate();
                                                } else {
                                                    var activityScheduled = moment.utc(dateAlone + ' ' + item.time, "DD.MM.YYYY HH:mm").toDate();
                                                }
                                                console.log('activityScheduled', activityScheduled)
                                            }
                                            return (
                                                <tr>
                                                    <td width="20%">
                                                        <div class="col-lg-12 min_ht bor_rt p-0 d-flex align-items-center">
                                                            <div class="col-lg-12 text-center p-0">
                                                                {isBeamClass &&
                                                                    <img src={constand.WEB_IMAGES + "Kidney-Beam-Logo.png"} alt="beam" class="logo-size" />
                                                                }
                                                                <span class="blue_txt pt-2 font-semibold">{isBeamClass ? (item.Room.type == 'Live' ? "Live class" : "On-demand class") : item.activityType}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width="30%">
                                                        <div class="col-lg-12 min_ht bor_rt p-0 d-flex align-items-center">
                                                            {isBeamClass &&
                                                                <div class="col-lg-12 text-left p-0 font-book">
                                                                    <span class="date_time_span">{moment(item.Room.scheduledFor).format("hh:mm A [on] dddd MMMM D, YYYY")}</span>
                                                                    <h3 class="orange_text mar_tb font-semibold">{item.Room.Workout.title}</h3>
                                                                    <span class="date_time_span">with <span class="bold_txt font-semibold">{item.Room.Workout.Instructor.User.name + " " + item.Room.Workout.Instructor.User.lastName}</span></span>
                                                                    {item.Room.watchPartyWorkoutId &&
                                                                        <span class="date_time_span">Watch Party Workout: <span class="bold_txt font-semibold">{item.Room.WatchPartyWorkout ? item.Room.WatchPartyWorkout.title : ''}</span></span>
                                                                    }
                                                                </div>
                                                                ||
                                                                <div class="col-lg-12 text-left p-0 font-book">
                                                                    <span class="date_time_span">{item.time == null ? moment(activityScheduled).format("dddd MMMM D, YYYY") : moment(activityScheduled).format("hh:mm A [on] dddd MMMM D, YYYY")}</span>
                                                                    <h3 class="orange_text mar_tb font-semibold">{item.title}</h3>

                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td width="50%">
                                                        <div class="col-lg-12 min_ht p-0 d-flex align-items-center font-medium">
                                                            <div class="col-lg-12 col-md-12 text-center p-0">
                                                                <div class="row">
                                                                    <div class="col-lg-4 col-md-4 text-center">
                                                                        <span class="date_time_span text-center fm_500 mb-2">Time</span>
                                                                        <span class="tde_text font-semibold">{isBeamClass ? item.timeSpent : item.duration} mins</span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 text-center">
                                                                        <span class="date_time_span text-center fm_500 mb-2">Distance</span>
                                                                        <span class="tde_text font-semibold">{isBeamClass ? '-' : (item.distance ? item.distance + ' ' + item.distanceUnits : '-')}</span>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-4 text-center">
                                                                        <span class="date_time_span fm_500 text-center mb-2">Exertion</span>
                                                                        <span class="tde_text font-semibold">{isBeamClass ? '-' : item.exertionText}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!isBeamClass &&
                                                                <span class="pos_icons"><button onClick={() => { me.getDetails(item.id) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} alt="" /></button></span>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            }
                        </div>
                        {this.state.allActivity.length > 0 && this.state.allActivity.length < this.state.count &&
                            <div class="row justify-content-center mt-5 mb-5 text-center font-bold">
                                <button class="orange_bg_btn mr-3" onClick={() => { this.loadMore() }}>See more</button>
                                <button class="orange_bord_btn" onClick={() => this.loadMore(true)}>Show all</button>
                            </div>
                        }
                    </div>
                </div>
                {this.state.allActivity.length == 0 &&
                    <div className="">
                        <h3 className="text-center">You're yet to complete a class on Kidney Beam - but you're just a few clicks away from changing that...!</h3>
                    </div>
                }
            </div>
        )
    }
    renderMobileViewActivity() {
        const { movement_data } = this.state;
        var me = this;
        return (
            <div class="mobileview" >
                <div class="tabbable-panel col-lg-12 p-0 p-2">
                    <ul class="nav nav-tabs w-100 justify-content-center m-t-10 font-semibold">
                        <li>
                            <a href="#this_Week" class="active" data-toggle="tab">This week</a>
                        </li>
                        <li>
                            <a href="#last_week" data-toggle="tab">Last week</a>
                        </li>
                        <li>
                            <a href="#all_time" data-toggle="tab">All time</a>
                        </li>
                    </ul>
                    <div class="tab-content col-lg-12 p-0 font-book">
                        <div class="tab-pane active" id="this_Week">
                            <div class="row m-0">
                                <div class="w-50 text-center">
                                    <div class="circle_set orange_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.currentWeek.count, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content ">Movement<br /> sessions </h5>
                                    </div>
                                </div>
                                <div class="w-50 text-center">
                                    <div class="circle_set orange_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.currentWeek.mins, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content">Movement<br /> minutes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="last_week">
                            <div class="row m-0">
                                <div class="w-50 text-center">
                                    <div class="circle_set yellow_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.lastWeek.count, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content">Movement sessions </h5>
                                    </div>
                                </div>
                                <div class="w-50 text-center">
                                    <div class="circle_set yellow_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.lastWeek.mins, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content">Movement minutes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="all_time">
                            <div class="row m-0">
                                <div class="w-50 text-center">
                                    <div class="circle_set purple_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.all.count, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content">Movement sessions </h5>
                                    </div>
                                </div>
                                <div class="w-50 text-center">
                                    <div class="circle_set purple_circle">
                                        <span class="blue_count">{commonService.kFormatter(movement_data.all.mins, 9999)}</span>
                                    </div>
                                    <div class="col-lg-12 p-0 font-medium">
                                        <h5 class="mwy_content">Movement minutes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 text-center my-4 pb-2 justify-content-center font-bold">
                    <button type="button" class="blue_btn" onClick={() => { this.setState({ modelState: true, formData: {}, activityId: '' }) }}>Manually add activity <span class="fa fa-plus"></span></button>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-2 text-right">
                        {!this.state.is_filter_open && !this.state.loader && (
                            <button type="button"
                                className="btn btn-purple-inverse button-filter close-btn  font-medium font-14 pointer"
                                onClick={() => this.setState({ is_filter_open: true })}
                            >
                                Filter
                            </button>
                        )}
                        {this.state.is_filter_open && (
                            <span
                                className="btn btn-purple close-btn  font-medium font-14 pointer"
                                onClick={() => this.setState({ is_filter_open: false })}
                            >
                                Close
                            </span>
                        )}
                    </div>
                </div>

                <div class="row">
                    {this.state.selected_filter &&
                        this.state.filterNames &&
                        !this.state.is_filter_open && (
                            <div className="font-14 orangefont filter-select w-100 m-t-20 m-b-20 font-book text-center">
                                {this.renderSelectedFilter()}
                            </div>
                        )}
                </div>

                {this.state.is_filter_open &&
                    <div className="col-md-12 filter_grid m-t-50 ">
                        <div className="row">
                            <div className=" col-md-3 condemand ">
                                <div className="filter_head black-txt small-txt">
                                    {" "}Activity Location
                                </div>
                                {this.state.available_filter.activityType &&
                                    this.state.available_filter.activityType.map(
                                        (item, key) => {
                                            return (
                                                <span
                                                    key={"activitytype_" + key}
                                                    className="font-medium black-txt extra-small-txt pointer clearfix capitalize_text"
                                                    onClick={() =>
                                                        this.filterFunction("activityType", item)
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            this.state.selected_filter.activityType &&
                                                                this.state.selected_filter.activityType.includes(
                                                                    commonService.condition_implode(item)
                                                                )
                                                                ? "orangefont"
                                                                : ""
                                                        }
                                                    >
                                                        {item}
                                                    </p>
                                                </span>
                                            );
                                        }
                                    )}
                            </div>

                        </div>
                    </div>
                }

                <div class="row m-0 margin_tb">
                    {this.state.allActivity.length && this.state.allActivity.map(function (item, index) {
                        var isBeamClass = (item.data_type == 'beam') ? true : false;
                        if (!isBeamClass) {
                            var dateAlone = moment(item.date).format('DD-MM-YYYY');
                            var activityScheduled = moment.utc(dateAlone + ' ' + item.time, "DD.MM.YYYY HH:mm").toDate();
                        }
                        return (
                            <div class="hr_line row m-0" onClick={() => { me.getDetails(item.id) }}>
                                <div class="col-lg-12 text-center p-0 mt-4 mb-3">
                                    {isBeamClass && <img src={constand.WEB_IMAGES + "Kidney-Beam-Logo.png"} alt="beam" class="logo-size" />}
                                    <span class="blue_txt pt-1 d-inline-block ml-2 font-semibold">{isBeamClass ? (item.Room.type == 'Live' ? "Live class" : "On-demand class") : item.activityType}</span>
                                </div>
                                {isBeamClass &&
                                    <div class="col-lg-12 text-center p-0 mt-2 mb-3 font-book">
                                        <span class="date_time_span d-block text-center">{moment(item.Room.scheduledFor).format("hh:mm A")}</span>
                                        <span class="date_time_span d-block text-center">{moment(item.Room.scheduledFor).format("dddd MMMM D, YYYY")}</span>
                                        <h3 class="orange_text mar_tb text-center font-semibold">{item.Room.Workout.title}</h3>
                                        <span class="date_time_span text-center">with <span class="bold_txt font-semibold">{item.Room.Workout.Instructor.User.name + ' ' + item.Room.Workout.Instructor.User.lastName}</span></span>
                                        {item.Room.watchPartyWorkoutId &&
                                            <span class="date_time_span text-center">Watch Party Workout: <span class="bold_txt font-semibold">{item.Room.WatchPartyWorkout ? item.Room.WatchPartyWorkout.title : ''}</span></span>
                                        }
                                    </div>
                                    ||
                                    <div class="col-lg-12 text-center p-0 mt-2 mb-3 font-book">
                                        <span class="date_time_span d-block text-center">{moment(activityScheduled).format("hh:mm A")}</span>
                                        <span class="date_time_span d-block text-center">{moment(activityScheduled).format("dddd MMMM D, YYYY")}</span>
                                        <h3 class="orange_text mar_tb text-center font-semibold">{item.title}</h3>

                                    </div>
                                }
                                <div class="col-lg-12 p-0 mt-2 mb-3 text-center">
                                    <div class="row m-0 font-medium">
                                        <div class="w-33 text-center">
                                            <span class="date_time_span text-center fm_500 mb-2">Time</span>
                                            <span class="tde_text font-semibold">{commonService.kFormatter(isBeamClass ? item.timeSpent : item.duration)} mins</span>
                                        </div>
                                        <div class="w-33 text-center">
                                            <span class="date_time_span text-center fm_500 mb-2">Distance</span>
                                            <span class="tde_text font-semibold">{isBeamClass ? '-' : (item.distance ? item.distance + ' ' + item.distanceUnits : '-')}</span>
                                        </div>
                                        <div class="w-33 text-center">
                                            <span class="date_time_span fm_500 text-center mb-2">Exertion</span>
                                            <span class="tde_text font-semibold">{isBeamClass ? '-' : item.exertionText}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {this.state.allActivity.length > 0 && this.state.allActivity.length < this.state.count &&
                    <div class="row justify-content-center mt-5 mb-5 text-center font-bold">
                        <button class="orange_bg_btn mb-3" onClick={() => { this.loadMore() }}>See more</button>
                        <button class="orange_bord_btn" onClick={() => this.loadMore(true)}>Show all</button>
                    </div>
                }
            </div>
        )
    }
    //main render
    render() {
        return (
            <div className=""
            >
                <Helmet>
                    <title>{constand.DASHBOARD_HISTORY_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.DASHBOARD_HISTORY_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.DASHBOARD_HISTORY_DESC} />
                    <meta property="og:image" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.DASHBOARD_HISTORY_PAGE_IMAGE_ALT} />
                </Helmet>
                {!this.state.Loading && this.renderListData()}
                <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div>
                {this.state.modelState &&
                    <ActivityModal is_model_open={this.state.modelState} modelData={this.state.formFields} modelClose={this.modelClose} activityId={this.state.activityId} formData={this.state.formData} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        all_activity_list: state.dashboard.all_activity_list,
    };
};

const mapDispatchToProps = {
    fetchRecentlyWatched, loginModelOpen, start_loader, stop_loader, joinClass, getForm, getActivityDetails, getAllActivity, clearActivityList
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityComponent);
