import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import * as constand from "./constant"; 
class ErrorBoundary extends Component {
    state = {
        hasError: false,
        error: null,
        errorInfo: null
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, error, errorInfo });
    } 

    handleClick = () => {
        const { history } = this.props;
        history.push('/home');
        window.location.reload(true);
    }

    render() {
        if (this.state.hasError) {
            // Render fallback UI
            return (
                <>
                    <div className='w-100'>
                        <img src={constand.WEB_IMAGES + "Kidney-Beam-Logo.png"} className={'error-page-beam-logo'} alt="beam" height={150} width={200} />
                    </div>
                    <div className='error-boundary'>

                        <div className='error-bg'></div>
                        <h1>Oops, something went wrong!</h1>
                        <p className='font-18'>{this.state.error.toString()}</p>
                        <Link className='font-18' onClick={this.handleClick}>Go Home.</Link>
                    </div>
                </>

            );
        } else {
            // Render children
            return this.props.children;
        }
    }
}

export default withRouter(ErrorBoundary);