import React, { useState } from "react";
import * as constand from "../../constant";
import DashboardPlayer from "./PlayerComponent";
import { useDispatch } from "react-redux";
import { saveTimeSpent, startVideo } from "../../actions";
import { useParams } from "react-router-dom";
import { ImageTag } from "../../tags";

const VideoContent = ({ videoContent, getSessionStep }) => {
  const dispatch = useDispatch();
  const { programId, sessionId } = useParams();
  const [roomId, setRoomId] = useState();
  const [isPlay, setIsPlay] = useState(false);
  const onCloseModal = async ({ spendTime, showAfterReview }) => {
    setIsPlay(false);

    if (spendTime > 0) {
      const payload = {
        roomId,
        time: spendTime,
      };
      await dispatch(saveTimeSpent(payload));
      getSessionStep(false);
    }
  };

  const playVideo = async () => {
    setIsPlay(true);

    const payload = {
      programId: programId,
      roomId: "0",
      workoutId: videoContent.id,
      sessionId
    };
    const response = await dispatch(startVideo(payload));
    setRoomId(response.attendee.RoomId);
  };
  let imgUrl = constand.WEB_IMAGES + "ondemand-placeholder.png";
  if (videoContent?.videoImg) {
    imgUrl = constand.S3_URL + "/api/img/workout/" + videoContent?.videoImg;
  }

  return (
    <div className="col-12 col-md-12 col-lg-12 text-left interactive-activity mb-32">
      <p className="mb-2 fw500 activity-one">
        {videoContent?.shortDescription}
      </p>
      <figure className="interactive-activity video-container" onClick={playVideo}>
        {/* <img
          src={imgUrl}
          className="img-fluid"
          alt="video exercise"
          onClick={playVideo}
        /> */}
        <ImageTag
          src={imgUrl}
          className="img-fluid img-responsive pointer"
          width="100%"
          thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
        />
         <img class="play-button img-fluid play-img pointer" src={constand.WEB_IMAGES + "b-play-btn.png"} alt="play"></img>
               
        {/* <div className="play-button"></div> */}
      </figure>

      <DashboardPlayer
        videoContent={videoContent}
        isPlay={isPlay}
        onCloseModal={onCloseModal}
      />
    </div>
  );
};

export default VideoContent;
