import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement, 
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WeightLineChart = ({
    weightData
}) => {
  const combinedArray = weightData?.y_axis?.filter(value => value !== null);
  const maxNumber = combinedArray?.length > 0 ? Math.max(...combinedArray) : 0;

  const data = {
    labels: ['', ...weightData?.x_axis],
    datasets: [
      {
        label: false,
        data: [0, ...weightData.y_axis],
        borderColor: 'rgba(68,109,175,1)',
        backgroundColor: 'rgba(68,109,175,1)',
        pointRadius:0,
        spanGaps: true,
        borderWidth: 2,
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false, 
      },
    },
    scales: {
      x: {
          min: 0,
          suggestedMin: 0,
          suggestedMax: 10,
          grid: {
            display: false,
          },
        },
        y: {
          min: 0,
          suggestedMin: 0,  
          suggestedMax: maxNumber,
          border: {
            display: false,
          }, 
        //   ticks: {
        //     stepSize: 10,
        // },
        },
    },
};

  return  (
    <div className='chart-2'>
      <Line data={data} options={options} />
    </div>
  )
};

export default WeightLineChart;
