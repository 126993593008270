import * as constand from '../constant';
import { getData, postData } from '../_helpers/api-helper';
import handleResponse from '../_services/handle.service';

export function fetchAdmin() {
  return (dispatch, getState) => {
    const requestOptions = getData();
    let url = constand.BACKEND_URL + "/api/programmes/messages/fetchAdmin";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error fetching conversations:", error);
        throw error;
      });
  };
}
export function fetchConversationId() {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + '/api/programmes/messages/fetchConversationId';
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("Error fetching conversations:", error);
                throw error;
            });
    };
}

export function sendMessage(conversationId, message, email, intecomId) {
  return (dispatch, getState) => {
    const data = { message, email, intecomId };
    const requestOptions = postData(data);
    let url =
      constand.BACKEND_URL +
      `/api/programmes/messages/conversations/${conversationId}/reply`;
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        throw error;
      });
  };
}

export function startConversation(message, email, name, userId) {
    return (dispatch, getState) => {
        const data = { message, email, name, userId };
        const requestOptions = postData(data);
        let url = constand.BACKEND_URL + '/api/programmes/messages/conversations/create';
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("Error starting a new conversation:", error);
                throw error;
            });
    };
}

export function getConversationById(id) {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + `/api/programmes/messages/conversations/${id}`;
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("Error fetching conversation by ID:", error);
                throw error;
            });
    };
}
