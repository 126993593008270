import React from "react";

const Tab = ({tabSelect, tabSelected}) => {
    return (
        <React.Fragment>
            <ul
                  id="myTab"
                  role="tablist"
                  className="nav nav-tabs nav-pills flex-column flex-sm-row text-center border-0 rounded-nav"
                >
                  {/*Tab one*/}
                  <li className="nav-item flex-sm-fill">
                    <a
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected={tabSelected==1?true:false}
                      className={"nav-link border-0 font-16 fw500 font-qmedium "+(tabSelected==1?"active":"")}
                      onClick={tabSelect(1)}
                    >
                      Update this week’s weight
                    </a>
                  </li>
                  {/*Tab two*/}
                  <li className="nav-item flex-sm-fill">
                    <a
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected={tabSelected==2?true:false}
                      className={"nav-link border-0 font-16 fw500 font-qmedium "+ (tabSelected==2?"active":"")}
                      onClick={tabSelect(2)}
                    >
                      Update this week’s activity
                    </a>
                  </li>
                </ul>
        </React.Fragment>
    )
}

export default Tab;