import React from "react";
import { connect } from "react-redux";
import {
  fetchBlogs,
  setCondition,
  saveBlog,
  loginModelOpen,
  getBlogTags,
  getBlogsTitle,
  conditionModel
} from "../../actions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { ImageTag, TextWrap } from "../../tags";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";
import { commonService } from "../../_services";
import AutoSearch from "../Common/AutoSearch";
import ConditionalModalComponent from "../Common/ConditionalModalComponent";
class BlogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      isSaveLoading: false,
      blogList: [],
      show_more_cnt: constand.BLOG_LIST_CNT,
      search: "",
      list_count: "",
      offset: 1,
      blogCategories: [],
      blogTags: [],
      selectedCategoriesArr: [], // Store checked selected categories here
      selectedMsCategoriesArr: [], // Store checked selected categories here
      selectedCategories: '', // Store selected categories here
      keyword: "",
      total_blogs: 0,
      pageUrl:'/resources/all',
      condition:''
    };
    /* this.props.history.listen((location, action) => {
      this.clearState();
    }); */
    this.lastState = this.props.match.params.type;

    this.clearState = this.clearState.bind(this);
    this.getBlogList = this.getBlogList.bind(this);
    this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);
  }

  componentDidMount() {
    console.log("this.props did mount",this.props.match.params)
    this.props.getBlogTags()
      .then(response => {
        this.lastState = this.props.match.params.type;
        let selectedCategoriesArr=[]
        let updatedCategoriesString=''
        let updatedCategories=[]
        if( this.props.match.params.type!='all'){
          let cateIndex = response.categories.findIndex((item)=>item.tagname==this.props.match.params.type)
          selectedCategoriesArr = this.state.selectedCategoriesArr.slice();
          selectedCategoriesArr[cateIndex] = true;
          updatedCategories = this.state.selectedCategories.split(',').filter((category) => !!category.trim());
          updatedCategories.push(this.props.match.params.type)
          updatedCategoriesString = updatedCategories.join(',');
        }
        let condition = this.props.match.params.condition;
        let selectedMsCategoriesArr=[]
        if(condition){
          console.log("condition",condition)
          let selectedTagIndex=response.tags.findIndex((item)=>item.value.replace(' ','-')==condition.replace(' ','-'))
          console.log("selectedTagIndex",selectedTagIndex)
          let selectedTag=response.tags.find((item)=>item.value.replace(' ','-')==condition.replace(' ','-'))
          console.log("selectedTag",selectedTag)
          if(selectedTag){
          updatedCategories.push(selectedTag.tagname)
          }
          updatedCategoriesString = updatedCategories.join(',');
          selectedMsCategoriesArr[selectedTagIndex] = true;
        }
        console.log("updatedCategoriesString",updatedCategoriesString)
        this.setState({ blogCategories: response.categories , selectedCategoriesArr:selectedCategoriesArr,condition:condition,
          selectedCategories:updatedCategoriesString, blogTags: response.tags,selectedMsCategoriesArr:selectedMsCategoriesArr},function(){
            this.clearStateInit();
          });
          
      })
      
      .catch(error => {
        console.error('Error fetching blog tags:', error);
      });
   
    window.scrollTo(0, 0);
    console.log('blog_titles', this.props.blog_titles)
    if(!Cookies.get('condition')){
      let url=this.props.match.params.type
      this.setState({ pageUrl: '/resources/'+url });
      this.props.conditionModel(true)
    }
    // this.extractValueFromURL();
  }
  componentWillReceiveProps(props) {
    console.log('componentWillReceiveProps',props.match.params)
    if (this.lastState != props.match.params.type) {
      this.lastState = props.match.params.type;
      let selectedCategoriesArr=[]
      let updatedCategoriesString=''
      let updatedCategories=[]
      if( props.match.params.type!='all'){
      let cateIndex = this.state.blogCategories.findIndex((item)=>item.tagname==props.match.params.type)
       selectedCategoriesArr =  props.match.params.type !=='all' ?this.state.selectedCategoriesArr.slice():[];
      selectedCategoriesArr[cateIndex] = true;
      updatedCategories = this.state.selectedCategories.split(',').filter((category) => !!category.trim());
      updatedCategories.push(props.match.params.type)
      updatedCategoriesString = updatedCategories.join(',');
      }
      let condition = this.props.match.params.condition;
      let selectedMsCategoriesArr=[]
      if(condition){
        let selectedTagIndex=this.state.blogTags.findIndex((item)=>item.value.replace(' ','-')==condition.replace(' ','-'))
        let selectedTag=this.state.blogTags.find((item)=>item.value.replace(' ','-')==condition.replace(' ','-'))
        updatedCategories.push(selectedTag.tagname)
        updatedCategoriesString = updatedCategories.join(',');
        selectedMsCategoriesArr[selectedTagIndex] = true;
      }
      console.log("updatedCategoriesString",updatedCategoriesString)
      this.setState(
        {
          typeFilter: props.match.params.type,
          selectedCategoriesArr:selectedCategoriesArr,
          selectedCategories:updatedCategoriesString,
          selectedMsCategoriesArr:selectedMsCategoriesArr
        },
        function () {
          this.clearStateValues();
        }
      );
    }
  }
 
  clearStateValues() {
    this.setState(
      {
        blogList: [],
        show_more_cnt: constand.BLOG_LIST_CNT,
        search: "",
        list_count: 0,
        offset: 1,
        keyword: "",
        search: "",
        // selectedCategories: '',
        // selectedMsCategoriesArr: [],
        // selectedCategoriesArr: [],
        total_blogs: 0,
      },
      function () {
        this.getBlogList();
      }
    );
  }
  clearStateInit(reset=false) {
    this.setState(
      {
        blogList: [],
        show_more_cnt: constand.BLOG_LIST_CNT,
        search: "",
        list_count: 0,
        offset: 1,
        keyword: "",
        search: "",
        total_blogs: 0,
      },
      function () {
        if(reset){
          this.props.history.push("/resources/all");
        }
        console.log("statecatego",this.state.selectedCategories)
        this.getBlogList();
      }
    );
  }
  clearState(reset=false) {
    let updatedCategories=[]
    let updatedCategoriesString=''
    let selectedMsCategoriesArr=[]
    if(this.state.condition && reset){
      let selectedTagIndex=this.state.blogTags.findIndex((item)=>item.value.replace(' ','-')==this.state.condition.replace(' ','-'))
      let selectedTag=this.state.blogTags.find((item)=>item.value.replace(' ','-')==this.state.condition.replace(' ','-'))
      updatedCategories.push(selectedTag.tagname)
      updatedCategoriesString = updatedCategories.join(',');
      selectedMsCategoriesArr[selectedTagIndex] = true;
    }
    this.setState(
      {
        blogList: [],
        show_more_cnt: constand.BLOG_LIST_CNT,
        search: "",
        list_count: 0,
        offset: 1,
        keyword: "",
        search: "",
        selectedCategories: updatedCategoriesString,
        selectedMsCategoriesArr:selectedMsCategoriesArr,
        selectedCategoriesArr: [],
        total_blogs: 0,
      },
      function () {
        if(reset){
          this.props.history.push("/resources/all/"+this.state.condition);
        }
        this.getBlogList();
      }
    );
  }
  // extractValueFromURL = () => {
  //   const pathname = window.location.pathname;
  //   const parts = pathname.split('/');
  //   const selectedCategories = parts[parts.length - 1];
  //   this.setState({ selectedCategories });
  // };

  handleCheckboxChange = (event, tagname, cattype, tagi) => {
    const isChecked = event.target.checked;

    if(cattype==1){
      let selectedMsCategoriesArr = this.state.selectedMsCategoriesArr.slice();
      selectedMsCategoriesArr[tagi] = isChecked;
      this.setState({selectedMsCategoriesArr});
    }
    else{
      let selectedCategoriesArr = this.state.selectedCategoriesArr.slice();
      selectedCategoriesArr[tagi] = isChecked;
      this.setState({selectedCategoriesArr});
    }
    console.log("selectedCategoriesArr",this.state.selectedCategoriesArr)
    this.setState((prevState) => {
      let updatedCategories = prevState.selectedCategories.split(',').filter((category) => !!category.trim());

      if (isChecked) {
        updatedCategories.push(tagname);
      } else {
        updatedCategories = updatedCategories.filter((category) => category !== tagname);
      }

      const updatedCategoriesString = updatedCategories.join(',');
      console.log("updatedCategoriesString",updatedCategoriesString)
      return { selectedCategories: updatedCategoriesString };
    }, () => {
      // console.log(this.state.selectedCategories);
      this.getBlogList();
    });
  };

  incrementShowmoreCnt() {
    this.setState(
      {
        offset: this.state.offset + 1 // constand.BLOG_LIST_CNT
      },
      function () {
        this.getBlogList();
      }
    );
  }

  getBlogList() {
    var paramType = this.props.match.params.type ? (this.props.match.params.type).replace("'", "") : '';
    var blogCondition = (paramType == 'Help') ? paramType.toLowerCase() + '-' + Cookies.get('condition') : paramType;
    var conditionArr = [];
    conditionArr.push(blogCondition);
    var dataObj = {
      offset: this.state.offset,
      limit: constand.BLOG_LIST_CNT,
      search: this.state.search,
      condition: this.state.selectedCategories ? this.state.selectedCategories : conditionArr
    };
    this.state.blogList = this.state.offset == 1 ? [] : this.state.blogList;
    this.setState({ Loading: true });
    this.props.fetchBlogs(dataObj).then(
      response => {
        if (response) {
          if (response.list) {
            let totalBlogs = this.state.search=="" ? response.list.Total : this.state.total_blogs;
            this.setState({
              Loading: false,
              total_blogs: totalBlogs,
              list_count: response.list.count,
              blogList: response.list.blogs
                ? [...this.state.blogList, ...response.list.blogs]
                : []
            });
          }
        }
      },
      error => {
        this.setState({ Loading: false });
        toast.error(error);
      }
    );
  }

  saveBlog(key) {
    //single method for save and unsave
    if (this.props.is_auth) {
      if (!this.state.isSaveLoading) {
        this.setState({ isSaveLoading: true });
        var item = this.state.blogList[key];
        var dataObj = { blogId: item.uid };
        this.props.saveBlog(dataObj, item.isSaved).then(
          response => {
            if (response) {
              var buffer = [...this.state.blogList];
              buffer[key].isSaved = !buffer[key].isSaved;
              this.setState({ blogList: buffer, isSaveLoading: false });
              toast.success(response.message);
            }
          },
          error => {
            this.setState({ isSaveLoading: false });
            toast.error(error);
          }
        );
      }
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }

  pagingButton() {
    if (
      this.state.blogList.length &&
      this.state.blogList.length < this.state.list_count
    ) {
      return (
        <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
          <button
            disabled={this.state.blogList.length >= this.state.list_count}
            onClick={this.incrementShowmoreCnt}
            className="btn btn-orange m-t-40 m-b-40 font-book"
          >
            Show more blogs
          </button>
        </div>
      );
    } else if (
      !this.state.blogList ||
      this.state.blogList.length === constand.CONSTZERO
    ) {
      return <div className="text-center w-100">
        {(!this.state.Loading) ? ("There are no blogs!") : (<AnimateLoaderComponent />)}
      </div>
      //return <div className="text-center w-100">{(!this.state.Loading) ? ("There are no blogs!") : (<i className="fa fa-spinner fa-spin fa-2x text-info" aria-hidden="true"></i>)}</div>;
    } else {
      return;
    }
  }

  tabRename(name) {
    var returnData = name;
    if (name === 'news') {
      returnData = 'Beam News';
    }
    return returnData;
  }

  renderSearchResource() {
    return (
      <>

      </>
    )
  }
  /*  searchBarEnter = (e) => {
     if (e.key === "Enter") {
       //this.searchFunction();
     }
   } */

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, });
    if (value == '') {
      this.searchFunction();
      this.setState({ isShowSearchWorkout: false, seeMoreWorkoutsList: false })
    }
  }

  searchFunction = () => {
    //this.props.getBlogsTitle(this.state.keyword);
    this.getBlogList();
  }

  /*Search bar inupt handling. */
  onSearchInput = (searchInput, isSelected = false) => {
    console.log('searchInput', searchInput)
    if (isSelected) {
      this.setState({ keyword: searchInput, search: searchInput }, function () {
        this.searchFunction();
      })
    } else {
      this.setState({ keyword: searchInput, search: searchInput })
    }
  }

  render() {
    return (
      <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'blog_page' : 'blog_page m-t-80'}>
        <Helmet>
          <title>{constand.BLOG_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.BLOG_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.BLOG_DESC} />
          <meta property="og:image" content={constand.BLOG_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.BLOG_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="banner_blog p-t-30 p-b-30">
          <div className="container">
            <h3 className="font-37 font-medium black-txt text-center p-t-10 p-b-30 capitalize_text">
              Resources
            </h3>
          </div>
        </div>
        <div className="tab_group m-t-0">
          <div className="container">
            {/* web view */}
            {/* <div className="row">
              <div className=" mx-auto tab_full d-none d-sm-none d-md-block d-lg-block">
                <ul
                  className="nav nav-tabs small justify-content-left mx-auto  tab_ul"
                  role="tablist"
                >
                  {constand.BLOG_TAGS &&
                    constand.BLOG_TAGS.map((item, key) => {
                      var conditionUrl = Cookies.get('condition') ? Cookies.get('condition') : this.props.match.params.type;
                      if (!constand.BLOG_TAGS_EXCEPT.includes(item) && item == commonService.replaceChar(conditionUrl, true)) {
                        return (
                          <li className="nav-item" key={key}>
                            <Link
                              className={
                                (this.props.match.params.type ===
                                  commonService.replaceChar(item, false)
                                  ? "active"
                                  : "") + " nav-link font-semibold"
                              }
                              to={"/resources/" + commonService.replaceChar(item, false)}
                              onClick={() => {
                                this.props.setCondition(item);
                                if(item=='all'){
                                  this.clearState(true)
                                }
                              }}
                            >
                              <span className="font-15 font-book capitalize_text">
                                {this.tabRename(item)}
                              </span>
                            </Link>
                          </li>
                        );
                      } else if (constand.BLOG_TAGS_EXCEPT.includes(item)) {
                        return (
                          <li className="nav-item" key={key}>
                            <Link
                              className={
                                (this.props.match.params.type ===
                                  commonService.replaceChar(item, false)
                                  ? "active"
                                  : "") + " nav-link font-semibold"
                              }
                              to={"/resources/" + commonService.replaceChar(item, false)}
                              onClick={() => {
                                this.props.setCondition(item);
                              }}
                            >
                              <span className="font-15 font-book capitalize_text">
                                {this.tabRename(item)}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            </div> */}
            {/* mobile view */}
            <div className="dropdown tab-sm-group tab-btn d-block d-sm-block d-md-none d-lg-none">
              <button
                type="button"
                className="btn btn-default font-medium font-14 tab-select dropdown-toggle capitalize_text"
                data-toggle="dropdown"
              > {this.tabRename(commonService.replaceChar(this.props.match.params.type, true))}
                <span className="slt"></span>
              </button>
              <div className="dropdown-menu text-center">
                {constand.BLOG_TAGS &&
                  constand.BLOG_TAGS.map((item, key) => {
                    var conditionUrl = Cookies.get('condition') ? Cookies.get('condition') : this.props.match.params.type;
                    if (!constand.BLOG_TAGS_EXCEPT.includes(item) && item == commonService.replaceChar(conditionUrl, true)) {
                      return (
                        <Link key={key}
                          className={
                            (this.props.match.params.type ===
                              commonService.replaceChar(item, false)
                              ? "active"
                              : "") + " nav-link font-semibold"
                          }
                          to={"/resources/" + commonService.replaceChar(item, false)}
                          onClick={() => {
                            this.props.setCondition(item);
                          }}
                        >
                          <span className="font-15 font-book capitalize_text">
                            {this.tabRename(item)}
                          </span>
                        </Link>
                      );
                    } else if (constand.BLOG_TAGS_EXCEPT.includes(item)) {
                      return (
                        <Link key={key}
                          className={
                            (this.props.match.params.type ===
                              commonService.replaceChar(item, false)
                              ? "active"
                              : "") + " nav-link font-semibold"
                          }
                          to={"/resources/" + commonService.replaceChar(item, false)}
                          onClick={() => {
                            this.props.setCondition(item);
                          }}
                        >
                          <span className="font-15 font-book capitalize_text">
                            {this.tabRename(item)}
                          </span>
                        </Link>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          <div className="tab-content dash_tab  col-md-12 p-0">
            <div className="tab-pane  py-4 active">
              <div className="container mx-auto w-100 p-0 blogpage_tab">
                <div className="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 m-b-20">
                    <div id="blogSearch" class="container mobile-filter">
                      <div class="filter_form-wrapper w-form">
                        <form id="wf-form-filter" name="wf-form-filter" method="" class="form_filter">
                          <div class="filter-column">
                            <div class="filter-block">
                              <label for="name" class="smaller primary marge-bottom font-18 font-semibold">Search</label>
                              <div class="input-group input-icon has-search font-qregular">
                                {/* <input type="text" class="input w-input font-18" placeholder="Enter topic" id="Field-3" /> */}
                                <AutoSearch
                                  theme={ {input: 'form-control autosuggest-input', suggestionsContainer: 'autocomplete-dropdown'}} //https://github.com/markdalgleish/react-themeable
                                  searchSuggestion={this.props.blog_titles}
                                  handleChange={this.handleChange}
                                  searchFunction={this.searchFunction}
                                  searchingWord={this.state.keyword}
                                  onSearchInput={this.onSearchInput}
                                  options={{
                                    "value": "Enter topic",
                                    "valueColour": "#53565a"
                                  }}
                                /*
                                loader={this.state.loader}
                                isMobileView={true}
                                backToSearch={this.backToSearch}
                                */
                                />
                                 <span class="fa fa-search form-control-feedback" onClick={()=>{this.searchFunction()}}></span>
                                {/* <button className="fa fa-search form-control-feedback" onClick={this.searchFunction}></button> */}
                              </div>
                             
                            </div>

                            <div class="filter-block border-top-bottom flex-h spaced">
                              <div class="filter_results-text marge-bottom font-15 font-semibold">Showing <span fs-cmsfilter-element="results-count" class="text-color-orange">{this.state.list_count}</span> results<br />of <span fs-cmsfilter-element="items-count">{this.state.total_blogs}</span> items.</div>
                              <a fs-cmsfilter-element="reset" href="#" class="filter_reset-all font-18  font-semibold" onClick={() => {
                                this.clearState(true);
                              }}>Reset All</a>
                            </div>
                            <div class="filter-block">
                              <label for="name" class="smaller primary marge-bottom less font-18  font-semibold">Categories</label>
                              <div class="container checkbox-contents">
                                <div class="catagories-wrapper w-dyn-list">
                                  <div role="list" class="catagories-list w-dyn-items">
                                    {this.state.blogCategories.map((tag, tagi) => (
                                      <div key={tag.tagname} role="listitem" class="catagory-item w-dyn-item">
                                        <label class="w-checkbox checkbox_field">
                                          <input type="checkbox" id={tag.tagname} name="checkbox" checked={this.state.selectedCategoriesArr[tagi]} class="checkbox_input" onChange={(event) => this.handleCheckboxChange(event, tag.tagname, 0, tagi)} />
                                          <span class="checkbox_label w-form-label black-txt font-16 font-qregular capitalize_text font-qregular" for="checkbox">{tag.value}</span>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-block">
                              <label for="name" class="smaller primary marge-bottom  font-18  font-semibold">What's popular</label>
                              <div class="container">
                                <ul class="ks-cboxtags">
                                  {this.state.blogTags.map((tag,tagi) => (
                                    <li key={tag.tagname}>
                                      <input type="checkbox" id={tag.tagname} value={tag.tagname} checked={this.state.selectedMsCategoriesArr[tagi]} onChange={(event) => this.handleCheckboxChange(event, tag.tagname, 1, tagi)} />
                                      <label for={tag.tagname} class="black-txt font-16 font-qregular">{tag.value}</label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div   className={
                    "col-lg-8 col-md-8 col-sm-12 m-b-20 equal_cards "+
                                        (this.state.blogList && this.state.blogList.length <3 && this.state.blogList.length >0
                                          ? "min-cards"
                                          : "")
                                      }>
                    {this.state.blogList
                      .filter(e => e.data)
                      .map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="col-lg-6 col-md-6 col-sm-12 m-b-20 float-left"
                          >
                            <div className="card h-100">
                              <Link to={"/blogs/" + item.uid}>
                                <ImageTag
                                  className="card-img-top"
                                  src={
                                    (item.data.thumbnail_img && item.data.thumbnail_img.url)
                                      ? item.data.thumbnail_img.url
                                      : constand.WEB_IMAGES + "no-image.png"
                                  }
                                />
                              </Link>
                              <div className="card-body">
                                <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                  <p className="dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                    {item.tags.toString().replace(",", "/ ")}
                                  </p>
                                  <div className="bookmark-left float-right text-right">
                                    <em
                                      className={
                                        "fa fa-bookmark-o pointer" +
                                        (item.isSaved === true
                                          ? " orangefont"
                                          : "")
                                      }
                                      onClick={() => this.saveBlog(key)}
                                    ></em>
                                  </div>
                                  <p className="dblog_title font-semibold font-18 black-txt pull-left text-left m-b-5">
                                    <Link to={"/blogs/" + item.uid}>
                                      <TextWrap
                                        text={
                                          item.data.title &&
                                            item.data.title.length > 0
                                            ? item.data.title[0].text
                                            : ""
                                        }
                                        limit={constand.BLOGLIST_TITLE_LIMIT}
                                      />
                                    </Link>
                                  </p>
                                  <p className="dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                    <span> Author:</span><span className="p-l-10">{item.data.author &&
                                      item.data.author.length > 0
                                      ? item.data.author[0].text
                                      : ""}</span>
                                  </p>
                                  <p className="font-qmedium dblog_text m-b-0 pull-left text-left font-16">
                                    <TextWrap
                                      text={
                                        item.data.preview_text &&
                                          item.data.preview_text.length > 0
                                          ? item.data.preview_text[0].text
                                          : ""
                                      }
                                      limit={constand.BLOGLIST_MSG_LIMIT}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {this.pagingButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
       
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    healthcondition_list: state.register.healthcondition_list,
    userData: state.header.logged_userData,
    is_auth: state.auth.is_auth,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    blog_titles: state.blogs.blog_titles,
    init_condition: state.auth.initial_condition,
    is_condition_model_open_by_blog: state.header.is_condition_model_open_by_blog,
  };
};

const mapDispatchToProps = {
  fetchBlogs,
  setCondition,
  saveBlog,
  loginModelOpen,
  getBlogTags,
  getBlogsTitle,
  conditionModel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogComponent);
