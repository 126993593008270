import moment from "moment";

import ReactHtmlParser from "react-html-parser";
function formatTimestamp(timestamp) {
  const now = moment();
  const messageTime = moment.unix(timestamp);

  if (now.isSame(messageTime, "day")) {
    return `Today ${messageTime.format("h:mm a")}`;
  } else if (now.diff(messageTime, "days") === 1) {
    return `Yesterday ${messageTime.format("h:mm a")}`;
  } else if (now.diff(messageTime, "days") < 7) {
    return `${messageTime.format("dddd h:mm a")}`;
  } else {
    return messageTime.format("MMM D, YYYY h:mm a");
  }
}


export const MessageBox = ({ sender, name, text, created_at, avatar }) => (
  <div className={`message-container ${sender ? "you" : ""}`}>
    <div className="row message-box-header m-0 p-0">
      <div className="col-md-1 col-1 px-0">
        {!sender && (
          <img
            className="profile-pic"
            src={avatar?.image_url}
            alt="Dr. Sharlene Greenwood"
          />
        )}
      </div>
      <div className="col-md-10 col-10 px-0 ml-md-2 ml-0">
        <div className="row">
          <div className="col-md-6 col-6 px-0">
            <span className="message-sender-name">{sender ? "You" : name}</span>
          </div>
          <div className="col-md-6 col-6 px-0 text-right">
            <span className="message-sender-time">
              {formatTimestamp(created_at)}
            </span>
          </div>
        </div>
        <div className="header-text">
          <div className={`message-bubble ${sender ? "you" : ""}`}>
            <div> {ReactHtmlParser(text)}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
