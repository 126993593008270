import React from 'react';
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactPlayer from 'react-player';
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";

import {
    getFaqContent
} from "../../actions";
import { commonService } from "../../_services";

class FaqComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqPosts: '',
            activeMenu: 'general'
        };
    }
    componentDidMount() {
        this.setState({
            faqPosts: this.props.faq_data[Object.keys(this.props.faq_data)[0]]
        })

        
    }

    render() {
        console.log('render-faq_data', this.props.faq_data)
        console.log('state-faq_data', this.state.faqPosts)
var me = this
        var Modified_Object = Object.keys(this.props.faq_data)
            // Sort and calling a method on
            // keys on sorted fashion.
            .sort().reduce(function (Obj, key) {
                // Adding the key-value pair to the
                // new object in sorted keys manner
                Obj[key] = me.props.faq_data[key];
                return Obj;
            }, {});
        console.log('Modified_Object', Modified_Object)

        return (
            <section class="container-fluid p-0 faq-page">
                <Helmet>
                    <title>{constand.FAQ_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.FAQ_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.TERMS_DESC} />
                    <meta property="og:image" content={constand.TERMS_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.TERMS_PAGE_IMAGE_ALT} />
                </Helmet>
                <div class="bgcolor_blue">
                    <div class="container">
                        <div class="faq_banner">
                            <div class="d-flex faqbg_img ">
                                <h4 class="faq_title">FAQ</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row container mx-auto paddding_10">
                    <div class="col-lg-3 d-none d-sm-none d-md-block d-lg-block">
                        <ul class="nav nav-pills flex-column faq_tabs mt-3" id="myTab" role="tablist">
                            {Object.keys(Modified_Object).map((item, key) => {
                                var tagname = commonService.toUpperEachWord(item.replace('-', ' '))
                                //active
                                return (
                                    <li class="nav-item w-75 " onClick={() => { this.setState({ activeMenu: item }) }}><a className={
                                        (this.state.activeMenu == item
                                            ? "active"
                                            : "") + " nav-link font-medium font-21 m-b-10 pointer"
                                    }
                                    >{tagname}</a></li>
                                );
                            })
                            }
                        </ul>
                    </div>
                    <div className="col-12 mb-3 d-block d-sm-block d-md-none d-lg-none">
                        <div className="dropdown tab-btn w-100">
                            <button
                                type="button"
                                className="btn btn-default font-medium font-14 tab-select dropdown-toggle"
                                data-toggle="dropdown"
                                data-placement="bottom"
                            >
                                {commonService.toUpperEachWord(this.state.activeMenu.replace('-', ' '))}
                                <span className="slt"></span>
                            </button>
                            <div className="dropdown-menu">
                                {Object.keys(Modified_Object).map((item, key) => {
                                    var tagname = commonService.toUpperEachWord(item.replace('-', ' '))
                                    return (
                                        <p class="nav-item w-75 " className={
                                            (this.state.activeMenu == item
                                                ? "active"
                                                : "") + " nav-link font-medium font-21 m-b-10 pointer"
                                        } onClick={() => { this.setState({ activeMenu: item }) }} >{tagname}</p>
                                    );
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9" id="accordion">
                        {this.props.faq_data && this.props.faq_data[this.state.activeMenu] && this.props.faq_data[this.state.activeMenu].map((itemData, faqkey) => {
                            console.log('itemData', itemData)
                            return (
                                <div class="accordion" id={"accordionExample" + faqkey}>
                                    <div class="card">
                                        <div class="card-header" id={"heading" + faqkey}>
                                            <h2 class="mb-0">
                                                <button class="btn btnaccor_arrow collapsed" type="button" data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    data-target={"#collapseOne" + faqkey}
                                                    aria-expanded="false"
                                                    aria-controls={"collapseOne" + faqkey}>
                                                    {itemData.page_title[0].text}
                                                </button>
                                            </h2>
                                        </div>

                                        <div id={"collapseOne" + faqkey} class="collapse " aria-labelledby={"headingOne" + faqkey} data-parent={"#accordionExample" + faqkey}>

                                            <div class="card-body p-0 pt-2">
                                                <ReactPlayer url={itemData.faqs[0].embedded_video && itemData.faqs[0].embedded_video.embed_url} controls={false} width='100%' height='250px' style={{ width: '100%', height: '100%' }} />
                                            </div>
                                            <div class="card-body p-0 pt-2">
                                                <p>{itemData.faqs[0].video_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-12 text-center">
                        {!Object.keys(this.props.faq_data).length && <AnimateLoaderComponent />}
                    </div>
                </div>

            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        faq_data: state.header.faq_data,
    };
};

const mapDispatchToProps = {
    getFaqContent,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FaqComponent);