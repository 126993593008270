import React from 'react';
import { connect } from 'react-redux';
import * as constand from "../../constant";
import { Link } from 'react-router-dom';
import NameComponent from '../RegisterPage/NameComponent';
import UsernameComponent from "../RegisterPage/UsernameComponent";
import EmailComponent from "../RegisterPage/EmailComponent";
import PasswordComponent from "../RegisterPage/PasswordComponent";
import GenderComponent from "../RegisterPage/GenderComponent";
import ProfessionComponent from "../RegisterPage/ProfessionComponent";
import CountryComponent from "../RegisterPage/CountryComponent";
import HealthConditionComponent from "../RegisterPage/HealthConditionComponent";
import ClinicComponent from "../RegisterPage/ClinicComponent";
import { PurposeComponent } from "../RegisterPage/PurposeComponent";
import PromocodeAppliedComponent from "../RegisterPage/PromocodeAppliedComponent";
import KidneyCareNiceOneComponent from "../RegisterPage/KidneyCareNiceOneComponent";
import EthnicityComponent from "../RegisterPage/EthnicityComponent";
import ComorbiditiesComponent from "../RegisterPage/ComorbiditiesComponent";
import KidneyConditionComponent from "../RegisterPage/KidneyConditionComponent";
import DobComponent from "../RegisterPage/DobComponent";
import HearAboutComponent from "../RegisterPage/HearAboutComponent";
//import FirstMonthComponent from "./FirstMonthComponent";
//import TrialComponent from "./TrialComponent";
import { AlldoneComponent } from "../RegisterPage/AlldoneComponent";
import { saveValues, nextStep, prevStep, registerformUpdate, getCountriesList, start_loader, stop_loader, healthCondition, hearabout, gotoStep, fetchConditionRelationships, setStep, getLicenseList, clearRegisterFormUpdate } from '../../actions';
import { toast } from "react-toastify";
import CaregiverComponent from '../RegisterPage/CaregiverComponent';
import MembershipComponent from '../RegisterPage/MembershipComponent';
import MembershipOptions from '../RegisterPage/MembershipOptions';
import CancerTreatment from '../RegisterPage/CancerTreatment';
import CancerComordities from '../RegisterPage/CancerComordities';
import ResearchNiceoneComponent from '../RegisterPage/ResearchNiceoneComponent';
import CryptoJS from 'crypto-js';
import { BehalfSomeoneComponent } from '../RegisterPage/BehalfSomeoneComponent';
import AsthmaPilotComponent from '../RegisterPage/AsthmaPilotComponent';
import MovementPrefsComponent from '../RegisterPage/MovementPrefsComponent';
import CFComorbiditiesComponent from '../RegisterPage/CFComorbiditiesComponent';
import SeverityConditionComponent from '../RegisterPage/SeverityConditionComponent';
import ResearchParticipantComponent from '../RegisterPage/ResearchParticipantComponent';
import Modal from "react-responsive-modal";
import KidneyYouthPilotComponent from '../RegisterPage/KidneyYouthPilotComponent';
import KDYComorbiditiesComponent from "../RegisterPage/KDYComorbiditiesComponent";

const Cryptr = require('cryptr');

class SubProfileRegisterComponent extends React.Component {

  constructor(props) {
    super(props);
    this.submitRegister = this.submitRegister.bind(this);
    this.getCountriesList = this.getCountriesList.bind(this);
    this.healthCondition = this.healthCondition.bind(this);
  }
  componentDidMount() {
    console.log('Register-this.props', this.props)
    console.log('this.props', this.props.registerFormValues)
  }

  componentWillMount() {
    // var user=localStorage.getItem('user');
    // user = JSON.parse(user);
    // if (user){
    //   window.location.href="/home";
    // }
    //this.healthCondition();
    this.props.hearabout();
    this.getCountriesList();
    this.getConditionRelationships();
    this.getLicenseList();
  }

  getCountriesList() {
    this.props.getCountriesList().then(
      response => {
        this.props.stop_loader();
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  getLicenseList() {
    this.props.getLicenseList().then(
      response => {
        this.props.stop_loader();
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  healthCondition() {
    this.props.healthCondition().then(
      response => {
      },
      error => {
        toast.error(error);
      }
    );
  }
  /**
  to get condition list
  */
  getConditionRelationships() {
    this.props.fetchConditionRelationships().then(
      response => {
        this.props.stop_loader();
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }
  submitRegister() {

  }
  onCloseModal = () => {
    this.props.closeModel()
    this.props.clearRegisterFormUpdate()
  }

  render() {
    return (
      <Modal
        classNames={{
          modal: "container m-t-50 membership-popup"
        }}
        open={this.props.ismodel_open}
        onClose={this.props.closeModel}
        center
      >
        <div className="col-md-10 mx-auto popup_close_align" role="document">
          <button
            type="button"
            className="close-register orangefont close_btns"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => this.onCloseModal()}>
              X
            </span>
          </button>
          <div id="register" class="background-none">
            <div id="register-box">
              <div id="register-form" class="border_none">

                {/* Step-1 */ this.props.step === 1 &&
                {/* <EmailComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} /> */}
                }
                {/* Step-2 */ this.props.step === 2 &&
                {/* <PasswordComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} /> */}
                }
                {/* Step-3 */ this.props.step === 4 &&
                  <NameComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep} prevStep={this.props.prevStep} masterUserName={this.props.masterUserName} masterUserId={this.props.masterUserId} setStep={this.props.setStep} />
                }
                {/* Step-4 */ this.props.step === 5 &&
                  <UsernameComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-5 */ this.props.step === 8 &&
                  <GenderComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-6 */ this.props.step === 9 &&
                  <CountryComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-9 */ this.props.step === 10 &&
                  <HealthConditionComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-7 */ this.props.step === 11 &&
                  <ProfessionComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep} gotoStep={this.props.gotoStep} conditionRelationshipList={this.props.conditionRelationshipList}
                    prevStep={this.props.prevStep} setStep={this.props.setStep} pageFrom="register" masterUserName={this.props.masterUserName} />
                }
                {/* Step-8 */ this.props.step === 12 &&
                  <CaregiverComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep} gotoStep={this.props.gotoStep} conditionRelationshipList={this.props.conditionRelationshipList}
                    prevStep={this.props.prevStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }

                {/* Step-10 */ this.props.step === 13 &&
                  <ClinicComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} clinic_direction={this.props.clinic_direction} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-11 */ this.props.step === 3 &&
                  <PurposeComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} setStep={this.props.setStep} pageFrom="register" masterUserName={this.props.masterUserName} />
                }
                {/* Step-12 */ this.props.step === 120 &&
                  <div className="step10">
                    <h3 className="text-center "><a className="pull-left" href="javascript:void(0)" onClick={() => this.props.prevStep()}><img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} /></a>Thanks! Nearly there!<Link
                      to="/home"
                      className="close-register orangefont"
                    >
                      X
                    </Link></h3>
                    <div className="thanksimg text-center">
                      <img src={constand.WEB_IMAGES + "thanks.png"} id="icon" className="img-fluid" alt="User thanks" />

                    </div>

                    <div className="row justify-content-center align-items-center">
                      <div className="input_section col-md-6 m-t-10">


                        <div id="register-link" className=" w-100 m-t-80 m-b-20 float-left">
                          <a href="javascript:void(0)" onClick={() => this.props.nextStep()} className="btn bluebtn float-left font-medium font-14 w-100 text-center">Next</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {/* Step-13 */ this.props.step === 6 &&
                  <DobComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-14 */ this.props.step === 14 &&
                  <HearAboutComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-15 */ this.props.step === 15 &&
                  <PromocodeAppliedComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />

                }
                {/* Step-16 */ this.props.step === 16 &&
                  <KidneyCareNiceOneComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-17 */ this.props.step === 17 &&
                  <EthnicityComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-18 */ this.props.step === 18 &&
                  <ComorbiditiesComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-19 */ this.props.step === 19 &&
                  <KidneyConditionComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-20 */ this.props.step === 20 &&
                  <CancerTreatment registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-21 */ this.props.step === 21 &&
                  <CancerComordities registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-22 */ this.props.step === 22 &&
                  <ResearchNiceoneComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
                {/* Step-23 */ this.props.step === 23 &&
                  <MembershipOptions registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" masterUserId={this.props.masterUserId} isSubProfileRegister={true} setStep={this.props.setStep} />
                }
                {/* Step-24 */ this.props.step === 24 &&
                  <BehalfSomeoneComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-25 */ this.props.step === 25 &&
                  <AsthmaPilotComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-26 */ this.props.step === 26 &&
                  <MovementPrefsComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-27 */ this.props.step === 27 &&
                  <CFComorbiditiesComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-28 */ this.props.step === 28 &&
                  <SeverityConditionComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-29 */ this.props.step === 29 &&
                  <ResearchParticipantComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} masterUserName={this.props.masterUserName} />
                }
                {/* Step-30 */ this.props.step === 31 &&
                  <KidneyYouthPilotComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                    prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} location={this.props.location} history={this.props.history} pageFrom="register" setStep={this.props.setStep} />
                }
                {/* Step-32 */ this.props.step === 32 &&
                  <KDYComorbiditiesComponent registerFormvalues={this.props.registerFormValues} registerformUpdate={this.props.registerformUpdate} nextStep={this.props.nextStep}
                  prevStep={this.props.prevStep} gotoStep={this.props.gotoStep} pageFrom="register" masterUserName={this.props.masterUserName} setStep={this.props.setStep} />
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );

  }
}

const mapStateToProps = state => {
  const {
    sending_data, step, user_data, registerFormValues, conditionRelationshipList, clinic_direction
  } = state.register;

  return {
    sending_data,
    step,
    user_data,
    registerFormValues,
    conditionRelationshipList,
    clinic_direction
  };
};

const mapDispatchToProps = {
  saveValues,
  nextStep,
  prevStep,
  gotoStep,
  setStep,
  registerformUpdate,
  getCountriesList,
  start_loader,
  stop_loader,
  healthCondition,
  hearabout,
  fetchConditionRelationships,
  getLicenseList,
  clearRegisterFormUpdate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubProfileRegisterComponent);
