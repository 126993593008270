import React from "react";
import { connect } from "react-redux";
import { start_loader, stop_loader, fetchBlogDetail, saveBlog, loginModelOpen } from "../../actions";
import { toast } from "react-toastify";
import { ImageTag, TextWrap } from "../../tags";
import { Date } from 'prismic-reactjs';
import { RichText } from 'prismic-reactjs';
import { Link } from "react-router-dom";
import * as constand from "../../constant";
import Slider from "react-slick";
import { Cookies } from "react-cookie-consent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";
import { Helmet } from "react-helmet";
import { commonService } from "../../_services";

const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'blog_post') return `/blogs/${doc.uid}`
  // Fallback for other types, in case new custom types get created
  return `/doc/${doc.id}`
}


class BlogDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogDetail: {},
      relatedBlog: [],
      isSaved: false,
      share_enable: false,
      loader: false
    };
    this.props.history.listen((location, action) => {
      this.clearDatas();
    });
    this.clearDatas = this.clearDatas.bind(this);
    this.getBlogDetail = this.getBlogDetail.bind(this);
    this.goback = this.goback.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBlogDetail();
  }

  clearDatas() {
    window.scrollTo(0, 0);
    this.setState({ blogDetail: {}, relatedBlog: [] }, function () { this.getBlogDetail() });
  }

  getBlogDetail() {
    var blogName = this.props.match.params.blogName;
    this.setState({ loader: true })
    this.props.fetchBlogDetail(blogName).then(
      response => {
        if (response) {
          this.setState({ blogDetail: {}, relatedBlog: [], loader: false });
          if (response.blog) {
            this.setState({ blogDetail: response.blog, isSaved: response.isSaved });
          }
          if (response.relatedBlogs && response.relatedBlogs) {
            this.setState({ relatedBlog: response.relatedBlogs });
          }
          this.props.stop_loader();
        } else {
          this.setState({ loader: false })
        }
      },
      error => {
        this.props.stop_loader();
        this.setState({ loader: false })
        toast.error(error);
      }
    );
  }

  saveBlog() { //single method for save and unsave
    if (this.props.is_auth) {
      this.props.start_loader();
      var dataObj = { blogId: this.props.match.params.blogName }
      this.props.saveBlog(dataObj, this.state.isSaved).then(
        response => {
          if (response) {
            this.setState({ isSaved: !this.state.isSaved });
            toast.success(response.message);
            this.props.stop_loader();
          }
        },
        error => {
          this.props.stop_loader();
          toast.error(error);
        }
      );
    } else { //not authorized
      this.props.loginModelOpen(true);
    }
  }

  goback() {
    //window.location.href = '/blog/' + ((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.condition).toLowerCase().replace(/ /g, '-'))
    //window.history.back();
    const { from } = { from: { pathname: '/blog/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.condition), false)) } };
    this.props.history.push(from);
  }

  socialShare(shareUrl, title, isSaved) {
    shareUrl = window.location.href;
    return (
      <React.Fragment>
        <span>
          <em className={"fa fa-bookmark-o pointer" + ((isSaved === true) ? " orangefont" : "")} onClick={() => this.saveBlog()}></em>
        </span>
        <span
          className="pointer"
          onClick={() =>
            this.setState({
              share_enable: !this.state.share_enable
            })
          }
        >
          <em className="fa fa-share pointer"></em>
        </span>
        {this.state.share_enable && (
          <div className="share-section">
            <span>
              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
                className="pointer"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </span>
            <span>
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="pointer"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </span>
            <span>
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="pointer"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </span>
            <span>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="pointer"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </span>
            <span>
              <LinkedinShareButton
                url={shareUrl}
                windowWidth={750}
                windowHeight={600}
                className="pointer"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </span>
          </div>)}</React.Fragment>);
  }

  imageSection(item, key) {
    var image = (item.items && item.items.length > 0) ? item.items[0].blog_image.url : '';
    return (
      <figure className="position-relative image-text-blog m-t-20">
        <div className="social_blog">
          {(key === 0 || key === 1) ?
            <React.Fragment>
              {this.socialShare(this.state.blogDetail.href, this.state.blogDetail.uid, this.state.isSaved)} </React.Fragment> : ''}
          {/*this.socialShare(window.location.pathname, this.state.blogDetail.uid, this.state.isSaved)} </React.Fragment> : ''*/}
        </div><ImageTag className="img-fluid" src={image} />
      </figure>);
  }

  questionSection(item) {
    var data = item.primary.section_title;
    let renderHtml = <h4 className="font-24 font-semibold m-t-20 m-b-20">{RichText.asText(data)}</h4>;
    return renderHtml;
  }

  answerSection(item) {
    var data = (item.items && item.items.length > 0) ? item.items[0].blog_text : [];
    let renderHtml = <RichText className="font-14 font-qregular black-txt" render={data} />;
    return renderHtml;
  }

  videoSection(item) {
    var video = (item.items && item.items.length > 0) ? item.items[0].blog_video.html : '';
    let renderHtml = <div class="text-center" dangerouslySetInnerHTML={{ __html: video }} />;
    return renderHtml;
  }

  htmlSection(item) {
    var blog_html = (item.items && item.items.length > 0) ? item.items[0].blog_html : [];
    let renderHtml = <div dangerouslySetInnerHTML={{ __html: RichText.asText(blog_html) }} />;
    return renderHtml;
  }

  renderMetaTags(blogDetail) {

    var desc = blogDetail.data.body.map((item, key) => {
      if ((item.slice_type === 'textsection'))
        return (item.items && item.items.length > 0) ? item.items[0].blog_text[0].text : '';
      return '';
    })
    desc = desc.filter(arr => {
      return arr != ''
    })
    desc = desc.length > 0 ? desc[0] : '';

    return (
      <Helmet>
        <title>{RichText.asText(blogDetail.data.title)}{constand.BLOG_DETAIL_TITLE}{constand.BEAM}</title>
        <meta property="og:title" content={RichText.asText(blogDetail.data.title) + constand.BLOG_DETAIL_TITLE + constand.BEAM} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={constand.BLOG_DETAIL_PAGE_IMAGE} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="Beam" />
        <meta name="twitter:image:alt" content={RichText.asText(blogDetail.data.title)} />
      </Helmet>
    );
  }
  render() {
    console.log('blogDetail', this.state.blogDetail)
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

      <section className="blog_details_page  m-t-80">

        {(Object.keys(this.state.blogDetail).length > 0 && (this.state.blogDetail).constructor === Object) &&
          <div className="blog_details_container offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-xs-10 col-10 mx-auto ">
            {Object.keys(this.state.blogDetail).length > 0 && this.renderMetaTags(this.state.blogDetail)}
            <div className="related-top m-b-30">
              <a href="javascript:void(0)" onClick={this.goback} className="font-15 font-book m-b-20 font-normal black-txt float-left w-100 ">
                <i className="fa fa-chevron-left p-r-5"></i>back to all posts
          </a>
              <p className="blogbreadcrum font-qregular font-14 black-txt capitalize_text">

                {(this.state.blogDetail.tags.toString()).replace(',', '/ ')}
              </p>
              <h1 className="font-37 font-medium orangefont m-b-5">
                {(this.state.blogDetail.data.title && this.state.blogDetail.data.title.length > 0) ? RichText.asText(this.state.blogDetail.data.title) : ''}
              </h1>


              <p className="blogbreadcrum font-qregular font-14 black-txt">
                Author: {(this.state.blogDetail.data.author && this.state.blogDetail.data.author.length > 0) ? RichText.asText(this.state.blogDetail.data.author) : ''}
              </p>
              <div className="blog_dyn_section">
                {this.state.blogDetail.data &&
                  this.state.blogDetail.data.body.map((item, key) => {
                    return (<React.Fragment key={key}>
                      {(item.slice_type === 'imagesection') && this.imageSection(item, key)}
                      {(item.slice_type === 'titlesection') && this.questionSection(item)}
                      {(item.slice_type === 'textsection') && this.answerSection(item)}
                      {(item.slice_type === 'videosection') && this.videoSection(item)}
                      {(item.slice_type === 'htmlsection') && this.htmlSection(item)}
                    </React.Fragment>);
                  })}
              </div>
            </div>
            <hr></hr>
            <div className="related m-t-30  mobile-view-blog">
              <h4 className="font-24 font-semibold">Related Articles</h4>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-12 m-t-20 m-b-20 p-0 clearfix">
                    <Slider {...settings}>
                      {this.state.relatedBlog
                        .filter(e => (e.data))
                        .map((item, key) => {
                          return (
                            <div key={key} className="col-lg-6 col-md-12 col-sm-12 float-left m-b-20 p-l-0">
                              <div className="card fixed_blog_height">
                                <Link to={"/blogs/" + item.uid}>
                                  <ImageTag
                                    className="card-img-top"
                                    src={
                                      (item.data.thumbnail_img && item.data.thumbnail_img.url)
                                        ? item.data.thumbnail_img.url
                                        : constand.WEB_IMAGES + "no-image.png"
                                    }
                                  />
                                </Link>
                                <div className="card-body">
                                  <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                    <p className="dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                      {(item.tags.toString()).replace(',', '/ ')}
                                    </p>
                                    <div className="bookmark-left float-right text-right">
                                      <em className="fa fa-bookmark-o "></em>
                                    </div>
                                    <p className="author_bold dblog_title font-semibold font-16 black-txt pull-left text-left m-b-5">
                                      <TextWrap text={(item.data.title && item.data.title.length > 0) ? item.data.title[0].text : ''} limit={constand.BLOGLIST_TITLE_LIMIT} />
                                    </p>
                                    <p className="author_bold dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                      <span> Author:</span><span className="p-l-10">{(item.data.author && item.data.author.length > 0) ? RichText.asText(item.data.author) : ''}</span>
                                    </p>
                                    <p className="font-qmedium dblog_text m-b-0 pull-left text-left font-16">
                                      <TextWrap text={(item.data.preview_text && item.data.preview_text.length > 0) ? item.data.preview_text[0].text : ''} limit={constand.BLOGDETAIL_LIST_LIMIT} />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>)
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                <Link className="btn btn-orange m-t-20 m-b-50 font-book" to={"/blog/" + (commonService.replaceChar(this.props.condition, false))}>
                  View all blog posts
              </Link>
              </div>
            </div>
            <div className="related m-t-30 desktop-view-blog">
              <h4 className="font-24 font-semibold">Related Articles</h4>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-12 m-t-20 m-b-20 p-0 clearfix">
                    {this.state.relatedBlog
                      .filter(e => (e.data))
                      .map((item, key) => {
                        return (
                          <div key={key} className="col-lg-6 col-md-12 col-sm-12 float-left m-b-20 p-l-0">
                            <div className="card fixed_blog_height">
                              <Link to={"/blogs/" + item.uid}>
                                <ImageTag
                                  className="card-img-top"
                                  src={
                                    (item.data.thumbnail_img && item.data.thumbnail_img.url)
                                      ? item.data.thumbnail_img.url
                                      : constand.WEB_IMAGES + "no-image.png"
                                  }
                                />
                              </Link>
                              <div className="card-body">
                                <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                  <p className="dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                    {(item.tags.toString()).replace(',', '/ ')}
                                  </p>
                                  <div className="bookmark-left float-right text-right">
                                    <em className="fa fa-bookmark-o "></em>
                                  </div>
                                  <p className="author_bold dblog_title font-semibold font-16 black-txt pull-left text-left m-b-5">
                                    <TextWrap text={(item.data.title && item.data.title.length > 0) ? item.data.title[0].text : ''} limit={constand.BLOGLIST_TITLE_LIMIT} />
                                  </p>
                                  <p className="author_bold dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                    <span> Author:</span><span className="p-l-10">{(item.data.author && item.data.author.length > 0) ? RichText.asText(item.data.author) : ''}</span>
                                  </p>
                                  <p className="font-qmedium dblog_text m-b-0 pull-left text-left font-16">
                                    <TextWrap text={(item.data.preview_text && item.data.preview_text.length > 0) ? item.data.preview_text[0].text : ''} limit={constand.BLOGDETAIL_LIST_LIMIT} />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>)
                      })}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                <Link className="btn btn-orange m-t-20 m-b-50 font-book" to={"/blog/" + (commonService.replaceChar(this.props.condition, false))}>
                  View all blog posts
              </Link>
              </div>
            </div>
          </div>
        }
        <div className="text-center w-100">
          {(this.state.loader) && (<AnimateLoaderComponent />)}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    condition: state.auth.condition, is_auth: state.auth.is_auth
  };
};

const mapDispatchToProps = { start_loader, stop_loader, fetchBlogDetail, saveBlog, loginModelOpen };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogDetailComponent);