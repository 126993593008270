import { SET_ACTIVE_SURVEY, SET_FORMDATA } from "../utilities";

const INITIAL_STATE = {
  active_survey: 0,
  formData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_SURVEY:
      return {
        ...state,
        active_survey: action.payload,
      };
    case SET_FORMDATA:
      return {
        ...state,
        formData: action.payload,
      };
    default:
      return state;
  }
};
