import {
    SET_SLIDER_VALUE, SET_DATEPICKER_VALUE, SET_TIME_VALUE, SET_DATE_VALUE, CLEAR_ACTIVITY_LIST, SET_ACTIVITY_LIST, UPDATE_JSON_SCHEMA_FORM_DATA, GET_USER_PROGRAMS, UPDATE_USER_PROGRAMS, IS_FITNESS_TEST_MODAL_OPEN, GET_FITNESS_TEST_FORM, IS_TAKE_TEST_CLICKED, GET_TEST_SCORE, SCHEDULE_LIVE_CLASS_LIST, DYNAMIC_DASHBOARD_CONTENT_DATA,DYNAMIC_DASHBOARD_YOUR_SESSION,DYNAMIC_DASHBOARD_WEIGHT, SESSION_JOIN,
    GET_SESSION
} from '../utilities';
import { commonService } from "../_services";

const INITIAL_STATE = {
    sliderValue: '',
    selectedDate: new Date(),
    selectedTime: '',
    datePickerValue: '',
    all_activity_list: [],
    json_form_data: {},
    program_list: null,
    schedule_live_class_list:{},
    is_fitness_test_model_open: false,
    fitness_test_form: {},
    is_take_test_clicked: false,
    is_test_clicked_by_popup: true,
    userTestScoreList: [],
    dynamicDashboardContent : {},
    sessionContent: null
};

const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SLIDER_VALUE:
            return {
                ...state,
                sliderValue: action.payload
            };
        case SET_DATE_VALUE:
            return {
                ...state,
                selectedDate: action.payload
            };
        case SET_TIME_VALUE:
            console.log('SET_TIME_VALUE', action.payload)
            return {
                ...state,
                selectedTime: action.payload
            };
        case SET_DATEPICKER_VALUE:
            var stateValue = { ...state };
            console.log('SET_DATEPICKER_VALUE', stateValue)
            var selectedDay = stateValue.selectedDate
            var selectedTime = stateValue.selectedTime
            var comb = commonService.formatDateFromString(selectedDay) + ' ' + commonService.formatTimeFromSelector(selectedTime);
            var dateObj = new Date(comb);
            var datetime = new Date(dateObj).toISOString()
            return {
                ...state,
                datePickerValue: datetime,
            };
        case CLEAR_ACTIVITY_LIST:
            return {
                ...state,
                all_activity_list: [],
                sliderValue: ''
            };
        case SET_ACTIVITY_LIST:
            var new_list = [...state.all_activity_list, ...action.payload];
            return {
                ...state,
                all_activity_list: new_list
            };
        case UPDATE_JSON_SCHEMA_FORM_DATA:
            //var new_list = {}...state.json_form_data, ...action.payload];
            return {
                ...state,
                json_form_data: action.payload
            };
        case GET_USER_PROGRAMS:
            //var new_list = {}...state.json_form_data, ...action.payload];
            return {
                ...state,
                program_list: action.payload
            };
        case UPDATE_USER_PROGRAMS:
            console.log('UPDATE_USER_PROGRAMS', action.payload)
            var program_list = { ...state.program_list, ...action.payload }
            return {
                ...state,
                program_list: program_list
            };
        case IS_FITNESS_TEST_MODAL_OPEN:
            return {
                ...state,
                is_fitness_test_model_open: action.payload
            };
        case GET_FITNESS_TEST_FORM:
            return {
                ...state,
                fitness_test_form: action.payload
            };
        case IS_TAKE_TEST_CLICKED:
            return {
                ...state,
                is_take_test_clicked: action.payload.isTakeTestClicked,
                is_test_clicked_by_popup: action.payload.clickedByTestPopup
            };
        case GET_TEST_SCORE:
            return {
                ...state,
                userTestScoreList: action.payload
            };
        case SCHEDULE_LIVE_CLASS_LIST:
            return {
                ...state,
                schedule_live_class_list: action.payload
            }
        case DYNAMIC_DASHBOARD_CONTENT_DATA:
            return {
                ...state,
                dynamicDashboardContent: action.payload,
            }
        case DYNAMIC_DASHBOARD_YOUR_SESSION:
            return {
                ...state,
                dynamicDashboardSession: action.payload,
            }
        case DYNAMIC_DASHBOARD_WEIGHT:
            return {
                ...state,
                dynamicDashboardWeight: action.payload,
            }
        case SESSION_JOIN:
            return {
                ...state,
                sessionJoined: action.payload,
            }
            
        case GET_SESSION:
            return {
                ...state,
                sessionContent: action.payload,
            }
        default:
            return state;
    }
};

export default DashboardReducer;;
