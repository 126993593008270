import {
    IP_DATA, SET_EXPLORE_CONDITION, SET_ACCOUNT_LOCKED_POPUP, IS_PAYMENT_ERROR_POPUP_OPEN
} from '../utilities';
import _ from 'lodash';

const INITIAL_STATE = {
    ip_data: {},
    explore_conditions: {},
    is_account_locked: false,
    is_payment_error_popup_open: false
    
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IP_DATA:
            return {
                ...state,
                ip_data: action.payload
            };
        case SET_EXPLORE_CONDITION:
            let lookup = _.keyBy(action.payload.myConditions, function (o) { return o.conditionId });
            let selectedHealth_condition = _.filter(action.payload.healthConditions, function (list) {
                console.log('SET_EXPLORE_CONDITION', list)

                console.log('SET_EXPLORE_CONDITION-lookup', lookup)
                    return lookup[list.id] == undefined;
            });
            console.log('SET_EXPLORE_CONDITION-result', selectedHealth_condition)
            return {
                ...state,
                explore_conditions: selectedHealth_condition
            };
        case SET_ACCOUNT_LOCKED_POPUP:
            return {
                ...state,
                is_account_locked : action.payload
            }
        case IS_PAYMENT_ERROR_POPUP_OPEN:
            return {
                ...state,
                is_payment_error_popup_open: action.payload
            }


        default:
            return state;
    }
};