import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ImageTag } from "../../tags";
import {
  getDashboardGroups,
  start_loader,
  stop_loader,
  joinrequest,
  joingroup,
  loginModelOpen
} from "../../actions";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";

class DashboardGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: false,
      groupList: [],
      groupPermissionList: [],
      groupMembers: [],
      show_more_cnt: constand.ONDEMAND_GROUP_COUND,
      list_count: 0,
      search_key: "",
      offset: 0
    };
    this.getGroupList = this.getGroupList.bind(this);
    this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchFucntion = this.searchFucntion.bind(this);
    this.joingroup = this.joingroup.bind(this);
    this.joinrequest = this.joinrequest.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.gotoGroupDetailPage = this.gotoGroupDetailPage.bind(this);
  }
  componentWillMount() {
    this.getGroupList();
  }
  incrementShowmoreCnt() {
    this.setState(
      {
        offset: this.state.offset + this.state.show_more_cnt
      },
      function () {
        this.getGroupList();
      }
    );
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  searchFucntion() {
    this.setState({ offset: 0, groupList: [], groupPermissionList: [] }, function () {
      this.getGroupList();
    });
  }
  getGroupList() {
    this.setState({ Loading: true, disableButton: true });
    var dataObj = {
      offset: this.state.offset,
      limit: this.state.show_more_cnt,
      search: this.state.search_key
    };
    this.props.start_loader();
    //var rootParam = (matchPath(this.props.location.pathname, '/account/dashboard/groups/:condition')) ? matchPath(this.props.location.pathname, '/account/dashboard/groups/:condition').params.condition : '';
    //var typeFilter = rootParam.replace("-", " ");
    this.props.getDashboardGroups(dataObj).then(
      response => {
        if (response) {
          var list_data = response.list.groups ? response.list.groups : [];
          var new_list = [...this.state.groupList, ...list_data];

          var member_data = response.list.groupMembers ? response.list.groupMembers : [];
          var member_list = [...this.state.groupMembers, ...member_data];
          this.setState({
            Loading: false,
            groupList: new_list,
            groupMembers: member_list,
            list_count: response.count,
            disableButton: false
          });
        }
        this.props.stop_loader();
      },
      error => {
        this.setState({
          Loading: false,
          disableButton: false
        });
        this.props.stop_loader();
        //toast.error(error);
      }
    );
  }
  submitRequest(item) {
    if (item.type === "closed") {
      // private group
      this.joinrequest(item);
    } else {
      // public group
      this.joingroup(item);
    }
  }
  joingroup(item) {
    if (this.props.is_auth) {
      this.props.start_loader();
      this.props.joingroup(item.id).then(
        response => {
          this.props.stop_loader();
          toast.success(response.message);
          const { from } = {
            from: {
              pathname:
                "/group/about/" +
                item.community_name +
                "/" +
                this.props.params.type
            }
          };
          this.props.history.push(from);
        },
        error => {
          this.props.stop_loader();
          toast.error(error);
        }
      );
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  joinrequest(item) {
    if (this.props.is_auth) {
      this.props.start_loader();
      this.props.joinrequest(item.id).then(
        response => {
          this.setState({ offset: 0, groupList: [], groupPermissionList: [] }, function () {
            this.getGroupList();
          });
          toast.success(response.message);
        },
        error => {
          this.props.stop_loader();
          toast.error(error);
        }
      );
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  gotoGroupDetailPage(item) {
    const { from } = {
      from: {
        pathname:
          "/group/about/" + item.community_name + "/" + this.props.params.type
      }
    };
    this.props.history.push(from);
  }
  /**
   * on enter search
   * @param {*} e
   */
  searchBarEnter(e) {
    if (e.key === "Enter") {
      this.searchFucntion();
    }
  }
  retruenMemberCount(id) {
    var returnVal = 0;
    var filterList = this.state.groupMembers.filter(e => parseInt(e.groupId) === parseInt(id));
    if (filterList.length > 0) {
      returnVal = filterList[0].count;
    }
    return (returnVal);
  }
  render() {
    return (
      <div className="">
        <Helmet>
          <title>{constand.DASHBOARD_GROUPS_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.DASHBOARD_GROUPS_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.DASHBOARD_GROUPS_DESC} />
          <meta property="og:image" content={constand.DASHBOARD_GROUPS_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.DASHBOARD_GROUPS_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="ondemand_groups">
          <div className="container-fluid mx-auto w-75">

            <div className="col-md-12 m-t-30 m-b-20">
              {(this.state.groupList.length > 0) &&
                <div className="row">
                  {this.state.groupList.map((item, key) => {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 p-l-0 m-b-20"
                        key={key}
                      >
                        <div className="card-design">
                          <div className="blog-photo">
                            <ImageTag
                              className="img-fluid"
                              src={
                                item.banner_img
                                  ? constand.GROUP_IMAGE_PATH +
                                  item.banner_img
                                  : constand.WEB_IMAGES + "/small_banner_img.png"
                              }
                            />
                          </div>
                          <div className="blog-content p-t-20 clearfix">
                            <div className="blog-top clearfix">
                              <h5 className="float-left font-book font-15 blog_head">
                                <Link
                                  to={
                                    "/group/about/" +
                                    item.community_name +
                                    "/" +
                                    this.props.params.condition
                                  }
                                >
                                  {item.community_name}
                                </Link>
                              </h5>
                              {/* {this.props.is_auth &&
                                <React.Fragment>
                                  <button
                                    onClick={() =>
                                      this.gotoGroupDetailPage(item)
                                    }
                                    className="btn float-right dblog_btn font-14 button-lightblue position-relative"
                                  >
                                    Member{" "} {item.type === "closed" && (<i className="fa fa-lock last-lock"></i>)}
                                  </button>
                                </React.Fragment>
                              } */}
                            </div>
                            <div className="blog-bottom clearfix m-t-15 m-b-10">
                              <ImageTag
                                className="img-fluid rounded-circle"
                                src={
                                  item.User.profilePic
                                    ? constand.PROFILE_IMAGE_PATH +
                                    item.User.profilePic
                                    : constand.WEB_IMAGES+"no-image.png"
                                }
                                alt=""
                                width="50"
                                height="50"
                              />
                              <span className="font-book font-14 m-l-5 light-grey">
                                {item.User
                                  ? item.User.name + " " + item.User.lastName
                                  : ""}
                              </span>
                              {item.Members && (
                                <span className="font-semibold float-right font-14 m-t-10">
                                  {this.retruenMemberCount(item.id)} Members
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {this.state.groupList.length < this.state.list_count && (
                    <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-t-40 m-b-40">
                      <button
                        disabled={
                          this.state.groupList.length >= this.state.list_count || this.state.disableButton
                        }
                        onClick={this.incrementShowmoreCnt}
                        className="button-lightblue  font-book"
                      >
                        show more groups
                    </button>
                    </div>
                  )}
                </div>
              }{
                (!this.state.Loading && this.state.groupList.length === 0) &&
                <div className="row"><h3
                  className="text-center w-100"
                >You are not yet a member of a group.  Why not join one today?</h3></div>
              }
              <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth
  };
};

const mapDispatchToProps = {
  getDashboardGroups,
  start_loader,
  loginModelOpen,
  stop_loader,
  joinrequest,
  joingroup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardGroupComponent);
